import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {Loader, useTranslation, toast} from "krl-alfred";
import OrderSummary from "./OrderSummary";
import AddressSummary from "./AddressSummary";
import CustomerSummary from "./CustomerSummary";
import ItemSummary from "./ItemSummary";
import { getCustomerDetail, getCustomerDetailByOperation } from "../../../api/routes/customer";
import { getOrderDetail, getOrderDetailByOperation } from "../../../api/routes/order";

function OrderDetail(props) {

    const { orderNumber } = useParams();
    const {t} = useTranslation();

    const {data,isFetching,refetch} = useQuery({ queryKey: [`orderDetail_${props?.type}`,orderNumber], retry:0, queryFn: () => ["operationOrders","todayShipmentOrders","todayReturnedOrders"].includes(props.type) ? getOrderDetailByOperation(orderNumber) : getOrderDetail(orderNumber) });
    const {data:customerData,isFetching:customerIsFetching} = useQuery({ queryKey: [`customerDetail_${props.type}`,orderNumber,data?.customerId], retry:0, queryFn: () => ["operationOrders","todayShipmentOrders","todayReturnedOrders"].includes(props.type) ? getCustomerDetailByOperation(data.customerId) : getCustomerDetail(data.customerId) });

    return (
        <div className="orderDetail">
            {(isFetching || customerIsFetching) ? (
                <div className="loadingPage">
                    <Loader width="32px" height="32px" variant="primary"/>
                </div>
            ) : (
                <>
                    <CustomerSummary data={customerData} type={props.type}/>
                    <OrderSummary data={data} refetch={refetch} type={props.type}/>
                    <AddressSummary
                        data={data?.billingAddresses && data?.billingAddresses[0]}
                        title={t("ORDER.BILLING_ADDRESS")}
                    />
                    <AddressSummary
                        data={data?.shippingAddresses && data?.shippingAddresses[0]}
                        title={t("ORDER.SHIPPING_ADDRESS")}
                    />
                    {data?.items.map(item => <ItemSummary type={props.type} data={item} order={data} refetch={refetch}/>)}
                </>
            )}
        </div>
    )
}

export default OrderDetail;