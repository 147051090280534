import {request} from "../request";

export const getOrderStatusList = async() => await request({
    url: 'Common/order-status-list',
    method: 'get',
})

export const getPaymentStatusList = async() => await request({
    url: 'Common/payment-status-list',
    method: 'get',
})

export const getOrderItemStatusList = async() => await request({
    url: 'Common/order-item-status-list',
    method: 'get',
})