import { Button, useTranslation, toast } from "krl-alfred";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils/price";
import { formatDate } from "../../../utils/date";
import React, { useContext, useState } from "react";
import { OrderContext } from "../../../Context/OrderContex";
import Listing from "../../Listing";
import {
  confirmOrder,
  getOrderDetail,
  unConfirmOrder,
  getOrderDetailByOperation
} from "../../../api/routes/order";
import ReturnShipmentWithItemModal from "../../../Modals/ReturnShipmentWithItemModal";
import SendShipmentModal from "../../../Modals/SendShipmentModal";
import OrderScoreModal from "../../../Modals/OrderScoreModal";

const List = (props) => {
  const { orderStatuses, paymentStatuses } = useContext(OrderContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [showSendShipmentModal, setShowSendShipmentModal] = useState(false);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);
  const [showOrderScoreModal, setShowOrderScoreModal] = useState(false);
  const [damageType, setDamageType] = useState(1);

  const [isLoading, setIsLoading] = useState({
    isReturnShipmentLoading: false,
    isSendShipmentLoading: false,
  });

  const routeLink = (record) => {
    let respLink;
    switch (props.type) {
      case "operationOrders":
        respLink = `/operations-management/orders/${record?.orderNumber}`;
        break;
      case "todayShipmentOrders":
        respLink = `/operations-management/today-shipment/${record?.orderNumber}`;
        break;
      case "todayReturnedOrders":
        respLink = `/operations-management/today-returned/${record?.orderNumber}`;
        break;
      case "saveCardOrders":
        respLink = `/orders-management/save-card-orders/${record?.orderNumber}`;
        break;
      case "awaitingOrders":
        respLink = `/orders-management/awaiting-orders/${record?.orderNumber}`;
        break;
      default:
        respLink = `/orders-management/orders/${record?.orderNumber}`;
        break;
    }

    navigate(respLink);
  }

  const handleOpenConfirmOrder = (variant, record) => {
    const toastVariables = {
      title:
        variant === "success"
          ? t("INFO.ARE_YOU_SURE_APPROVE_ORDER")
          : t("INFO.ARE_YOU_SURE_REJECT_ORDER"),
      icon: variant === "success" ? "CheckMark" : "TimesLg",
      hasClosing: false,
      variant,
      alignment: "left",
      showPrimaryButton: true,
      showSecondaryButton: true,
      secondaryButtonText: t("BUTTON.GIVE_UP"),
      primaryButtonText:
        variant === "success" ? t("BUTTON.APPROVE") : t("BUTTON.REJECT"),
      isSecondaryButtonLoading: false,
      onSecondaryAction: (e) => e,
    };

    toast({
      ...toastVariables,
      isPrimaryButtonLoading: false,
      onPrimaryAction: async () => {
        toast({
          ...toastVariables,
          isPrimaryButtonLoading: true,
        });
        if (variant === "success") {
          await confirmOrder({
            email: record.email,
            orderNumber: record.orderNumber,
          })
            .then((resp) => {
              toast({
                hasClosing: true,
                title: t("INFO.APPROVED_ORDER"),
              });
              props?.refetch()
            })
            .catch((err) => {
              toast({
                hasClosing: true,
                icon: "Danger",
                variant: "warning",
                title: err?.response?.data?.message,
              });
            });
        } else {
          await unConfirmOrder({
            email: record.email,
            orderNumber: record.orderNumber,
          })
            .then((resp) => {
              toast({
                hasClosing: true,
                title: t("INFO.REJECTED_ORDER"),
              });
            })
            .catch((err) => {
              toast({
                hasClosing: true,
                icon: "Danger",
                variant: "warning",
                title: err?.response?.data?.message,
              });
            });
        }
      },
    });
  };

  const handleCreateReturnCode = async (record) => {
    setIsLoading((prev) => ({ ...prev, isReturnShipmentLoading: true }));
    await getOrderDetail(record.orderNumber)
      .then((resp) => {
        setSelectedOrderDetail(resp);
        setShowShipmentModal(true);
      })
      .finally((final) => {
        setIsLoading((prev) => ({ ...prev, isReturnShipmentLoading: false }));
      });
  };

  const handleSendOrder = async (record) => {
    setIsLoading((prev) => ({ ...prev, isSendShipmentLoading: true }));
    await getOrderDetailByOperation(record.orderNumber)
      .then((resp) => {
        setSelectedOrderDetail(resp);
        setShowSendShipmentModal(true);
      })
      .finally((final) => {
        setIsLoading((prev) => ({ ...prev, isSendShipmentLoading: false }));
      });
  };

  const handleOpenOrderScoreModal = async (type, record) => {
    setDamageType(type);
    setIsLoading((prev) => ({ ...prev, isDamageTypeLoading: true }));
    await getOrderDetail(record.orderNumber)
      .then((resp) => {
        setSelectedOrderDetail(resp);
        setShowOrderScoreModal(true);
      })
      .finally((final) => {
        setIsLoading((prev) => ({ ...prev, isDamageTypeLoading: false }));
      });
  };

  let columns = [
    {
      key: "id",
      title: t("LIST.ID"),
      dataIndex: "id",
      width: 85,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
      render: (_, data) => data?.id || data?.orderId
    },
    {
      key: "isPurchasedOrder",
      title: t("ORDER.ORDER_TYPE"),
      dataIndex: "isPurchasedOrder",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
      render: (_, data) =>
        data?.isPurchasedOrder
          ? "Satış"
          : data?.isSubscriptionOrder
          ? "Abonelik"
          : "Kiralama",
    },
    {
      key: "customerId",
      title: t("CUSTOMER.CUSTOMER_ID"),
      dataIndex: "customerId",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "firstName",
      title: t("CUSTOMER.FIRST_NAME"),
      dataIndex: "firstName",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "lastName",
      title: t("CUSTOMER.LAST_NAME"),
      dataIndex: "lastName",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "orderNumber",
      title: t("ORDER.ORDER_NUMBER"),
      dataIndex: "orderNumber",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "orderStatus",
      title: t("ORDER.ORDER_STATUS"),
      dataIndex: "orderStatus",
      render: (value) =>
        orderStatuses.find((status) => status?.id === value)?.value,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "totalOrderCount",
      title: t("ORDER.TOTAL_ORDER_COUNT"),
      dataIndex: "totalOrderCount",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "paymentGatewayName",
      title: t("ORDER.PAYMENT_GATEWAY_NAME"),
      dataIndex: "paymentGatewayName",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "isRepeatOrder",
      title: t("ORDER.IS_REPEAT_ORDER"),
      dataIndex: "isRepeatOrder",
      render: (value) => (value ? t("INFO.YES") : t("INFO.NO")),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "paymentStatus",
      title: t("ORDER.PAYMENT_STATUS"),
      dataIndex: "paymentStatus",
      render: (value) =>
        paymentStatuses?.find((status) => status?.id === value)?.value,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "creditScoreStatusText",
      title: t("ORDER.CREDIT_SCORE"),
      dataIndex: "creditScoreStatusText",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "total",
      title: t("ORDER.TOTAL"),
      dataIndex: "total",
      render: (price) => formatPrice(price),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "customerScore",
      title: t("CUSTOMER.CUSTOMER_SCORE"),
      dataIndex: "customerScore",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "createdAt",
      title: t("LIST.CREATED_AT"),
      dataIndex: "createdAt",
      render: (date) => formatDate(date),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "actions",
      title: null,
      fixed: "right",
      width: 100,
      render: (record) =>
        (props.type === "operationOrders" && [10,11,3]?.includes(record.orderStatus)) ? (
          <div className="actions">
            {[10, 11]?.includes(record.orderStatus) && (
              <>
                <Button
                  onClick={() => handleOpenOrderScoreModal(1, record)}
                  width="48px"
                  variant="inverted"
                  size="xsmall"
                  icon="Dislikesmall"
                  isLoading={isLoading.isDamageTypeLoading}
                  tooltip={t("INFO.IS_DAMAGED")}
                />
                <Button
                  onClick={() => handleOpenOrderScoreModal(2, record)}
                  width="48px"
                  variant="primary"
                  size="xsmall"
                  icon="Likesmall"
                  isLoading={isLoading.isDamageTypeLoading}
                  tooltip={t("INFO.IS_NOT_DAMAGED")}
                />
              </>
            )}
            {[3]?.includes(record.orderStatus) &&  (
                <Button
                    onClick={() => handleSendOrder(record)}
                    width="32px"
                    variant="primary"
                    tooltip={t("BUTTON.SEND")}
                    size="xsmall"
                    icon="Send"
                    isLoading={isLoading.isSendShipmentLoading}
                />
            )}
          </div>
        ) : [1,6].includes(record?.paymentStatus) && (
          <div className="actions">
            {record?.paymentStatus === 1 && (
                <Button
                    onClick={() => handleCreateReturnCode(record)}
                    width="32px"
                    variant="primary"
                    tooltip={t("BUTTON.GENERATE_RETURN_CODE")}
                    size="xsmall"
                    icon="BackCargosmall"
                    isLoading={isLoading.isReturnShipmentLoading}
                />
            )}
            {record?.paymentStatus === 6 && (
                <div className="actions">
                  <Button
                      onClick={() => handleOpenConfirmOrder("danger", record)}
                      width="48px"
                      variant="inverted"
                      tooltip={t("BUTTON.REJECT")}
                      size="xsmall"
                      icon="CloseSmall"
                  />
                  <Button
                      onClick={() => handleOpenConfirmOrder("success", record)}
                      width="48px"
                      variant="primary"
                      tooltip={t("BUTTON.APPROVE")}
                      size="xsmall"
                      icon="Completed"
                  />
                </div>
            )}
          </div>
        ),
    },
  ];
  
  if(["operationOrders"].includes(props?.type)){
    columns = columns.filter(column => !["creditScoreStatusText","totalOrderCount","paymentGatewayName","total"].includes(column.key))
  }else if(["todayShipmentOrders","todayReturnedOrders"].includes(props?.type)){
    columns = columns.filter(column => !["creditScoreStatusText","totalOrderCount","paymentGatewayName","total","customerScore","paymentStatus","isRepeatOrder","isPurchasedOrder","orderStatus"].includes(column.key))
  }

  return (
    <div className="orderList">
      <ReturnShipmentWithItemModal
        show={showShipmentModal}
        setShow={setShowShipmentModal}
        data={selectedOrderDetail}
        refetch={props.refetch}
      />
      <SendShipmentModal
        orderDetail={selectedOrderDetail}
        show={showSendShipmentModal}
        setShow={setShowSendShipmentModal}
        refetch={props.refetch}
      />

      <OrderScoreModal
        show={showOrderScoreModal}
        setShow={setShowOrderScoreModal}
        data={selectedOrderDetail?.items}
        refetch={props.refetch}
        order={selectedOrderDetail}
        damageType={damageType}
      />
      <Listing
        hasLoading={props.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />
    </div>
  );
};

export default List;
