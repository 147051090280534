import { Button, useTranslation } from 'krl-alfred'
import {useNavigate} from "react-router-dom";

const ErrorPage = ({ title, description, img }) => {
  const { t } = useTranslation()

  const navigate = useNavigate();

  return (
    <div className="errorPage">
      <div className="errorPageInner">
        <div className="errorImg">{img}</div>
        <h1 className="title" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="description">{description}</p>
        <div className="bottom">
          <Button onClick={() => navigate("/")} variant="primary" size="big" icon="ArrowSmallRight">
            {t('BUTTON.GO_TO_HOMEPAGE')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
