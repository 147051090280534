import axios from 'axios'
import Cookies from 'universal-cookie';
import moment from "moment";
import {formatDate} from "../utils/date";

const cookie = new Cookies();

export const request = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': cookie.get("accessToken")
    },
})

function responseF(response) {
    if (
        response.headers['content-type'] === 'application/ms-excel' ||
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
        const today = new Date();
        const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
        let fileHeader = response?.config?.url?.substring(9);
        if (fileHeader === "Customer/export") {
            fileHeader = "customer_";
        } else if (fileHeader === "Product/export-product-click") {
            fileHeader = "productclick_";
        } else if (fileHeader === "DemandedProduct/export") {
            fileHeader = "basketandorder_";
        }
        let filename = fileHeader + date + ".xlsx";
        const disposition = response.headers['content-disposition'];
        if (disposition) {
            const filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return Promise.resolve({
            fileName: `${filename}`,
            file: response.data,
            contentType: response.headers['content-type']
        });
    }
    if (response?.data?.result) {
        return response.data.result;
    }
}

request.interceptors.response.use(responseF);

request.interceptors.request.use(function (response) {

    if(response?.params) {
        Object.keys(response?.params)?.forEach(function(key, index) {
            if(["minStartDate","minEndDate","maxEndDate","maxStartDate","startDate","endDate"].includes(key)){
                //const selectedDate = response.params[key].split("/")
                //const formatted = formatDate(`${selectedDate[1]}-${selectedDate[0]}-${selectedDate[2]}`,false,"YYYY-MM-DDTHH:mm:ss")
                //response.params[key] = formatted;

                const formatted = formatDate(response?.params[key],false,"YYYY-MM-DDTHH:mm:ss")
                response.params[key] = formatted;
            }
        });
    }

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export const downloadFile = response => {
    try {
        const blob = new Blob([response.file], {type: response.contentType});
        let dom = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        dom.href = url;
        dom.download = decodeURI(response.fileName);
        dom.style.display = 'none';
        document.body.appendChild(dom);
        dom.click();
        dom.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log(error)
    }
};