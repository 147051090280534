import React, { useContext } from "react";
import { Input, useTranslation, SelectBox } from "krl-alfred";
import { Controller } from "react-hook-form";
import { focusSelect } from "../utils/genericUtils";
import { OrderContext } from "../Context/OrderContex";

function UpdateOrderProductForm({
  onSubmit,
  control,
  errors,
  isLoading,
  submitRef,
}) {
  const { t } = useTranslation();
  const { orderStatuses, paymentStatuses } = useContext(OrderContext);

  return (
    <form onSubmit={onSubmit}>
      <div className="input-container">
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              type="date"
              label={t("FORM.LABEL.START_DATE")}
              placeholder={t("FORM.INPUT.START_DATE")}
              hasError={errors.startDate}
              errorMessage={t("FORM.ERROR.START_DATE")}
              format="yyyy-MM-dd"
              mask={null}
            />
          )}
        />
      </div>
      <div className="input-container">
        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              type="date"
              label={t("FORM.LABEL.END_DATE")}
              placeholder={t("FORM.INPUT.END_DATE")}
              hasError={errors.endDate}
              errorMessage={t("FORM.ERROR.END_DATE")}
              format="yyyy-MM-dd"
              mask={null}
            />
          )}
        />
      </div>
      <div className="input-container">
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.ORDER_STATUSES")}
              instanceId="orderStatusesSelect"
              width="100%"
              placeholder={t("FORM.INPUT.ORDER_STATUSES")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={e => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={errors.orderStatuses}
              errorMessage={t("FORM.ERROR.ORDER_STATUSES")}
              maxOptionListHeight="153px"
              options={orderStatuses?.map((orderStatus) => ({
                value: orderStatus.id,
                label: orderStatus.value,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="serialNumber"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.SERIAL_NUMBER")}
              placeholder={t("FORM.INPUT.SERIAL_NUMBER")}
              hasError={errors.serialNumber}
              errorMessage={t("FORM.ERROR.SERIAL_NUMBER")}
            />
          )}
        />
      </div>

      <div className="d-none">
        <button type="submit" ref={submitRef} />
      </div>
    </form>
  );
}

export default UpdateOrderProductForm;
