import { Form, Input, DatePicker, Button, Switch } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      StartDate: value.StartDate ? formatDate(new Date(value.StartDate),false,"") : undefined,
      EndDate: value.EndDate ? formatDate(new Date(value.EndDate),false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
    >
       <Form.Item label="Id" name="Id">
        <Input />
      </Form.Item>
      <Form.Item label="Ad" name="FirstName">
        <Input />
      </Form.Item>
      <Form.Item label="Soyad" name="LastName">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="Email">
        <Input />
      </Form.Item>
      <Form.Item label="Tel no" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Sipariş Veren Müşteri Mi?" name="IsOrderedCustomer">
        <Switch />
      </Form.Item>
      <Form.Item label="Başl. tarihi" name="StartDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Bitiş tarihi" name="EndDate">
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
