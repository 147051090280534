import React, { useContext } from "react";
import { useTranslation, SelectBox } from "krl-alfred";
import { Controller } from "react-hook-form";
import { focusSelect } from "../utils/genericUtils";
import { OrderContext } from "../Context/OrderContex";

function UpdateOrderForm({ onSubmit, control, errors, isLoading, submitRef }) {
  const { t } = useTranslation();
  const { orderStatuses, paymentStatuses } = useContext(OrderContext);
  
  return (
    <form onSubmit={onSubmit}>
      <div className="input-container">
        <Controller
          name="orderStatus"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.ORDER_STATUSES")}
              instanceId="orderStatusesSelect"
              width="100%"
              placeholder={t("FORM.INPUT.ORDER_STATUSES")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={e => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={errors.orderStatuses}
              errorMessage={t("FORM.ERROR.ORDER_STATUSES")}
              maxOptionListHeight="153px"
              options={orderStatuses?.map((orderStatus) => ({
                value: orderStatus.id,
                label: orderStatus.value,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name="paymentStatus"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.PAYMENT_STATUSES")}
              instanceId="paymentStatusesSelect"
              width="100%"
              placeholder={t("FORM.INPUT.PAYMENT_STATUSES")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={e => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={errors.paymentStatuses}
              errorMessage={t("FORM.ERROR.PAYMENT_STATUSES")}
              maxOptionListHeight="153px"
              options={paymentStatuses?.map((status) => ({
                value: status.id,
                label: status.value,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>

      <div className="d-none">
        <button type="submit" ref={submitRef} />
      </div>
    </form>
  );
}

export default UpdateOrderForm;
