import {request} from "../request";

export const getProductList = async (params) => await request({
    url: 'console/Product/get-product-list',
    method: 'get',
    params
})

export const getActiveBrands = async (params) => await request({
    url: 'console/Product/get-active-brands',
    method: 'get',
    responseType: "blob",
    params
})

export const getSampleImportForImportProducts = async (params) => await request({
    url: 'console/Product/sample-import-for-import-products',
    method: 'get',
    responseType: "blob",
    params
})

export const getSampleImportForUpdatePrices = async (params) => await request({
    url: 'console/Product/sample-import-for-update-prices',
    method: 'get',
    responseType: "blob",
    params
})

export const importForUpdatePrices = async (data) => await request({
    url: 'console/Product/import-for-update-prices',
    method: 'post',
    responseType: "blob",
    data,
    headers: {
        ...request.headers,
        "Content-Type": "multipart/form-data",
    },
})
export const getProductDetail = async (params) => await request({
    url: `console/Product/detail/${params?.productId}`,
    method: 'get',
    params
})