import {Pagination, Table} from "krl-alfred";
import React, {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import SimpleBar from "simplebar-react";

const Listing = ({columns, data, hasLoading, title, defaultPageSize,hasPagination=true}) => {

    const navigate = useNavigate();
    const scrollableRef = useRef()

    useEffect(() => {
        scrollableRef.current.scrollLeft = 0
    },[hasLoading])

    return (
        <div className="listingWrapper">
            {title && <strong className="listTitle">{title}</strong>}
            <div className="listing">
                <SimpleBar autoHide={true} scrollableNodeProps={{ ref: scrollableRef }}>
                    <Table hasLoading={hasLoading} columns={columns} data={data?.data}/>
                </SimpleBar>
            </div>
            {hasPagination && (
                <div class="paginationWrapper">
                    {!hasLoading && (
                        <Pagination
                            onChange={(current, pageSize) => {
                                //console.log('onChange:current=', current);
                                //console.log('onChange:pageSize=', pageSize);
                                const url = new URL(window.location.href);
                                url.searchParams.set('index', current);
                                navigate(url.pathname + url.search);
                            }}
                            onShowSizeChange={(current, pageSize) => {
                                //console.log('onChange:current=', current);
                                //console.log('onChange:pageSize=', pageSize);
                                const url = new URL(window.location.href);
                                url.searchParams.set('defaultPageSize', pageSize);
                                navigate(url.pathname + url.search);
                            }}
                            defaultCurrent={data?.index}
                            defaultPageSize={defaultPageSize}
                            total={data?.totalCount}
                            current={data?.index}
                            pageSizeOptions={[15, 20, 30, 50, 100]}
                            hideOnSinglePage
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default Listing