import {DescriptionCard, useTranslation} from "krl-alfred";
import React, {useState} from "react";
import UpdateProductInfoModal from "../../../../Modals/UpdateProduct/UpdateProductInfoModal";
import UpdateProductDescriptionModal from "../../../../Modals/UpdateProduct/UpdateProductDescriptionModal";

const ProductInfo = ({productDetail}) => {
    const {t} = useTranslation();
    const [showUpdateProductInfoModal, setShowUpdateProductInfoModal] = useState(false);
    const [showUpdateProductDescriptionModal, setShowUpdateProductDescriptionModal] = useState(false);

    let list = [
        {
            key: "PRODUCT_ID",
            title: t("PRODUCT.PRODUCT_ID"),
            display: productDetail?.productId,
        },
        {
            key: "PRODUCT_NAME",
            title: t("PRODUCT.PRODUCT_NAME"),
            display: productDetail?.name,
        },
        {
            key: "ERP_CODE",
            title: t("PRODUCT.ERP_CODE"),
            display: productDetail?.erpCode,
        },
        {
            key: "SLUG",
            title: t("PRODUCT.SLUG"),
            display: productDetail?.slug,
        },
        {
            key: "MODEL_NUMBER",
            title: t("PRODUCT.MODEL_NUMBER"),
            display: productDetail?.modelNumber,
        },
        {
            key: "META_TITLE",
            title: t("PRODUCT.META_TITLE"),
            display: productDetail?.metaTitle,
        },
        {
            key: "META_DESCRIPTION",
            title: t("PRODUCT.META_DESCRIPTION"),
            display: productDetail?.metaDescription,
        },
        {
            key: "TITLE",
            title: t("PRODUCT.TITLE"),
            display: productDetail?.title,
        },
    ];
    let descriptionList = [
        {
            key: "SHORT_DESCRIPTION",
            title: t("PRODUCT.SHORT_DESCRIPTION"),
            display: productDetail?.shortDescription,
        },
        {
            key: "FULL_DESCRIPTION",
            title: t("PRODUCT.FULL_DESCRIPTION"),
            display: productDetail?.fullDescription,
        },
        {
            key: "BOX_DESCRIPTION",
            title: t("PRODUCT.BOX_DESCRIPTION"),
            display: productDetail?.boxDescription,
        },
    ];


    return (
        <div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.PRODUCT_INFO")}</h1>
                <DescriptionCard
                    list={list}
                    title={productDetail?.name}
                    hasHeaderButton
                    headerButtonText={t("BUTTON.EDIT")}
                    headerButtonWidth="82px"
                    headerButtonAction={(e) => {
                        setShowUpdateProductInfoModal(true)
                    }}
                    headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                    boxSize={4}
                />
            </div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.DESCRIPTION")}</h1>
                <DescriptionCard
                    list={descriptionList}
                    title={productDetail?.name}
                    hasHeaderButton
                    headerButtonText={t("BUTTON.EDIT")}
                    headerButtonWidth="82px"
                    headerButtonAction={(e) => {
                        setShowUpdateProductDescriptionModal(true)
                    }}
                    headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                    boxSize={3}
                />
            </div>
            <UpdateProductInfoModal
                show={showUpdateProductInfoModal}
                setShow={setShowUpdateProductInfoModal}
                productDetail={productDetail}
            />
            <UpdateProductDescriptionModal
                show={showUpdateProductDescriptionModal}
                setShow={setShowUpdateProductDescriptionModal}
                productDetail={productDetail}
            />
        </div>
    )
}

export default ProductInfo