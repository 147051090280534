import Filters from "./Filters";
import List from "./List";
import { useForm } from "react-hook-form";
import {useQuery} from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "krl-alfred";
import {getSearchParams, setUrlWithString} from "../../../utils/genericUtils";
import { getCustomerWithDetails } from "../../../api/routes/customer";
import {useEffect, useState} from "react";

const Customers = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [params,setParams] = useState(getSearchParams(searchParams));

  useEffect(() => {
    setParams(getSearchParams(searchParams));
  },[searchParams])

  const defaultPageSize = params?.defaultPageSize || 15;

  const {
    control,
    formState: { errors, isSubmitted },
    watch,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: params,
  });

  const submit = async (data) => {
    data.index = 1;
    let str = setUrlWithString(data);
    navigate(`${str}`);
    //await refetch()
  };

  const handleExport = async () => {
    toast({
      hasClosing: true,
      icon: "Danger",
      variant: "warning",
      title: "Burası yapılmadı şimdilik :)",
    });
  };

  const handleClear = async () => {
    //navigate(`?`);
    reset({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      startDate: "",
      endDate: "",
    });
  };

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["customerList", { ...params }],
    retry: 0,
    refetchOnReconnect: false,
    queryFn: async ({ pageParam }) => {
      let requestData = params;
      let filteredData = {};

      for (const [key, value] of Object.entries(requestData)) {
        if (typeof value === "object") {
          filteredData[key] = [value.value];
        } else {
          if (![undefined, null, ""].includes(value)) {
            filteredData[key] = value;
          }
        }
      }

      return await getCustomerWithDetails({
        size: defaultPageSize,
        index: params?.index || 1,
        ...filteredData,
      });
    }
  });

  return (
    <div id="customersPage">
      <Filters
        handleClear={handleClear}
        onFilter={handleSubmit(submit)}
        onExport={handleExport}
        control={control}
        errors={errors}
        watch={watch}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        isLoading={isFetching}
      />
      <List
        hasLoading={isFetching}
        data={data}
        defaultPageSize={defaultPageSize}
      />
    </div>
  );
};

export default Customers;
