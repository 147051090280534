import {useTranslation, Modal, toast, TextLink} from "krl-alfred";
import {useRef, useState} from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import CreateManualReturnCodeForm from "../Forms/CreateManualReturnCodeForm";
import {sendManualReturnCode} from "../api/routes/shipment";

const CreateManualReturnCodeModal = (props) => {
  const { show, setShow } = props;

  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState({
    isSubmitLoading: false,
  });

  const copyToClipboard = (text) => {
    inputRef.current.value = text
    inputRef.current.select();
    document.execCommand('copy');
    inputRef.current.blur();
  }

  const submitRef = useRef(null);

  const {
    control,
    formState: { errors, isSubmitted, isValid, dirtyFields },
    watch,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm();

  const submit = async (values) => {
    setIsLoading((prev) => ({ ...prev, isSubmitLoading: true }));
    await sendManualReturnCode({
      "providerType": values?.providerType?.value,
    }).then(async resp => {
      const toastVariables = {
        title: t("INFO.SUCCESS_CREATE_RETURN_CARGO_CODE"),
        icon: "CheckMark",
        hasClosing: false,
        variant: "primary",
        alignment: "left",
        showPrimaryButton: true,
        primaryButtonText: t("BUTTON.OK"),
        children: <>{watch("providerType")?.label} iade kodun: <TextLink alertText={t("INFO.COPIED")} hasAlert onClick={() => copyToClipboard(resp)}><b>{resp}</b></TextLink></>,
      }
      toast({
        ...toastVariables,
        onPrimaryAction: async () => {
          toast({
            ...toastVariables,
            hasClosing: true,
            show:false,
            timer:0
          })
        }
      })
    }).catch(err => {
      toast({
        hasClosing: true,
        icon: "Danger",
        variant: "warning",
        title: err?.response?.data?.message,
      });
    })
    setShow(false);
    setIsLoading((prev) => ({ ...prev, isSubmitLoading: false }));
  };

  return (
      <>
        <input style={{opacity:"0",position:"absolute",userSelect:"none",pointerEvents:"none"}} ref={inputRef}/>
        <Modal
            titleAlignment="left"
            buttonsAlignment="horizontal"
            showHeaderBorder
            showFooterBorder
            showPrimaryButton
            showSecondaryButton
            showCloseIcon
            showBackIcon={false}
            size="small"
            title={t("INFO.CREATE_RETURN_CODE")}
            primaryButtonText={t("BUTTON.GENERATE_CODE")}
            secondaryButtonText={t("BUTTON.GIVE_UP")}
            id="createManualReturnCodeModal"
            onClose={() => setShow(false)}
            onSecondaryAction={() => setShow(false)}
            show={show}
            onPrimaryAction={() => submitRef?.current?.click()}
            isPrimaryButtonLoading={isLoading.isSubmitLoading}
            isPrimaryButtonDisabled={Object.keys(dirtyFields).length <= 0}
            customContent
            primaryButtonWidth="50%"
            secondaryButtonWidth="50%"
        >
          <SimpleBar autoHide={false}>
            <div className="modalInnerContent">
              <CreateManualReturnCodeForm
                  onSubmit={handleSubmit(submit)}
                  control={control}
                  errors={errors}
                  isLoading={isLoading}
                  submitRef={submitRef}
                  dirtyFields={dirtyFields}
                  setValue={setValue}
                  setIsLoading={setIsLoading}
                  watch={watch}
              />
            </div>
          </SimpleBar>
        </Modal>
      </>
  );
};

export default CreateManualReturnCodeModal;
