import { useState } from "react";
import { formatDate } from "../../../utils/date";
import { DescriptionCard, useTranslation, TextLink, toast } from "krl-alfred";
import PaymentHistoryModal from "../../../Modals/PaymentHistoryModal";
import UpdateOrderProductModal from "../../../Modals/UpdateOrderProductModal";
import OrderScoreModal from "../../../Modals/OrderScoreModal";
import CargoBarcode from "./CargoBarcode";
import JsBarcode from "jsbarcode";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SalesOfferModal from "../../../Modals/SalesOfferModal";

const ItemSummary = ({ data, order, refetch, type }) => {
  const { t } = useTranslation();
  const [isShowPaymentHistoryModal, setIsShowPaymentHistoryModal] =
    useState(false);
  const [showUpdateOrderProductModal, setShowUpdateOrderProductModal] =
    useState(false);
  const [showOrderScoreModal, setShowOrderScoreModal] = useState(false);
  const [damageType, setDamageType] = useState(1);
  const [showSalesOfferModal, setShowSalesOfferModal] = useState(false);

  const handleOpenOrderScoreModal = (type = 1) => {
    setDamageType(type);
    setShowOrderScoreModal(true);
  };

  const handleExportCargo = async (cargoKey) => {
    await JsBarcode("#barcode", cargoKey, {
      format: "CODE128",
    });

    const elementHTML = document.querySelector(`#printibleContent_${data.id}`);
    html2canvas(elementHTML).then(function (canvas) {
      var imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("landscape", "px", "a7");
      doc.html("<img width='100%' src='" + imgData + "'/>", {
        callback: function (doc) {
          // Save the PDF
          doc.save(`kargo_${data.id}.pdf`);
        },
        filename: "Kargo Çıktısı",
        x: 10,
        y: 10,
        width: 200, //target width in the PDF document
        windowWidth: 750, //window width in CSS pixels
      });
    });
  };

  const exportCargoData = data?.orderShipments?.find(
    (cargoDetail) =>
      cargoDetail?.cargoDirectionType === 1 &&
      cargoDetail?.shippingCompanyId === 2 &&
      cargoDetail?.cargoKey
  );

  let list = [
    {
      key: "ID",
      title: t("PRODUCT.ID"),
      display: data?.id,
    },
    {
      key: "SKU",
      title: t("PRODUCT.SKU"),
      display: data?.sku,
    },
    {
      key: "QUANTITY",
      title: t("PRODUCT.QUANTITY"),
      display: data?.quantity,
    },
    {
      key: "SERIAL_NUMBER",
      title: t("PRODUCT.SERIAL_NUMBER"),
      display: data?.serialNumber,
    },
    {
      key: "PERIOD",
      title: t("PRODUCT.PERIOD"),
      display: data?.variantPrice?.priceTypeText,
    },
    {
      key: "IS_DAMAGED",
      title: t("PRODUCT.IS_DAMAGED"),
      display: data?.isDamaged ? t("INFO.YES") : t("INFO.NO"),
    },
    {
      key: "START_END_DATE",
      title: t("PRODUCT.START_END_DATE"),
      display: `${formatDate(
        data?.startDate,
        false,
        "DD.MM.YYYY",
        false
      )} - ${formatDate(data?.endDate, false, "DD.MM.YYYY", false)}`,
    },
    {
      key: "INSURANCE",
      title: t("PRODUCT.INSURANCE"),
      display:
        data?.fees && data?.fees?.length > 0 && data?.fees[0]?.type === 5
          ? t("INFO.AVAIBLE")
          : t("INFO.NOT"),
    },
    {
      key: "INSURANCE_PRICE",
      title: t("PRODUCT.INSURANCE_PRICE"),
      display:
        data?.fees && data?.fees?.length > 0 && data?.fees[0]?.type === 5
          ? data?.fees[0]?.unitPrice
          : "-",
    },
    {
      key: "IS_REPEAT_ORDER",
      title: t("PRODUCT.IS_REPEAT_ORDER"),
      display: data?.isRepeatOrder ? t("INFO.YES") : t("INFO.NO"),
    },
    {
      key: "REPEAT_ORDER_NUMBER",
      title: t("PRODUCT.REPEAT_ORDER_NUMBER"),
      display: data?.repeatOrderNumber,
    },
    {
      key: "TOTAL_PRICE",
      title: t("PRODUCT.TOTAL_PRICE"),
      display: `${data?.totalPriceText} ${data?.currencyCode}`,
    },
    {
      key: "HAS_DISCOUNT",
      title: t("PRODUCT.HAS_DISCOUNT"),
      display: data?.totalDiscount
        ? t("INFO.APPLIED")
        : t("INFO.NOT_IMPLEMENTED"),
    },
    {
      key: "DISCOUNT_PRICE",
      title: t("PRODUCT.DISCOUNT_PRICE"),
      display:
        data?.totalDiscount > 0
          ? `${data?.totalDiscountText} ${data?.currencyCode}`
          : "-",
    },
    {
      key: "PRICE",
      title: t("PRODUCT.AMOUNT"),
      display: `${data?.unitPriceText} ${data?.currencyCode}`,
    },
    {
      key: "IS_PURCHASED_ORDER",
      title: t("PRODUCT.IS_PURCHASED_ORDER"),
      display: data?.isOrderItemPurchased
        ? t("ORDER.PURCHASED")
        : data?.isSubscriptionOrder
        ? t("ORDER.SUBSCRIPTION")
        : t("ORDER.RENTAL"),
    },
    {
      key: "statusText",
      title: t("ORDER.ORDER_STATUS"),
      display: data?.statusText,
    },
  ];

  if (
    ["operationOrders", "todayShipmentOrders", "todayReturnedOrders"].includes(
      type
    )
  ) {
    list = list.filter(
      (column) =>
        ![
          "INSURANCE",
          "INSURANCE_PRICE",
          "TOTAL_PRICE",
          "HAS_DISCOUNT",
          "DISCOUNT_PRICE",
          "PRICE",
        ].includes(column.key)
    );
  }

  const dropdownList = [
    {
      name: t("BUTTON.UPDATE_ORDER_INFO"),
      component: ({ children }) => (
        <a
          href="javascript:void(0);"
          onClick={() => setShowUpdateOrderProductModal(true)}
        >
          {children}
        </a>
      ),
    },
    {
      name: t("BUTTON.RECEIVED_IT_DAMAGED"),
      component: ({ children }) => (
        <a
          href="javascript:void(0);"
          onClick={() => handleOpenOrderScoreModal(1)}
        >
          {children}
        </a>
      ),
    },
    {
      name: t("BUTTON.RECEIVED_IT_WITHOUT_DAMAGED"),
      component: ({ children }) => (
        <a
          href="javascript:void(0);"
          onClick={() => handleOpenOrderScoreModal(2)}
        >
          {children}
        </a>
      ),
    },
  ];

  if (data?.isSubscriptionOrder) {
    dropdownList.splice(1, 0, {
      name: t("BUTTON.SEE_PAYMENT_HISTORY"),
      component: ({ children }) => (
        <a onClick={() => setIsShowPaymentHistoryModal(true)}>{children}</a>
      ),
    });
  }
  if (data?.serialNumber && !["operationOrders", "todayShipmentOrders", "todayReturnedOrders"].includes(type)) {
    dropdownList.push({
      name: t("BUTTON.SEE_SALES_OFFER"),
      component: ({ children }) => (
        <a onClick={() => setShowSalesOfferModal(true)}>{children}</a>
      ),
    });
  }

  if (exportCargoData) {
    dropdownList.push({
      name: t("BUTTON.GENERATE_BARCODE_CODE"),
      component: ({ children }) => (
        <a onClick={() => handleExportCargo(exportCargoData?.cargoKey)}>
          {children}
        </a>
      ),
    });
  }

  const getCargoData = (cargo) => [
    {
      title: t("ORDER.CARGO.CARGO_DIRECTION_TYPE"),
      display: cargo?.cargoDirectionTypeText,
    },
    {
      title: t("ORDER.CARGO.CARGO_KEY"),
      display: cargo?.cargoKey,
    },
    {
      title: t("ORDER.CARGO.CARGO_TRACKING_NUMBER"),
      display: cargo?.cargoTrackingNumber,
    },
    {
      title: t("ORDER.CARGO.CARGO_TRACKING_URL"),
      display: (
        <TextLink
          onClick={() => window.open(cargo?.cargoTrackingUrl, "_blank")}
        >
          <b>{t("BUTTON.GO")}</b>
        </TextLink>
      ),
    },
    {
      title: t("ORDER.CARGO.CREATED_AT"),
      display: formatDate(cargo?.createdAt, false, "DD.MM.YYYY HH:mm", false),
    },
    {
      title: t("ORDER.CARGO.DELIVERY_DATE"),
      display: formatDate(
        cargo?.deliveryDate,
        false,
        "DD.MM.YYYY HH:mm",
        false
      ),
    },
    {
      title: t("ORDER.CARGO.QUANTITY"),
      display: cargo?.quantity,
    },
    {
      title: t("ORDER.CARGO.SHIPMENT_REF_NUMBER"),
      display: cargo?.shipmentRefNumber,
    },
    {
      title: t("ORDER.CARGO.SHIPPED_DATE"),
      display: formatDate(cargo?.shippedDate, false, "DD.MM.YYYY HH:mm", false),
    },
    {
      title: t("ORDER.CARGO.SHIPPING_COMPANY_NAME"),
      display: cargo?.shippingCompanyName,
    },
    {
      title: t("ORDER.CARGO.SHIPPING_DELIVERY_STATUS_TEXT"),
      display: cargo?.shippingDeliveryStatusText,
    },
  ];

  const accordionList = data?.orderShipments?.map((orderShipment, i) => ({
    title: orderShipment?.cargoDirectionTypeText,
    list: getCargoData(orderShipment),
  }));

  return (
    <div className="itemSummary">
      <DescriptionCard
        list={list}
        title={data?.productName}
        hasBadge
        hasHeaderButton
        hasHeaderDropdown
        headerButtonText={t("BUTTON.EDIT")}
        dropdownWidth="167px"
        headerButtonWidth="104px"
        dropdownList={dropdownList}
        headerImage={<img alt={data?.productName} src={data?.pictureUrl} />}
        badgeText={
          data?.isOrderItemPurchased
            ? t("ORDER.PURCHASED")
            : data?.isSubscriptionOrder
            ? t("ORDER.SUBSCRIPTION")
            : t("ORDER.RENTAL")
        }
        accordionList={accordionList}
      />
      <PaymentHistoryModal
        show={isShowPaymentHistoryModal}
        setShow={setIsShowPaymentHistoryModal}
        data={order?.transactions?.filter(
          (transaction) => transaction?.orderItemId === data?.id
        )}
        product={data}
      />
      <UpdateOrderProductModal
        show={showUpdateOrderProductModal}
        setShow={setShowUpdateOrderProductModal}
        data={data}
        refetch={refetch}
        order={order}
      />
      <OrderScoreModal
        show={showOrderScoreModal}
        setShow={setShowOrderScoreModal}
        data={[data]}
        refetch={refetch}
        order={order}
        damageType={damageType}
        hasProductDetail
      />
      <SalesOfferModal
        show={showSalesOfferModal}
        setShow={setShowSalesOfferModal}
        item={data}
        order={order}
      />

      <div
        style={{
          opacity: "0",
          position: "absolute",
          userSelect: "none",
          pointerEvents: "none",
          left: "0",
          right: "0",
          overflow: "hidden",
          bottom: "0",
        }}
      >
        <CargoBarcode product={data} order={order} />
      </div>
    </div>
  );
};

export default ItemSummary;
