import {DescriptionCard, useTranslation} from "krl-alfred";

const AddressSummary = ({data,title}) => {

    const {t} = useTranslation();

    const list = [
        {
            title: 'İsim Soyisim',
            display: data?.fullName,
        },
        {
            title: t("CUSTOMER.E_MAIL"),
            display: data?.email
        },
        {
            title: t("CUSTOMER.PHONE_NUMBER"),
            display: data?.phone,
        },
        {
            title: t("CUSTOMER.ADDRESS.TITLE"),
            display: data?.title,
        },
        {
            title: t("CUSTOMER.ADDRESS.COUNTRY"),
            display: data?.country,
        },
        {
            title: t("CUSTOMER.ADDRESS.CITY"),
            display: data?.city
        },
        {
            title: t("CUSTOMER.ADDRESS.COUNTY"),
            display: data?.county
        },
        {
            title: t("CUSTOMER.ADDRESS.STREET_ADDRESS"),
            display: data?.streetAddress
        },
        {
            title: t("CUSTOMER.ADDRESS.TYPE"),
            display: data?.type === 1 ? 'Bireysel' : 'Kurumsal'
        }
    ]

    if(data?.type === 2){
        list.push(
            {
                title: t("CUSTOMER.ADDRESS.CORPORATE_NAME"),
                display: data?.corporateName
            },
            {
                title: t("CUSTOMER.ADDRESS.TAX_NUMBER"),
                display: data?.taxNumber
            },
            {
                title: t("CUSTOMER.ADDRESS.TAX_OFFICE"),
                display: data?.taxOffice
            }
        )
    }

    return (
        <div className="addressSummary">
            <DescriptionCard
                list={list}
                title={title}
            />
        </div>
    )
}

export default AddressSummary