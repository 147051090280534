import SimpleBar from "simplebar-react";
import { useTranslation, Modal, toast } from "krl-alfred";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { addAndRemoveProductToBox } from "../api/routes/box";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import AddProductToBoxForm from "../Forms/AddProductToBoxForm";

const AddProductToBoxModal = ({ show, setShow, box }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const submitRef = useRef(null);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm();

  const submit = async (values) => {
    setIsLoading(true);

    await addAndRemoveProductToBox({
      boxId: values?.boxId,
      productId: values?.productIds?.value,
    })
      .then(() => {
        toast({
          hasClosing: true,
          title: t("INFO.SUCCESS_UPDATED_TO_BOX"),
        });
      })
      .catch((err) => {
        toast({
          hasClosing: true,
          icon: "Danger",
          variant: "warning",
          title: err?.response?.data?.message,
        });
      })
      .finally(() => {
        setShow(false);
      });

    setIsLoading(false);
    queryClient.invalidateQueries("productListToBox");
  };

  useEffect(() => {
    if (show) {
      reset({
        boxId: box?.id,
        productIds: null,
      });
    }
  }, [show]);

  return (
    <>
      <Modal
        titleAlignment="left"
        buttonsAlignment="horizontal"
        showHeaderBorder
        showFooterBorder
        showPrimaryButton
        showSecondaryButton
        showCloseIcon
        showBackIcon={false}
        size="small"
        title={t("INFO.ADD_PRODUCT_TO_BOX")}
        primaryButtonText={t("BUTTON.ADD")}
        secondaryButtonText={t("BUTTON.GIVE_UP")}
        id="addProductToBoxModal"
        onClose={() => setShow(false)}
        show={show}
        onPrimaryAction={() => submitRef?.current?.click()}
        onSecondaryAction={() => setShow(false)}
        customContent
        primaryButtonWidth="50%"
        secondaryButtonWidth="50%"
        isPrimaryButtonDisabled={!isValid}
        isPrimaryButtonLoading={isLoading}
      >
        <SimpleBar autoHide={false}>
          <div className="modalInnerContent">
            <AddProductToBoxForm
              control={control}
              errors={errors}
              onSubmit={handleSubmit(submit)}
              submitRef={submitRef}
            />
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default AddProductToBoxModal;
