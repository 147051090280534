import {Form, Input, DatePicker, Button, Row, Col, Select} from 'antd'
import React, {useContext} from 'react'
import { formatDate } from "../../utils/date";
import {OrderContext} from "../../Context/OrderContex";

const { Option } = Select;

function Filters({ onSubmit,initialValues }) {

  const { ProviderTypes } = useContext(OrderContext);

  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinStartDate: value.MinStartDate ? formatDate(new Date(value.MinStartDate),false,"") : undefined,
      MaxStartDate: value.MaxStartDate ? formatDate(new Date(value.MaxStartDate),false,"") : undefined,
      MinEndDate: value.MinEndDate ? formatDate(new Date(value.MinEndDate),false,"") : undefined,
      MaxEndDate: value.MaxEndDate ? formatDate(new Date(value.MaxEndDate),false,"") : undefined,
      OrderStartCreatedDate: value.OrderStartCreatedDate ? formatDate(new Date(value.OrderStartCreatedDate),false,"") : undefined,
      OrderEndCreatedDate: value.OrderEndCreatedDate ? formatDate(new Date(value.OrderEndCreatedDate),false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      className="filters"
      onFinish={onFinish}
    >
      <Row>
        <Col md={12}>
          <Form.Item label="Ürün Adı" name="ProductName">
            <Input />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Seri Numarası" name="ProductSerialNumber">
            <Input />
          </Form.Item>
        </Col>
      </Row>
        <Row>
          <Col md={8}>
          <Form.Item label="Min. Kiralama Başlangıç" name="MinStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
           <Col md={8}>
          <Form.Item label="Max. Kiralama Başlangıç" name="MaxStartDate">
            <DatePicker />
          </Form.Item>
          </Col>
           <Col md={8}>
             <Form.Item label="Sipariş Başlangıç tarihi" name="OrderStartCreatedDate">
               <DatePicker />
             </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
          <Form.Item label="Min. Kiralama Bitiş" name="MinEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={8}>
          <Form.Item label="Max. Kiralama Bitiş" name="MaxEndDate">
            <DatePicker />
          </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Sipariş Bitiş tarihi" name="OrderEndCreatedDate">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Ürün Kaynağı" name="providerType">
              <Select allowClear style={{ width: "100%" }} placeholder="Please select">
                {ProviderTypes.data.map((status) => (
                    <Option key={status.id} value={status.value}>{status.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
