import {Form, DatePicker, Button, Col, Row} from "antd";
import React from "react";
import {formatDate} from "../../utils/date";

function Filters({onSubmit, initialValues}) {

    const onFinish = (value) => {
        onSubmit({
            ...value,
            StartDate: value.StartDate ? formatDate(new Date(value.StartDate), false, "") : undefined,
        });
    };


    return (
        <Form labelCol={{span: 4}}
              wrapperCol={{span: 14}}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <div className="filters">
                <Row>
                    <Col md={12}>
                        <Form.Item label="Min. Başl. Tarihi" name="StartDate">
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrele
                </Button>
            </Form.Item>
        </Form>
    );
}

export default Filters;
