import Filters from "./Filters";
import {useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "krl-alfred";
import {getSearchParams, setUrlWithString} from "../../../utils/genericUtils";
import Calendar from "./Calendar";
import {useEffect, useState} from "react";
import {formatDate} from "../../../utils/date";
import moment from "moment";
import {useQuery} from "@tanstack/react-query";
import {calendarFilter} from "../../../api/routes/order";

const OrderCalendar = () => {
    const calendarDateFormat = "YYYY-MM-DD";
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf("isoWeek").add(-1, 'days');
    const weekEnd = currentDate.clone().endOf("isoWeek").add(1, 'days');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [requestParams, setRequestParams] = useState(getSearchParams(searchParams));

    useEffect(() => {
        setRequestParams(getSearchParams(searchParams));
    }, [searchParams])

    const [calendarData, setCalendarData] = useState(null);
    const [sendParams, setSendParams] = useState({
        minStartDate: formatDate(weekStart.format(), false, calendarDateFormat),
        maxStartDate: formatDate(weekEnd.format(), false, calendarDateFormat),
        index: 1,
        size: 1000,
        paymentStatuses: 1,
    });
    const [deliveryParams, setDeliveryParams] = useState({
        minEndDate: formatDate(weekStart.format(), false, calendarDateFormat),
        maxEndDate: formatDate(weekEnd.format(), false, calendarDateFormat),
        index: 1,
        size: 1000,
        paymentStatuses: 1,
    });
    const [dates, setDates] = useState({
        start: formatDate(weekStart.format(), false, calendarDateFormat),
        end: formatDate(weekEnd.format(), false, calendarDateFormat),
    });

    const {
        data: sendData,
        isFetching: sendIsFetching,
        refetch: sendRefetch
    } = useQuery({
        queryKey: ['calendarSend', {sendParams, requestParams}],
        retry: 0,
        queryFn: () => calendarFilter({...sendParams, ...requestParams})
    });
    const {
        data: deliveryData,
        isFetching: deliveryIsFetching,
        refetch: deliveryRefetch
    } = useQuery({
        queryKey: ['calendarDelivery', {deliveryParams, requestParams}],
        retry: 0,
        queryFn: () => calendarFilter({...deliveryParams, ...requestParams})
    });


    useEffect(() => {
        const refactorData1 = []

        sendData?.data?.map(item => {
            const mappedData = [];
            item?.orderItemResponses.map(item => {
                if (mappedData?.filter(itemSm => itemSm?.starDate === item?.starDate && itemSm?.orderNumber === item?.orderNumber)?.length <= 0) {
                    mappedData.push(item)
                }
            })
            return (mappedData?.map(orderItem => {
                    refactorData1.push({
                        title: item.orderNumberByCalendar,
                        orderNumber: item.orderNumber,
                        date: formatDate(orderItem?.starDate, false, calendarDateFormat),
                        eventType: item.orderDirection,
                        filterProductCount: item.filterProductCount,
                    })
                    return orderItem
                })
            )
        });

        const refactorData2 = []
        deliveryData?.data?.map(item => {
            const mappedData = [];
            item?.orderItemResponses.map(item => {
                if (mappedData?.filter(itemSm => itemSm?.endDate === item?.endDate && itemSm?.orderNumber === item?.orderNumber)?.length <= 0) {
                    mappedData.push(item)
                }
            })

            return (mappedData?.map(orderItem => {
                    refactorData2.push({
                        title: item.orderNumberByCalendar,
                        orderNumber: item.orderNumber,
                        date: formatDate(orderItem?.endDate, false, calendarDateFormat),
                        eventType: item.orderDirection,
                        filterProductCount: item.filterProductCount,
                    })
                    return orderItem
                })
            )
        })

        setCalendarData([...refactorData1, ...refactorData2])
    }, [sendIsFetching, deliveryIsFetching]);


    useEffect(() => {
        if (dates.start && dates.end) {
            setSendParams({
                ...sendParams,
                minStartDate: dates.start,
                maxStartDate: dates.end,
            })
            setDeliveryParams({
                ...deliveryParams,
                minEndDate: dates.start,
                maxEndDate: dates.end,
            })
        }
    }, [dates]);

    const {
        control,
        formState: {errors, isSubmitted},
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm({
        defaultValues: requestParams,
    });

    const submit = async (data) => {
        data.productIds = data?.productIds ? JSON.stringify(data.productIds) : null
        let str = setUrlWithString(data);
        navigate(`${str}`);
    };

    const handleExport = async () => {
        toast({
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Burası yapılmadı şimdilik :)",
        });
    };

    const handleClear = async () => {
        //navigate(`?`);
        reset({
            billingAddressEmail: "",
            orderNumber: "",
            startDate: "",
            endDate: "",
            orderStatuses: "",
            productIds: "",
        });
    };

    return (
        <div id="orderCalendarPage">
            <Filters
                handleClear={handleClear}
                onFilter={handleSubmit(submit)}
                onExport={handleExport}
                control={control}
                errors={errors}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                isLoading={sendIsFetching && deliveryIsFetching}
            />
            <Calendar
                sendParams={sendParams}
                setSendParams={setSendParams}
                deliveryParams={deliveryParams}
                setDeliveryParams={setDeliveryParams}
                dates={dates}
                setDates={setDates}
                calendarData={calendarData}
                isLoading={sendIsFetching && deliveryIsFetching}
            />
        </div>
    );
};

export default OrderCalendar;
