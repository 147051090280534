import { Table, Descriptions } from "antd";
import useRequest from "../../Hooks/useRequest";
import { NavLink, useParams } from "react-router-dom";
import usePagination from "../../Hooks/usePagination";
import { getRentalOrders } from "../../Service/console";
import { formatDate } from "../../utils/date";

const CurrentRentalReportDetailTable = () => {
  const { productId } = useParams();
  const [params, setParams] = usePagination({
    ProductId: productId,
    SerialNumber: "",
    OrderNumber: undefined,
  });

  const {
    data = {},
    status,
    message,
    update,
  } = useRequest(getRentalOrders, [params]);

  const Columns = [
    {
      key: "orderNumber",
      title: "Sipariş Numarası",
      dataIndex: "orderNumber",
      render: (_, data) => (
        <NavLink to={`/orders-management/orders/${data.orderNumber}`}>
          <a style={{ color: "black", textDecoration: "underline" }}>
            {data.orderNumber}
          </a>
        </NavLink>
      ),
    },
    {
      key: "serialNumber",
      title: "Seri Numarası",
      dataIndex: "serialNumber",
    },
    {
      key: "startDate",
      title: "Başlangıç Tarihi",
      dataIndex: "startDate",
      render: (date) => formatDate(date),
    },
    {
      key: "endDate",
      title: "Bitiş Tarihi",
      dataIndex: "endDate",
      render: (date) => formatDate(date),
    },
  ];

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onShowSizeChange: (_, pageSize) => {
      setParams({ Size: pageSize });
    },
    onChange: (page) => {
      setParams({ Index: page });
    },
    pageSizeOptions: params.INDEX_PAGE_SIZE_OPTIONS,
    total: data.totalCount,
  };
  const pagination = {
    ...paginationOptions,
    total: data.totalCount,
    current: data.index,
    pageSize: data.size,
  }; 

  
  return (
    <>
      <Descriptions title={status === "success" ? data.data[0].productName : []}>
      </Descriptions>
      
      <Table
          loading={status === "pending"}
          dataSource={status === "success" ? data.data[0].rentalOrderGroupResponses : []}
          columns={Columns}
          pagination={pagination}
          rowKey={"serialNumber"}
        />
    </>
  );
};

export default CurrentRentalReportDetailTable;
