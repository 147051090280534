import {Controller} from "react-hook-form";
import {SelectBox, useTranslation} from "krl-alfred";
import {useContext} from "react";
import {OrderContext} from "../Context/OrderContex";
import {focusSelect} from "../utils/genericUtils";

function CreateManualReturnCodeForm({
  onSubmit,
  control,
  errors,
  submitRef,
  dirtyFields,
  setValue,
  setIsLoading,
  watch
}) {

    const { t } = useTranslation();
    const { cargoProviderTypes } = useContext(OrderContext);

  return (
      <form onSubmit={onSubmit}>

          <div className="shipmentDetail">
              <Controller
                  name="providerType"
                  control={control}
                  render={({field: {onChange, value, name}}) => (
                      <SelectBox
                          value={value}
                          name={name}
                          label={t("FORM.LABEL.PROVIDER_TYPE")}
                          instanceId="orderStatusesSelect"
                          width="100%"
                          placeholder={t("FORM.INPUT.PROVIDER_TYPE")}
                          isSearchable={false}
                          lightBg={false}
                          //filterOption={customSelectFilter}
                          onChange={onChange}
                          onFocus={e => focusSelect(e.target)}
                          onMenuClose={() => document.activeElement.blur()}
                          noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                          hasError={errors.providerType}
                          errorMessage={t("FORM.ERROR.PROVIDER_TYPE")}
                          maxOptionListHeight="153px"
                          options={cargoProviderTypes?.map((type) => ({
                              value: type.value,
                              label: type.label,
                          }))}
                          hasShowDropdownIcon
                      />
                  )}
                  rules={{required: true}}
              />
          </div>

              <div className="d-none">
                  <button type="submit" ref={submitRef}/>
              </div>
      </form>
);
}

export default CreateManualReturnCodeForm;
