import UserDropdown from "./UserDropdown"

const Header = () => {
    return (
        <header id="siteHeader">
            <div className="logo">
                <img src="/logo.svg" alt="logo"/>
            </div>
            <div className="rightContent">
                <UserDropdown/>
            </div>
        </header>
    )
}

export default Header