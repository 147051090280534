import { Modal, useTranslation } from 'krl-alfred'

const ConfirmationModal = props => {
  const { show, setShow, contentTitle, contentDescription } = props

  const { t } = useTranslation()

  return (
    <Modal
      titleAlignment="left"
      buttonsAlignment="horizontal"
      showBackIcon={false}
      size="xsmall"
      title=""
      titleDescription=""
      showPrimaryButton
      showSecondaryButton
      showCloseIcon
      id="confirmationModal"
      primaryButtonText={t('BUTTON.DELETE')}
      secondaryButtonText={t('BUTTON.GIVE_UP')}
      autoHeight
      onClose={() => setShow(false)}
      onSecondaryAction={() => setShow(false)}
      show={show}
      primaryButtonWidth="50%"
      secondaryButtonWidth="50%"
      {...props}>
      <div className="confirmationModalInner">
        <strong className="title">{contentTitle}</strong>
        <p className="description">{contentDescription}</p>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
