import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import trLocale from '@fullcalendar/core/locales/tr';
import { formatDate } from '../../../utils/date';
import { Icon, Loader, useTranslation } from 'krl-alfred';
import {getOrderDetailByOperation} from '../../../api/routes/order';
import { useState } from 'react';
import OrderDetailModal from '../../../Modals/OrderDetailModal';
import {getCustomerDetailByOperation} from '../../../api/routes/customer';

const Calendar = ({
    sendParams,
    setSendParams,
    deliveryParams,
    setDeliveryParams,
    dates,
    setDates,
    calendarData,
    isLoading
}) => {
    const calendarDateFormat = "YYYY-MM-DD"
    const { t } = useTranslation();
    const [isOrderDetailLoading,setIsOrderDetailLoading] = useState(false)
    const [selectedOrderDetail,setSelectedOrderDetail] = useState(null)
    const [showOrderDetailModal,setShowOrderDetailModal] = useState(false)

    async function handleOrderDetail(orderNumber) {
        setIsOrderDetailLoading(true)
        setShowOrderDetailModal(true)
        await getOrderDetailByOperation(orderNumber).then(async resp => {
            await getCustomerDetailByOperation(resp.customerId).then(respCustomer => {
                setSelectedOrderDetail({...resp,customer:respCustomer})
            }).catch(err => err)
        }).catch(err => err)
        setIsOrderDetailLoading(false)
    }
    
    function renderEventContent(eventInfo) {
        return (
            <div className="eventDetail">
                <b className={`eventType ${["Uzak gönder","Gönder"].includes(eventInfo.event.extendedProps.eventType) ? "success" : "warning"}`}>
                    <Icon name="CircleFilled"/>
                    {eventInfo.event.extendedProps.eventType}
                </b>
                <strong className="eventName">{eventInfo.event.title}</strong>
                {eventInfo.event.extendedProps.filterProductCount && <div className="eventProductCount">Ürün sayısı: <b>{eventInfo.event.extendedProps.filterProductCount}</b></div>}
            </div>
        )
    }
    
    return (
        <div className='fullCalendar'>
            {(isLoading) && (
                <div className="loadingPage">
                    <Loader width="24px" height="24px" variant="primary"/>
                </div>
            )}
            <FullCalendar
                    plugins={[dayGridPlugin, listPlugin]}
                    initialView="dayGridWeek"
                    firstDay={1}
                    locale={trLocale}
                    editable
                    height="auto"
                    eventOrder="eventType"
                    eventContent={renderEventContent}
                    datesSet={(e) => {
                        const startDate = new Date(e.start);
                        startDate.setDate(startDate.getDate() - 1);
                        setDates({
                            start: formatDate(startDate, false, calendarDateFormat),
                            end: formatDate(e.end, false, calendarDateFormat),
                        })
                    }}
                    titleFormat={e => {
                        const start = formatDate(e.start, false, "MMMM")
                        const end = formatDate(e.end, false, "MMMM")
                        if(e.start.year !== e.end.year){
                            return `${e.start.day} ${start} ${e.start.year} – ${e.end.day} ${end} ${e.end.year}`
                        }else if(e.start.month !== e.end.month){
                            return `${e.start.day} ${start} – ${e.end.day} ${end}, ${e.end.year}`
                        }else{
                            return `${e.start.day} – ${e.end.day} ${start}, ${e.end.year}`
                        }
                    }}
                    eventClick={(e) => {
                        handleOrderDetail(e?.event?.extendedProps?.orderNumber)
                    }}

                    buttonText= {{
                        month : t("BUTTON.MONTHLY"),
                        week : t("BUTTON.WEEKLY"),
                    }}
                    events={calendarData}
                    headerToolbar={{
                        left: 'title',
                        right: 'prev,today,next,dayGridMonth,dayGridWeek',
                    }}
                    dayHeaderFormat={{ weekday: 'long'}}
                    //dayHeaderFormat={{ weekday: 'long', month: 'long', day: 'numeric', omitCommas: true }}
                    //dayHeaderContent={(args) => {
                    //    //return moment(args.date).format('ddd Do')
                    //    return args.view.type === "dayGridWeek" ? args.text : args.text.split(" ")[args.text.split(" ").length - 1]
                    //}}
                    dayCellContent={e => {
                        const dayNumber = formatDate(e.date, false, "DD")
                        const day = e.dayNumberText ? e.dayNumberText : dayNumber
                        return day
                    }}
                />
                <OrderDetailModal 
                    data={selectedOrderDetail}
                    show={showOrderDetailModal}
                    setShow={setShowOrderDetailModal}
                    isLoading={isLoading || isOrderDetailLoading}
                />
        </div>
    )
}

export default Calendar