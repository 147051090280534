import { formatDate } from "../../../utils/date";
import { DescriptionCard, useTranslation } from "krl-alfred";

const CustomerSummary = ({ data, type }) => {
    
  const { t } = useTranslation();
  
  let list = [
    {
      source: "id",
      title: t("CUSTOMER.CUSTOMER_ID"),
      display: data?.id,
    },
    {
      source: "firstName",
      title: t("CUSTOMER.FIRST_NAME"),
      display: data?.firstName,
    },
    {
      source: "lastName",
      title: t("CUSTOMER.LAST_NAME"),
      display: data?.lastName,
    },
    {
      source: "createdAt",
      title: t("LIST.CREATED_AT"),
      display: formatDate(data?.createdAt,true,"DD.MM.YYYY HH:mm:ss",false),
    },
    {
      source: "email",
      title: t("CUSTOMER.E_MAIL"),
      display: data?.email,
    },
    {
      source: "identityNumber",
      title: t("CUSTOMER.IDENTIFICATION_NUMBER"),
      display: data?.customerInfo?.identificationNumber,
    },
    {
      source: "dateOfBirth",
      title: t("CUSTOMER.DATE_OF_BIRTH"),
      display: formatDate(data?.customerInfo?.dateOfBirth,false,"DD.MM.YYYY",false),
    },
    {
      source: "phone",
      title: t("CUSTOMER.PHONE_NUMBER"),
      display: data?.phone,
    },
    {
      source: "lastLoginDateAt",
      title: t("CUSTOMER.LAST_LOGIN_DATE_AT"),
      display: formatDate(data?.customerInfo?.lastLoginDateAt,true,"DD.MM.YYYY HH:mm:ss",false),
    },
    {
      source: "isVerified",
      title: t("CUSTOMER.IS_VERIFIED"),
      display: data?.isVerified ? t("CUSTOMER.VERIFIED") : t("CUSTOMER.NOT_VERIFIED"),
    },
    {
      source: "isActive",
      title: t("CUSTOMER.IS_ACTIVE"),
      display: data?.isActive ? t("CUSTOMER.ACTIVE") : t("CUSTOMER.PASSIVE"),
    },
    {
      source: "isDeleted",
      title: t("CUSTOMER.IS_DELETED"),
      display: data?.isDeleted ? t("CUSTOMER.DELETED") : t("CUSTOMER.NOT_DELETED"),
    },
    {
      source: "rate",
      title: t("CUSTOMER.RATE"),
      display: data?.customerInfo?.rate,
    },
  ];

  if(["operationOrders","todayShipmentOrders","todayReturnedOrders"].includes(type)){
    list = list.filter(column => !["createdAt","email","identityNumber","dateOfBirth","lastLoginDateAt","isVerified","isActive","isDeleted"].includes(column.source))
  }

  return (
    <div className="customerSummary">
      <DescriptionCard list={list} title={t("CUSTOMER.CUSTOMER_DETAIL")} />
    </div>
  );
};

export default CustomerSummary;
