import moment from "moment";
import 'moment/locale/tr';
export const formatDate = (date,time=false,format="DD.MM.YYYY",clear=true) => {
    let formattedDate;
    if(time)
        format = "DD.MM.YYYY HH:mm:ss"
    
    if(clear){
        formattedDate = moment(date).set({hour:0,minute:0,second:0,millisecond:0}).format(format);
    }else{
        formattedDate = moment(date).format(format);
    }

    return formattedDate;
};