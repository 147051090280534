import {Link, useLocation, useRoutes,useParams } from "react-router-dom";
import {DropdownButton,useTranslation} from "krl-alfred"
import Routes from "../../Routes/Routes";
import {useEffect, useState} from "react";
import CreateManualReturnCodeModal from "../../Modals/CreateManualReturnCodeModal";
import BoxDetailModal from "../../Modals/BoxDetailModal";

function Navigation(props) {

    const [showReturnCodeModal,setShowReturnCodeModal] = useState(false)
    const [showBoxDetailModal,setShowBoxDetailModal] = useState(false)

    const {t} = useTranslation();

    const location = useLocation();

    const children = useRoutes(Routes);
    const matchPath = children?.props?.match?.route?.path
    
    const { pathname } = location;
    
    const setComponent = (slug, children, hasLink = true,onClick) =>
        hasLink ? (
            <Link to={slug}>{children}</Link>
        ) : (
            <a onClick={onClick}>{children}</a>
        )

    const parentElem = Routes.find(routeSm => routeSm.path === pathname);
    const activeRoute = Routes.find(routeSm => routeSm.path === matchPath);

    const renderRoutes = Routes.map(route => {
        if(route?.id === "GENERATE_RETURN_CODE"){
            route.onClick = () => setShowReturnCodeModal(true)
        }else if(route?.id === "ADD_BOX"){
            route.onClick = () => setShowBoxDetailModal(true)
        }
        
        return {
            ...route,
            title: t(`ROUTE.${route?.id}`),
            isActive: route?.id === parentElem?.parent || route?.path === pathname || activeRoute.isActiveMenu === route?.id || activeRoute.parent === route?.id,
            component: ({children}) => setComponent(route?.targetLink || route?.path, children,!!route?.path,route?.onClick)
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        const selectedRoute = Routes.find(item => item.path === matchPath)
        if(selectedRoute?.id){
            document.title = `${t(`ROUTE.${selectedRoute?.id}`)} - Kiralarsın admin`
        }else{
            document.title = "Kiralarsın admin"
        }
    },[pathname])

    return (
        <>
            <div id="siteNavigation">
                <div className="dropdownList">
                    {renderRoutes.filter(item => !item.parent && item.type !== "detail").map(dropdownItem => {
                        const dropdownList = renderRoutes.filter(route => route.parent === dropdownItem.id && route.type !== "detail");
                        return <DropdownButton
                                    key={dropdownItem.id} 
                                    isActive={dropdownItem.isActive} 
                                    component={dropdownItem.component}
                                    title={dropdownItem.title} 
                                    icon={dropdownItem.icon}
                                    hasDropdown={dropdownList.length > 0}
                                    list={dropdownList}
                                />
                    })}
                </div>
            </div>
            <CreateManualReturnCodeModal
                show={showReturnCodeModal}
                setShow={setShowReturnCodeModal}
            />
            <BoxDetailModal
                show={showBoxDetailModal}
                setShow={setShowBoxDetailModal}
            />
        </>
    );
}

export default Navigation;
