import {useTranslation, Modal, toast, TextLink} from "krl-alfred";
import SimpleBar from "simplebar-react";
import ReturnShipmentForm from "../Forms/ReturnShipmentForm";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { sendShipment } from "../api/routes/order";

const ReturnShipmentWithItemModal = (props) => {
    const {
        show,
        setShow,
        data
    } = props

    const submitRef = useRef(null)
    const [isLoading,setIsLoading] = useState({
        isSubmitLoading:false
    })
    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue
    } = useForm()

    const { t } = useTranslation()
    
    const [step,setStep] = useState(1)
    
    const handleNextStep = async (values) => {
        const validProductIds = []
        const boxIds = []

        values?.productIds?.map((item,key) => item && validProductIds.push(key) )
        
        if(step === 1){
            if(validProductIds?.length <= 0){
                values?.productIds?.map((item,key) => setError(`productIds[${key}]`,true) )
                return
            }
            clearErrors()
            setStep(2)
        }else{

            values?.boxIds?.map((item,key) => boxIds?.push(item?.value) )
           
            const sendedValues = {
                orderNumber: data?.orderNumber,
                providerType: values?.providerType?.value,
                orderItemId: validProductIds,
                cargoDirectionType: "Incoming",
                quantity: values?.quantity?.value,
                boxIds: boxIds
            }

            const toastVariables = {
                title: t('INFO.SHIPPING_CODE_CREATED'),
                icon: "CheckMark",
                hasClosing: false,
                alignment: "left",
                showPrimaryButton: true,
                primaryButtonText: t("BUTTON.OK"),
                //children: <div className="toastMessageFont">{t('INFO.RETURN_SHIPPING_CODE')} <TextLink><b>test</b></TextLink></div>,
                isSecondaryButtonLoading: false,
                onSecondaryAction: (e) => e,
                onPrimaryAction: (e) => toast({
                    show: false,
                    hasClosing: true,
                    timer: 1,
                })
            }

            setIsLoading((prev) => ({ ...prev, isSubmitLoading: true }));
            await sendShipment(sendedValues).then(resp => {
                toast(toastVariables)
            }).catch(err => {
                toast({
                    ...toastVariables,
                    icon: "TimesLg",
                    variant: "danger",
                    children: null,
                    title: t('INFO.SOMETHING_WENT_ERROR'),
                })
            })
            setIsLoading((prev) => ({ ...prev, isSubmitLoading: false }));
            setShow(false)
        }
    }

    useEffect(() => {
        setStep(1)
        reset({
            productIds: null,
            providerType: null,
            quantity: null,
            boxIds: null
        })
    },[show])

    return (
        <Modal
            titleAlignment="left"
            buttonsAlignment="horizontal"
            showHeaderBorder
            showFooterBorder
            showPrimaryButton
            showSecondaryButton
            showCloseIcon
            showBackIcon={false}
            size="small"
            title={t("INFO.GENERATE_RETURN_CODE")}
            primaryButtonText={t('BUTTON.CONTINUE')}
            secondaryButtonText={t('BUTTON.GIVE_UP')}
            id="returnShipmentWithItemModal"
            onClose={() => setShow(false)}
            onSecondaryAction={() => setShow(false)}
            show={show}
            onPrimaryAction={() => submitRef.current.click()}
            isPrimaryButtonLoading={isLoading?.isSubmitLoading}
            customContent
            primaryButtonWidth="50%"
            secondaryButtonWidth="50%"
        >
            <SimpleBar autoHide={false}>
                <div className="modalInnerContent">
                    <div className="customerDetail">
                        <strong className="title">{t("ORDER.ORDER_INFO")}</strong>
                        <ul className="detailList">
                            <li>
                                <span className="label">{t("CUSTOMER.FIRST_AND_LAST_NAME")}: </span>
                                <span
                                    className="value">{`${data?.shippingAddresses[0]?.firstName} ${data?.shippingAddresses[0]?.lastName}`}</span>
                            </li>
                            <li>
                                <span className="label">{t("ORDER.ORDER_NUMBER")}: </span>
                                <span className="value">{`${data?.orderNumber}`}</span>
                            </li>
                            <li>
                                <span className="label">{t("ORDER.SHIPPING_ADDRESS")}: </span>
                                <span className="value">
                                    {data?.shippingAddresses[0]?.streetAddress}<br/>
                                    {data?.shippingAddresses[0]?.county}/{data?.shippingAddresses[0]?.city}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <ReturnShipmentForm 
                        data={data?.items}
                        control={control}
                        errors={errors}
                        step={step}
                        submitRef={submitRef}
                        onSubmit={handleSubmit(handleNextStep)}
                        dirtyFields={dirtyFields}
                        watch={watch}
                        setValue={setValue}
                        setIsLoading={setIsLoading}
                    />
                </div>
            </SimpleBar>
        </Modal>
    )
}

export default ReturnShipmentWithItemModal