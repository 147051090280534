import {request} from "../request";

export const getOrderShipment = async (params) => {
    const refactorParams = Object?.keys(params)?.length > 0 ? {...params} : null
    let productIds = "productIds=null"
    if(params?.productIds){
        productIds = JSON.parse(params?.productIds).map(item => item.value).join(",").split(",")?.map(item => "productIds=" + item).join("&");
        delete refactorParams.productIds
    }
    return await request({
        url: `console/Shipment/get-order-shipment?${productIds}`,
        method: 'get',
        params: refactorParams,
        paramsSerializer: {
            indexes: true, // use brackets with indexes
        }
    })
}

export const sendManualReturnCode = async (data) => await request({
    url: 'console/Shipment/send-manual-return-code',
    method: 'post',
    data,
})