import ProductList from "./ProductList";

function OrderScoreForm({ onSubmit, control, errors, isLoading, submitRef, productList, hasProductDetail }) {
  return (
    <form onSubmit={onSubmit}>
      <ProductList
        data={productList}
        control={control}
        errors={errors}
        hasProductDetail={hasProductDetail}
      />
      <div className="d-none">
        <button type="submit" ref={submitRef} />
      </div>
    </form>
  );
}

export default OrderScoreForm;
