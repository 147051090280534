import ProductCard from "./Productcard";

const ProductList = ({ control, data, errors, hasDisabled, activeStep, watch, setValue, clearErrors }) => {

  return (
    <div className="productList">
      {data?.map((item, index) => <ProductCard
          activeStep={activeStep}
          control={control}
          item={item}
          watch={watch}
          errors={errors}
          index={index}
          setValue={setValue}
          clearErrors={clearErrors}
      />)}
    </div>
  );
};

export default ProductList;
