import { useTranslation, Modal, ProductCardCheckbox, Loader } from "krl-alfred"
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { getPurchasePrice } from "../api/routes/purchasePrice";

const SalesOfferModal = (props) => {
    const {
        show,
        setShow,
        item,
        order
    } = props

    const { t } = useTranslation()
    const [data,setData] = useState()
    const [isLoading,setIsLoading] = useState(false)
    
    useEffect(() => {
        if(show){
            setIsLoading(true)
            getPurchasePrice({
                serialNumber: item?.serialNumber,
                customerId: order?.customerId,
                productId: item?.productId,
                orderNumber: order?.orderNumber
            }).then(res => {
                setData(res)
            }).finally(final => {
                setIsLoading(false)
            })
        }
    },[show])
    
    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={t("INFO.SEE_SALES_OFFER")}
                primaryButtonText={t('BUTTON.OK')}
                id="salesOfferModal"
                onClose={() => setShow(false)}
                show={show}
                onPrimaryAction={() => setShow(false)}
                primaryButtonWidth="100%"
                customContent
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <ProductCardCheckbox
                            hasCheckbox={false}
                            disabled
                            title={item?.productName}
                            contentAlignment="left"
                            image={<img src={item?.pictureUrl} width="64px" alt={item?.productName} />}
                            value={String(item?.id)}
                            size="small"
                            content={isLoading ? (
                                <div className="spinner">
                                    <Loader width="24px" height="24px" className="mx-auto" variant="primary" />
                                </div>
                            ) : (
                                <div className="productDetail">
                                    <ul className="detailList">
                                        <li>
                                            <span className="label">{t("PRODUCT.WARRANTY_END_DATE")}: </span>
                                            <span className="value">{data?.warrantyEndDateText}</span>
                                        </li>
                                        <li>
                                            <span className="label">{t("PRODUCT.SALE_PRICE")}: </span>
                                            <span className="value">{`${data?.purchasePriceText} ${data?.currencyCode}`}</span>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        />
                    </div>
                </SimpleBar>
            </Modal>
        </>
    )
}

export default SalesOfferModal