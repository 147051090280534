import "./styles/App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/login";
import {Toaster} from "krl-alfred";
import Layout from "./Components/Layout";
import { RequireAuth } from "./Container/AuthWrapper";
import { AuthContextProvider } from "./Context/AuthContext";
import { ToggleContextProvider } from "./Context/ToggleContext";
import { initi18n, trLocale } from 'krl-alfred'
import turkishJson from './i18n/locales/turkish.json'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

function App() {

  initi18n({
    lng: 'tr',
    resources: {
      tr: {
        translation: { ...trLocale, ...turkishJson },
      },
    },
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchOnReconnect: false
      },
    },
  })

  return (

      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <ToggleContextProvider>
            <Toaster />
            <Router>
              <Routes>
                <Route path={"/login"} element={<Login />} />
                <Route exact element={<RequireAuth />}>
                  <Route exact path="*" element={<Layout />} />
                </Route>
              </Routes>
            </Router>
          </ToggleContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
  );
}

export default App;
