import {Controller} from "react-hook-form";
import {
    Input,
    useTranslation,
    SelectBox,
    AutocompleteInput,
} from "krl-alfred";
import {useContext, useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {OrderContext} from "../../Context/OrderContex";
import {getProductListWithFilter} from "../../api/routes/category";
import {useSearchParams} from "react-router-dom";
import {getSearchParams} from "../../utils/genericUtils";

const OrderCalendarFilter = ({
                                 control,
                                 errors,
                                 watch,
                                 setError,
                                 clearErrors,
                                 setValue,
                             }) => {
    const {t} = useTranslation();

    const productIdsRef = useRef(null);

    const {orderStatuses} = useContext(OrderContext);

    const [searchParams] = useSearchParams();
    const params = getSearchParams(searchParams);

    useEffect(() => {
        if (params.productIds)
            setValue("productIds", JSON.parse(params.productIds))
    }, []);

    const loadOptions = async (inputValue, callback) => {
        const data = await getProductListWithFilter({
            query: inputValue,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: [],
        }).then(async resp => {
            return resp.data.map(item => ({
                value: item.productId,
                label: item.name,
            }))
        })
        callback(data)
    }

    useEffect(() => {
        if (watch("orderStatuses")) {
            const selectedOrderStatus = orderStatuses.find(
                (status) => status.id === parseInt(watch("orderStatuses"))
            );
            if (selectedOrderStatus) {
                setValue("orderStatuses", {
                    value: selectedOrderStatus?.id,
                    label: selectedOrderStatus?.value,
                });
            }
        }

    }, [orderStatuses]);
    return (
        <>
            <div className="input-controller">
                <Controller
                    name="billingAddressEmail"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.EMAIL")}
                            placeholder={t("FORM.INPUT.EMAIL")}
                            hasError={errors.billingAddressEmail}
                            errorMessage={t("FORM.ERROR.EMAIL")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="orderNumber"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ORDER_NUMBER")}
                            placeholder={t("FORM.INPUT.ORDER_NUMBER")}
                            hasError={errors.orderNumber}
                            errorMessage={t("FORM.ERROR.ORDER_NUMBER")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="startDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.START_DATE")}
                            placeholder={t("FORM.INPUT.START_DATE")}
                            hasError={errors.startDate}
                            errorMessage={t("FORM.ERROR.START_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="endDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.END_DATE")}
                            placeholder={t("FORM.INPUT.END_DATE")}
                            hasError={errors.endDate}
                            errorMessage={t("FORM.ERROR.END_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="orderStatuses"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ORDER_STATUSES")}
                            instanceId="orderStatusesSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.ORDER_STATUSES")}
                            isSearchable={false}
                            lightBg={false}
                            //filterOption={customSelectFilter}
                            onChange={onChange}
                            //onFocus={e => focusSelect(e.target)}
                            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                            hasError={errors.orderStatuses}
                            errorMessage={t("FORM.ERROR.ORDER_STATUSES")}
                            maxOptionListHeight="153px"
                            options={orderStatuses?.map((orderStatus) => ({
                                value: orderStatus.id,
                                label: orderStatus.value,
                            }))}
                            hasShowDropdownIcon
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="productIds"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PRODUCT_IDS")}
                            instanceId="productIdsSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.PRODUCT_IDS")}
                            lightBg={false}
                            onChange={e => {
                                productIdsRef.current.blur()
                                onChange(e)
                            }}
                            hasError={errors?.productIds}
                            errorMessage={t("FORM.ERROR.PRODUCT_IDS")}
                            isAsync
                            isClearable={true}
                            loadOptions={loadOptions}
                            maxOptionListHeight="153px"
                            hasShowDropdownIcon
                            cacheOptions
                            ref={productIdsRef}
                            noOptionsMessage={(e) => {
                                return (['',null].includes(e.inputValue) || e.inputValue.length <= 2) ? t("FORM.INFO.PRODUCT_IDS.ENTER_CHAR") : t("FORM.INFO.PRODUCT_IDS.NO_OPTIONS")
                            }}
                        />
                    )}
                />
            </div>
        </>
    );
};

export default OrderCalendarFilter;
