import {request} from "../request";

export const getCustomerDetail = async(id) => await request({
    url: `console/Customer/id/${id}`,
    method: 'get',
})

export const getCustomerDetailByOperation = async(id) => await request({
    url: `console/Customer/id-by-operation/${id}`,
    method: 'get',
})

export const getCustomerWithDetails = async(params) => await request({
    url: `console/Customer/customer-with-details`,
    method: 'post',
    params
})