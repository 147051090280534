import Header from "./Header"
import Navigation from "./Navigation"
import { OrderContextProvider } from "../../Context/OrderContex"
import { useRoutes } from "react-router-dom"
import Routes from "../../Routes/Routes"
import Custom404 from "../../Pages/404";

const Layout = () => {

  const children = useRoutes(Routes);

  return (
    <OrderContextProvider>
      <div id="siteLayout">
          {children ? (
            <>
                <Header/>
                <div className="innerContent">
                    <div className="layoutLeftSide">
                        <Navigation />
                    </div>
                    <div className="layoutRightSide">
                        {children}
                    </div>
                </div>
            </>
          ) : (
              <Custom404/>
          )}
          {/* !["ORDER_DETAIL"].includes(children?.props?.match?.route?.id) && <GoToUp/> */}
      </div>
    </OrderContextProvider>
  )
}

export default Layout