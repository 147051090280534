import {createContext, useState} from "react";
import {getOrderItemStatusList, getOrderStatusList, getPaymentStatusList} from "../api/routes/common";
import {useQuery} from "@tanstack/react-query";
import useRequest from "../Hooks/useRequest";

export const OrderContext = createContext({
    currentOrderPage: 0,
    orderStatuses: [],
    paymentStatuses: [],
    orderItemStatuses: [],
    cargoProviderTypes: [],
    cargoDirectionTypes: [],
    ProviderTypes: {
        data : []
    },
});

export const OrderContextProvider = ({children}) => {
    const orderStatuses = useQuery({queryKey: ['orderStatuses'], retry:0, gcTime:0, queryFn: getOrderStatusList, initialData: []})
    const paymentStatuses = useQuery({queryKey: ['paymentStatuses'], retry:0, gcTime:0, queryFn: getPaymentStatusList, initialData: []})
    const orderItemStatuses = useQuery({queryKey: ['orderItemStatuses'], retry:0, gcTime:0, queryFn: getOrderItemStatusList, initialData: []})

    const cargoProviderTypes = [
        { id: 1, value: "BoltCargo",label: "Bolt Kargo" },
        { id: 2, value: "YurticiCargo",label: "Yurtiçi Kargo" },
        { id: 3, value: "HepsiJetCargo",label: "Hepsi Jet Kargo" },
    ]

    const cargoDirectionTypes = [
        { id: 1, value: "Outgoing",label: "Giden Kargo" },
        { id: 2, value: "Incoming",label: "Gelen Kargo" },
    ]

    const [currentOrderPage, setCurrentOrderPage] = useState(0);

    const handleCurrentOrderPage = (val) => setCurrentOrderPage(val);

    const ProviderTypes = {
        data: [
            {id: 0, value: "Kiralarsın", label: "Kiralarsın"},
            {id: 1, value: "Pazarama", label: "Pazarama"},
            {id: 2, value: "Byqee", label: "Byqee"},
            {id: 3, value: "Alya", label: "Alya"},
            {id: 4, value: "Arçelik", label: "Arçelik"},
            {id: 5, value: "Vestel", label: "Vestel"},
            {id: 6, value: "Monsternotebook", label: "Monsternotebook"},
            {id: 7, value: "Remington", label: "Remington"},
            {id: 8, value: "RussellHobbs", label: "RussellHobbs"},
        ]
    };

    return (
        <OrderContext.Provider
            value={{
                orderStatuses: orderStatuses.data,
                paymentStatuses: paymentStatuses.data,
                orderItemStatuses: orderItemStatuses.data,
                currentOrderPage,
                cargoProviderTypes,
                setCurrentOrderPage: handleCurrentOrderPage,
                cargoDirectionTypes,
                ProviderTypes
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
