import Filters from "./Filters";
import List from "./List";
import {useForm} from "react-hook-form";
import {useQuery} from "@tanstack/react-query";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "krl-alfred";
import {getSearchParams, setUrlWithString} from "../../../utils/genericUtils";
import {getOrders, getOrdersByOperation} from "../../../api/routes/order";
import {getOrderShipment} from "../../../api/routes/shipment";
import {useEffect, useState} from "react";

const Orders = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [params, setParams] = useState(getSearchParams(searchParams));

    useEffect(() => {
        setParams(getSearchParams(searchParams));
    }, [searchParams])

    const defaultPageSize = params?.defaultPageSize || 15;

    const {
        control,
        formState: {errors},
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm({
        defaultValues: params,
    });

    const submit = async (data) => {
        data.index = 1;
        data.productIds = data?.productIds?.length > 0 ? JSON.stringify(data.productIds) : null
        let str = setUrlWithString(data);
        navigate(`${str}`);
    };

    const handleExport = async () => {
        toast({
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Burası yapılmadı şimdilik :)",
        });
    };

    const handleClear = async () => {
        //navigate(`?`);
        reset({
            billingAddressFirstName: "",
            billingAddressLastName: "",
            billingAddressEmail: "",
            orderNumber: "",
            startDate: "",
            endDate: "",
            minStartDate: "",
            maxStartDate: "",
            minEndDate: "",
            maxEndDate: "",
            serialNumber: "",
            orderStatuses: "",
            paymentStatuses: "",
            orderTypes: "",
            productIds: "",
        });
    };

    const {data, isFetching, refetch} = useQuery({
        queryKey: [props?.type || "orderList", {...params}],
        retry: 0,
        keepPreviousData: true,
        queryFn: async () => {
            let requestData = params;
            let filteredData = {};
            let requestLink = getOrders;

            for (const [key, value] of Object.entries(requestData)) {
                if (typeof value === "object") {
                    filteredData[key] = [value.value];
                } else {
                    if (![undefined, null, ""].includes(value)) {
                        if (key === "orderTypes") {
                            filteredData[value] = true;
                        } else {
                            filteredData[key] = value;
                        }
                    }
                }
            }

            if (["operationOrders"].includes(props?.type)) {
                requestLink = getOrdersByOperation
            } else if (["todayReturnedOrders"].includes(props?.type)) {
                requestLink = getOrderShipment
                filteredData.cargoDirectionType = "Incoming"
            } else if (["todayShipmentOrders"].includes(props?.type)) {
                requestLink = getOrderShipment
                filteredData.cargoDirectionType = "Outgoing"
            } else if (["saveCardOrders"].includes(props?.type)) {
                filteredData.isSaveCardOrder = true
            } else if (["awaitingOrders"].includes(props?.type)) {
                if(!filteredData.orderStatuses)
                    filteredData.orderStatuses = 2
            }

            return await requestLink({
                size: defaultPageSize,
                index: params?.index || 1,
                ...filteredData
            });
        }
    });

    return (
        <div id="ordersPage">
            <Filters
                handleClear={handleClear}
                onFilter={handleSubmit(submit)}
                onExport={handleExport}
                control={control}
                errors={errors}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                isLoading={isFetching}
                type={props?.type}
            />
            <List
                hasLoading={isFetching}
                data={data}
                type={props?.type}
                refetch={refetch}
                defaultPageSize={defaultPageSize}
            />
        </div>
    );
};

export default Orders;
