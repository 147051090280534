import { Badges, Modal, useTranslation } from 'krl-alfred'
import classNames from 'classnames'
import SimpleBar from 'simplebar-react'

const PaymentHistoryModal = props => {
  const { show, setShow, data, product, isPaymentFailed } = props
  const { t } = useTranslation()
  
  const handlePrimaryActions = () => {
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onClose={() => setShow(false)}
      onPrimaryAction={handlePrimaryActions}
      size="small"
      showBackIcon={false}
      id="paymentHistoryModal"
      showPrimaryButton
      primaryButtonText={isPaymentFailed ? t('BUTTON.GO_TO_PAYMENT_INFO') : t('BUTTON.OK')}
      title={t('MY_ACCOUNT.SUBSCRIPTION.PAYMENT_HISTORY')}
      showHeaderBorder
      showFooterBorder
      titleAlignment="left"
      customContent
      autoHeight>
      <SimpleBar autoHide={false}>
        <ul className="list">
          {data.map((item, i) => (
            <li key={i} className={classNames('listItem', { disabled: item.status !== 3 })}>
              <div className="date">{item.subscriptionDate}</div>
              <div className="priceWrapper">
                {item.subscriptionStatusText && !['Onay bekliyor'].includes(item.subscriptionStatusText) && (
                  <span className="status">
                    <Badges title={item.subscriptionStatusText} layout="text" variant={['İptal edildi', 'Başarısız ödeme'].includes(item.subscriptionStatusText) ? 'danger' : 'secondary'} />
                  </span>
                )}
                <span className="price">{['Onay bekliyor'].includes(item?.subscriptionStatusText) ? item?.subscriptionStatusText : `${item?.monthlySubscriptionAmountText} ${product?.currencyCode}`}</span>
              </div>
            </li>
          ))}
        </ul>
      </SimpleBar>
    </Modal>
  )
}

export default PaymentHistoryModal
