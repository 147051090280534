import ProductList from "./ProductList";
import ShipmentDetail from "../ReturnShipmentForm/ShipmentDetail";

function SendShipmentForm({
  onSubmit,
  control,
  errors,
  isLoading,
  submitRef,
  activeStep,
  productList,
  dirtyFields,
  watch,
  setValue,
  setIsLoading,
  clearErrors
}) {

  const validProductIds = [];
  watch("productIds")?.map((item,key) => item && validProductIds.push(key) )

  return (
    <form onSubmit={onSubmit}>
      {activeStep === 1 && (
        <ProductList
          data={productList}
          control={control}
          errors={errors}
          activeStep={activeStep}
          watch={watch}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )}
      {activeStep === 2 && (
        <ShipmentDetail
          control={control}
          errors={errors}
          productList={productList.filter(item => validProductIds.includes(item.id))}
          dirtyFields={dirtyFields}
          watch={watch}
          setValue={setValue}
          setIsLoading={setIsLoading}
        />
      )}
      {activeStep === 3 && (
        <ProductList
          data={productList.filter(item => validProductIds.includes(item.id))}
          control={control}
          errors={errors}
          activeStep={activeStep}
          watch={watch}
        />
      )}
      <div className="d-none">
        <button type="submit" ref={submitRef} />
      </div>
    </form>
  );
}

export default SendShipmentForm;
