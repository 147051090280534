import { Input, useTranslation } from "krl-alfred";
import { Controller } from "react-hook-form";

function BoxDetailForm({ onSubmit, control, errors, isLoading, submitRef, data }) {
  const { t } = useTranslation();
  
  return (
    <form onSubmit={onSubmit}>
        {data?.id && (
            <div className="input-controller">
                <Controller
                    name="id"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                    <Input
                        onChange={onChange}
                        value={value}
                        name={name}
                        label={t("FORM.LABEL.ID")}
                        placeholder={t("FORM.INPUT.ID")}
                        hasError={errors.id}
                        errorMessage={t("FORM.ERROR.ID")}
                        disabled
                        readonly
                    />
                    )}
                />
            </div>
        )}
        <div className="input-controller">
            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                <Input
                    onChange={onChange}
                    value={value}
                    name={name}
                    label={t("FORM.LABEL.BOX_NAME")}
                    placeholder={t("FORM.INPUT.BOX_NAME")}
                    hasError={errors.name}
                    errorMessage={t("FORM.ERROR.BOX_NAME")}
                />
                )}
                rules={{ required: true }}
            />
        </div>
        <div className="input-controller">
            <Controller
                name="code"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                <Input
                    onChange={onChange}
                    value={value}
                    name={name}
                    label={t("FORM.LABEL.BOX_CODE")}
                    placeholder={t("FORM.INPUT.BOX_CODE")}
                    hasError={errors.code}
                    errorMessage={t("FORM.ERROR.BOX_CODE")}
                />
                )}
                rules={{ required: true }}
            />
        </div>
        <div className="input-controller">
            <Controller
                name="width"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                <Input
                    onChange={onChange}
                    value={value}
                    name={name}
                    type="number"
                    label={t("FORM.LABEL.WIDTH")}
                    placeholder={t("FORM.INPUT.WIDTH")}
                    hasError={errors.width}
                    errorMessage={t("FORM.ERROR.WIDTH")}
                />
                )}
                rules={{ required: true, validate: val => parseInt(val) > 0 }}
            />
        </div>
        <div className="input-controller">
            <Controller
                name="size"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                <Input
                    onChange={onChange}
                    value={value}
                    name={name}
                    type="number"
                    label={t("FORM.LABEL.SIZE")}
                    placeholder={t("FORM.INPUT.SIZE")}
                    hasError={errors.size}
                    errorMessage={t("FORM.ERROR.SIZE")}
                />
                )}
                rules={{ required: true, validate: val => parseInt(val) > 0 }}
            />
        </div>
        <div className="input-controller">
            <Controller
                name="height"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                <Input
                    onChange={onChange}
                    value={value}
                    name={name}
                    type="number"
                    label={t("FORM.LABEL.HEIGHT")}
                    placeholder={t("FORM.INPUT.HEIGHT")}
                    hasError={errors.height}
                    errorMessage={t("FORM.ERROR.HEIGHT")}
                />
                )}
                rules={{ required: true, validate: val => parseInt(val) > 0 }}
            />
        </div>

      <div className="d-none">
        <button type="submit" ref={submitRef} />
      </div>
    </form>
  );
}

export default BoxDetailForm;