import ErrorPage from './../Components/Errors/ErrorPage'
import { useTranslation } from 'krl-alfred'
const Custom404 = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage
      title={t('INFO.404_TITLE')}
      description={<span>{t('INFO.404_DESC')}</span>}
      img={
        <div className="img404">
          <img src="/404.svg" alt={t('INFO.404_TITLE')} width={456} height={282} />
        </div>
      }
    />
  )
}

Custom404.displayName = 'Custom404'

export default Custom404
