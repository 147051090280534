import { Button, useTranslation } from "krl-alfred"

const FileActionCard = ({
    onPrimaryAction,
    onSecondaryAction,
    title,
    primaryText,
    secondaryText,
    isShowSecondaryButton,
    isShowPrimaryButton
}) => {
    const {t} = useTranslation()
    return (
        <div className="fileActionCard">
            <div className="leftFrame">
                <strong className="title">{title}</strong>
            </div>
            <div className="rightFrame">
                {isShowSecondaryButton && <Button onClick={onSecondaryAction} size="xsmall" variant="inverted" icon="Download">{secondaryText}</Button>}
                {isShowPrimaryButton && <Button onClick={onPrimaryAction} size="xsmall" icon="Upload">{primaryText}</Button>}
            </div>
        </div>
    )
}

export default FileActionCard