import {request} from "../request";

export const getShipmentBox = async (params) => await request({
    url: 'console/Box/get-shipment-box',
    method: 'post',
    data: params
})

export const getBoxList = async (params) => await request({
    url: 'console/Box/filter',
    method: 'post',
    data: params
})

export const deleteBox = async (params) => await request({
    url: `console/Box/box/id/${params?.id}/user-id/${params?.userId}`,
    method: 'delete'
})

export const getBoxProductListToBox = async (params) => await request({
    url: `console/Box/get-box-products/${params?.boxId}`,
    method: 'get',
    params
})

export const addBox = async (data) => await request({
    url: 'console/Box/box',
    method: 'post',
    data
})

export const updateBox = async (data) => await request({
    url: 'console/Box/box',
    method: 'put',
    data
})

export const addAndRemoveProductToBox = async (data) => await request({
    url: 'console/Box/product-box',
    method: 'put',
    data
})
