import { useEffect } from 'react'

export default function useOnClickOutside(ref, handler, status = true) {
  useEffect(() => {
    if (status) {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target) || event.target.ariaHidden === "true") {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, handler])
}
