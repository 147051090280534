import { Controller } from "react-hook-form";
import {
  Input,
  useTranslation,
} from "krl-alfred";

const TodayShipmentFilter = ({
  control,
  errors
}) => {
  const { t } = useTranslation();
  
  return (
    <>
    <div className="input-controller">
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Input
            onChange={onChange}
            value={value}
            name={name}
            type="date"
            label={t("FORM.LABEL.MIN_START_DATE")}
            placeholder={t("FORM.INPUT.MIN_START_DATE")}
            hasError={errors.startDate}
            errorMessage={t("FORM.ERROR.MIN_START_DATE")}
            format="yyyy-MM-dd"
            mask={null}
            isClearable
          />
        )}
      />
    </div>
    </>
  );
};

export default TodayShipmentFilter;
