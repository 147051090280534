import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {Loader} from "krl-alfred";
import CustomerSummary from "./CustomerSummary";
import { getCustomerDetail } from "../../../api/routes/customer";

function CustomerDetail() {

    const { customerId } = useParams();
    
    const {data,isFetching} = useQuery({ queryKey: ['customerDetail',customerId], retry:0, queryFn: () => getCustomerDetail(customerId) });
    
    return (
        <div className="customerDetail">
            {(isFetching) ? (
                <div className="loadingPage">
                    <Loader width="32px" height="32px" variant="primary"/>
                </div>
            ) : (
                <>
                    <CustomerSummary data={data}/>
                </>
            )}
        </div>
    )
}

export default CustomerDetail;