import { useTranslation, Modal, toast } from "krl-alfred"
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import UpdateOrderForm from "../Forms/UpdateOrderForm";
import { OrderContext } from "../Context/OrderContex";
import { updateOrderStatus, updatePaymentStatus } from "../api/routes/order";
import ConfirmationModal from "./ConfirmationModal";

const UpdateOrderModal = (props) => {
    const {
        show,
        setShow,
        data,
        refetch
    } = props

    const { t } = useTranslation()
    const { orderStatuses, paymentStatuses } = useContext(OrderContext);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submitRef = useRef(null)

    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm()
    
    const submit = async (values) => {
        if(!showConfirmationModal){
            setShowConfirmationModal(true)
            setShow(false)
            return
        }
        setIsLoading(true)
        
        await updateOrderStatus({
            "orderId": data?.id,
            "orderStatus": values?.orderStatus?.value
        }).then(async resp => {
            await updatePaymentStatus({
                "orderId": data?.id,
                "paymentStatus": values?.paymentStatus?.value
            }).then(resp => {
                toast({
                    hasClosing: true,
                    title: t("INFO.UPDATED_ORDER_INFO")
                })
                setShowConfirmationModal(false)
            })
        })

        setIsLoading(false)
        refetch()
    }

    useEffect(() => {
        if(show){
            const selectedOrderStatus = orderStatuses?.find(status => status?.id === data?.orderStatus)
            const selectedPaymentStatus = paymentStatuses?.find(status => status?.id === data?.paymentStatus)
            if(selectedOrderStatus){
                setValue('orderStatus', {
                    value: selectedOrderStatus?.id,
                    label: selectedOrderStatus?.value,
                })
            }
            
            if(selectedPaymentStatus){
                setValue('paymentStatus', {
                    value: selectedPaymentStatus?.id,
                    label: selectedPaymentStatus?.value,
                })
            }
        }
    },[show])

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showSecondaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={t("INFO.UPDATE_ORDER_INFO")}
                primaryButtonText={t('BUTTON.UPDATE')}
                secondaryButtonText={t('BUTTON.GIVE_UP')}
                id="updateOrderModal"
                onClose={() => setShow(false)}
                onSecondaryAction={() => setShow(false)}
                show={show}
                onPrimaryAction={() => submitRef?.current?.click()}
                isPrimaryButtonLoading={isLoading}
                isPrimaryButtonDisabled={Object.keys(dirtyFields).length <= 0}
                customContent
                primaryButtonWidth="50%"
                secondaryButtonWidth="50%"
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <UpdateOrderForm
                            onSubmit={handleSubmit(submit)}
                            control={control}
                            errors={errors}
                            isLoading={isLoading}
                            submitRef={submitRef}
                        />
                    </div>
                </SimpleBar>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                contentTitle={t("INFO.ARE_YOU_SURE_UPDATE_ORDER_INFO")}
                onPrimaryAction={handleSubmit(submit)}
                isPrimaryButtonLoading={isLoading}
                primaryButtonText={t('BUTTON.APPROVE')}
            />
        </>
    )
}

export default UpdateOrderModal