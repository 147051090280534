
export const setUrlWithString = (data) => {
  let str = "?"
  Object.keys(data).forEach(function(key, index) {
    if(data[key]){
      if(typeof data[key] === "object"){
        data[key] = data[key].value
      }
      str += `${key}=${data[key]}&`
    }
  });
  return str
}

export const emailIsValid = email =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

export const focusSelect = (elem,block="start") => {
  setTimeout(() => {
    if (elem) {
      elem.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: block })
    }
  }, 50)
}

export const getSearchParams = (params) => {
  const refactorParams = {}
  params.forEach((value, key) => {
    refactorParams[key] = value;
  });
  return refactorParams
}

export const customSelectFilter = (option, searchText) => {
  const label = option.data.label
  const value = option.data.value

  const replaceVal = val => {
    return val
        .replace(/Ğ/gim, 'g')
        .replace(/Ü/gim, 'u')
        .replace(/Ş/gim, 's')
        .replace(/I/gim, 'i')
        .replace(/İ/gim, 'i')
        .replace(/Ö/gim, 'o')
        .replace(/Ç/gim, 'c')
        .replace(/ğ/gim, 'g')
        .replace(/ü/gim, 'u')
        .replace(/ş/gim, 's')
        .replace(/ı/gim, 'i')
        .replace(/ö/gim, 'o')
        .replace(/ç/gim, 'c')
        .replace(/i̇/gim, 'i')
  }

  return (
      replaceVal(label.toLocaleLowerCase()).includes(replaceVal(searchText.toLocaleLowerCase())) || replaceVal(String(value).toLocaleLowerCase()).includes(replaceVal(searchText.toLocaleLowerCase()))
  )
}