import { ProductCardCheckbox } from "krl-alfred"
import { Controller } from "react-hook-form"

const ProductList = ({
        control,
        data,
        errors
    }) => {
        
    return (
        <div className="productList">
            {
                data?.map((item, index) => (
                    <Controller
                        control={control}
                        name={`productIds[${item.id}]`}
                        defaultValue={false}
                        render={({ field: { onChange, value, name } }) => (
                            <div className="productCard">
                                <ProductCardCheckbox
                                    title={item.productName}
                                    name={name}
                                    contentAlignment="left"
                                    image={<img src={item.pictureUrl} width="64px" alt={item.productName} />}
                                    value={String(item.id)}
                                    productChecked={value}
                                    checkboxId={`productCard_${item.id}`}
                                    checked={value}
                                    content={null}
                                    size="small"
                                    productCardOnChange={onChange}
                                    valid={errors?.productIds ? !errors?.productIds[item?.id] : true}
                                />
                            </div>
                        )}
                        rules={{ required: false }}
                    />
                ))
            }
        </div>
    )
}

export default ProductList