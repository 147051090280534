import {Input, ProductCardCheckbox, useTranslation} from "krl-alfred";
import {formatDate} from "../../utils/date";
import {Controller} from "react-hook-form";
import {useEffect, useRef} from "react";

const ProductCard = ({
                         activeStep,
                         control,
                         item,
                         watch,
                         errors,
                         index,
                         setValue,
                         clearErrors
                     }) => {
    const { t } = useTranslation();
    const isToday = item?.cargoShipmentTimeText === "Bugün";
    const productCardRef = useRef(null)

    useEffect(() => {
        if(activeStep === 1 && watch(`productIds[${item?.id}]`)){
            setTimeout(() => productCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)
            setValue(`serialNumber[${item.id}]`,item.serialNumber);
        }
    },[watch(`productIds[${item?.id}]`)])

    return (
        <Controller
            control={control}
            name={`productIds[${item?.id}]`}
            defaultValue={false}
            render={({ field: { onChange, value, name } }) => (
                <div className="productCard" ref={productCardRef}>
                    <ProductCardCheckbox
                        hasCheckbox={isToday && activeStep !==3 }
                        disabled={!isToday || activeStep ===3 }
                        title={item?.productName}
                        hasBadge
                        //badgeText={
                        //  isToday
                        //    ? t("ORDER.CARGO.TODAY")
                        //    : t("ORDER.CARGO.FUTURE_DATED")
                        //}
                        badgeText={item?.cargoShipmentTimeText}
                        badgeVariant={isToday ? "secondary" : "disabled"}
                        name={name}
                        contentAlignment="left"
                        image={
                            <img
                                src={item?.pictureUrl}
                                width="64px"
                                alt={item?.productName}
                            />
                        }
                        value={String(item?.id)}
                        productChecked={value}
                        checkboxId={`productCard_${item?.id}`}
                        checked={value}
                        size="small"
                        productCardOnChange={e => {
                            setValue(`serialNumber[${item?.id}]`,null)
                            onChange(e)
                        }}
                        valid={
                            errors?.productIds ? !errors?.productIds[item?.id] : true
                        }
                        content={
                            (
                                <ul className="summaryList">
                                    <li>
                                        <span>{t("PRODUCT.START_END_DATE_LONG")}: </span>
                                        <strong className="date">{`${formatDate(
                                            item?.startDate,
                                            false,
                                            "DD.MM.YYYY",
                                            false
                                        )} - ${formatDate(
                                            item?.endDate,
                                            false,
                                            "DD.MM.YYYY",
                                            false
                                        )}`}</strong>
                                    </li>
                                    {
                                        value && activeStep !== 3 && (
                                            <li>
                                                <div className="input-container">
                                                    <Controller
                                                        name={`serialNumber[${item?.id}]`}
                                                        control={control}
                                                        render={({field: {onChange, value, name}}) => (
                                                            <Input
                                                                onChange={onChange}
                                                                value={value}
                                                                name={name}
                                                                width="100%"
                                                                type="text"
                                                                label={t("FORM.LABEL.SERIAL_NUMBER")}
                                                                placeholder={t("FORM.INPUT.SERIAL_NUMBER")}
                                                                hasError={errors?.serialNumber && errors?.serialNumber[item?.id]}
                                                                errorMessage={(errors?.serialNumber && errors?.serialNumber[item?.id]?.message) ? errors?.serialNumber[item?.id]?.message : t("FORM.ERROR.SERIAL_NUMBER")}
                                                                isClearable
                                                                onClear={() => {
                                                                    clearErrors(`serialNumber`);
                                                                }}
                                                            />
                                                        )}
                                                        rules={{required: true}}
                                                    />
                                                </div>
                                            </li>
                                        )
                                    }
                                    {activeStep !== 1 && (
                                        <>
                                            <li>
                                                <span>{t("PRODUCT.SERIAL_NUMBER")}: </span>
                                                <strong>{watch("serialNumber")[item?.id]}</strong>
                                            </li>
                                            <li>
                                                <span>{t("PRODUCT.CARGO_TYPE")}: </span>
                                                <strong>{watch("providerType")?.label}</strong>
                                            </li>
                                            <li>
                                                <span>{t("PRODUCT.BOX")}: </span>
                                                <strong>{watch("boxIds")[item?.productId]?.label || watch("boxIds")[watch("boxIds").length - (index + 1)]?.label}</strong>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            )
                        }
                    />
                </div>
            )}
            rules={{required: false}}
        />
    )
}

export default ProductCard