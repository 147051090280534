import {useTranslation, Input} from "krl-alfred";
import { Controller } from "react-hook-form";

function UpdateProductInfoForm({ onSubmit, control, errors, isLoading, submitRef }) {
    const { t } = useTranslation();

    return (
        <form onSubmit={onSubmit}>
            <div className="input-controller">
                <Controller
                    name="productId"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PRODUCT_ID")}
                            placeholder={t("FORM.INPUT.PRODUCT_ID")}
                            hasError={errors.productId}
                            errorMessage={t("FORM.ERROR.PRODUCT_ID")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="name"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PRODUCT_NAME")}
                            placeholder={t("FORM.INPUT.PRODUCT_NAME")}
                            hasError={errors.name}
                            errorMessage={t("FORM.ERROR.PRODUCT_NAME")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="erpCode"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ERP_CODE")}
                            placeholder={t("FORM.INPUT.ERP_CODE")}
                            hasError={errors.erpCode}
                            errorMessage={t("FORM.ERROR.ERP_CODE")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="slug"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.SLUG")}
                            placeholder={t("FORM.INPUT.SLUG")}
                            hasError={errors.slug}
                            errorMessage={t("FORM.ERROR.SLUG")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="modelNumber"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.MODEL_NUMBER")}
                            placeholder={t("FORM.INPUT.MODEL_NUMBER")}
                            hasError={errors.modelNumber}
                            errorMessage={t("FORM.ERROR.MODEL_NUMBER")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="metaTitle"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.META_TITLE")}
                            placeholder={t("FORM.INPUT.META_TITLE")}
                            hasError={errors.metaTitle}
                            errorMessage={t("FORM.ERROR.META_TITLE")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="metaDescription"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.META_DESCRIPTION")}
                            placeholder={t("FORM.INPUT.META_DESCRIPTION")}
                            hasError={errors.metaDescription}
                            errorMessage={t("FORM.ERROR.META_DESCRIPTION")}
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="title"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.TITLE")}
                            placeholder={t("FORM.INPUT.TITLE")}
                            hasError={errors.title}
                            errorMessage={t("FORM.ERROR.TITLE")}
                        />
                    )}
                />
            </div>
            <div className="d-none">
                <button type="submit" ref={submitRef}/>
            </div>
        </form>
    );
}

export default UpdateProductInfoForm;