import { useTranslation, SelectBox } from "krl-alfred";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { OrderContext } from "../../Context/OrderContex";
import { getShipmentBox, getBoxList } from "./../../api/routes/box";
import { focusSelect } from "../../utils/genericUtils";

const ShipmentDetail = ({
  control,
  errors,
  watch,
  productList,
  setValue,
  setIsLoading,
}) => {
  const [boxResponseList, setBoxResponseList] = useState([]);
  const [allBoxList, setAllBoxList] = useState([]);

  const { t } = useTranslation();
  const { cargoProviderTypes } = useContext(OrderContext);

  const getShipmentBoxList = async () => {
    const validProductIds = [];
    watch("productIds")?.map((item, key) => item && validProductIds.push(key));
    const filteredProducts = productList?.filter((item) =>
      validProductIds.includes(item.id)
    );
    setIsLoading((prev) => ({ ...prev, isSubmitLoading: true }));
    await getShipmentBox({
      quantity: watch("quantity")?.value,
      productIds: filteredProducts?.map((item) => item.productId),
    })
      .then((res) => {
        const boxes = Array?.from(Array(watch("quantity")?.value)?.keys());

        if (res?.sameProductQuantity) {
          for (
            let i = 0;
            i < res?.getShipmentProductBoxResponses?.length;
            i++
          ) {
            boxes[i] = res?.getShipmentProductBoxResponses[i];
          }
        }

        setBoxResponseList(boxes);
      })
      .catch((err) => {
        //console.log(err)
      });
    setIsLoading((prev) => ({ ...prev, isSubmitLoading: false }));
  };

  useEffect(() => {
    if (watch("quantity")?.value > 0) {
      getShipmentBoxList();
    }
  }, [watch("quantity")]);

  useEffect(() => {
    setBoxResponseList([]);
    getBoxList({
      Index: 1,
      Size: 1000,
    })
      .then((res) => {
        setAllBoxList(res?.data);
      })
      .catch((err) => {
        //console.log(err)
      });
  }, []);

  useEffect(() => {
    boxResponseList?.map((box, index) => {
      const defaultBox = allBoxList.find((boxes) => box.boxId === boxes.id);
      if (defaultBox?.id) {
        const defaultValue = {
          value: defaultBox.id,
          label: defaultBox.name,
        };
        setValue(`boxIds[${box?.productId || index + 999999}]`, defaultValue);
      }
    });
  }, [boxResponseList]);

  return (
    <div className="shipmentDetail">
      <Controller
        name="providerType"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <SelectBox
            value={value}
            name={name}
            label={t("FORM.LABEL.PROVIDER_TYPE")}
            instanceId="orderStatusesSelect"
            width="100%"
            placeholder={t("FORM.INPUT.PROVIDER_TYPE")}
            isSearchable={false}
            lightBg={false}
            //filterOption={customSelectFilter}
            onChange={onChange}
            //onFocus={e => focusSelect(e.target)}
            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
            hasError={errors.providerType}
            errorMessage={t("FORM.ERROR.PROVIDER_TYPE")}
            maxOptionListHeight="153px"
            options={cargoProviderTypes?.map((type) => ({
              value: type.value,
              label: type.label,
            }))}
            hasShowDropdownIcon
            menuPosition="static"
          />
        )}
        rules={{ required: true }}
      />
      <Controller
        name="quantity"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <SelectBox
            value={value}
            name={name}
            label={t("FORM.LABEL.QUANTITY")}
            instanceId="orderStatusesSelect"
            width="100%"
            placeholder={t("FORM.INPUT.QUANTITY")}
            isSearchable={false}
            lightBg={false}
            //filterOption={customSelectFilter}
            onChange={onChange}
            onFocus={e => focusSelect(e.target)}
            onMenuClose={() => document.activeElement.blur()}
            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
            hasError={errors.quantity}
            errorMessage={t("FORM.ERROR.QUANTITY")}
            maxOptionListHeight="153px"
            options={[...Array(100)].map((_, i) => ({
              value: i + 1,
              label: i + 1,
            }))}
            hasShowDropdownIcon
            menuPosition="static"
          />
        )}
        rules={{ required: true }}
      />
      {boxResponseList?.map((box, index) => {
        return (
          <Controller
            key={`boxIds[${box?.productId || index + 999999}]`}
            name={`boxIds[${box?.productId || index + 999999}]`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <SelectBox
                value={value}
                name={name}
                label={t("FORM.LABEL.BOX", {
                  boxName: `(${box?.productName || index})`,
                })}
                instanceId="orderStatusesSelect"
                width="100%"
                placeholder={t("FORM.INPUT.BOX")}
                isSearchable={false}
                lightBg={false}
                //filterOption={customSelectFilter}
                onChange={onChange}
                onFocus={e => focusSelect(e.target,"center")}
                onMenuClose={() => document.activeElement.blur()}
                noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                hasError={errors.boxIds}
                errorMessage={t("FORM.ERROR.BOX")}
                maxOptionListHeight="153px"
                options={allBoxList.map((item, i) => ({
                  value: item.id,
                  label: item.name,
                }))}
                hasShowDropdownIcon
                menuPosition="static"
              />
            )}
            rules={{ required: true }}
          />
        );
      })}
    </div>
  );
};

export default ShipmentDetail;
