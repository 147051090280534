import { Controller } from "react-hook-form";
import {
  Input,
  useTranslation
} from "krl-alfred";

const CargoBoxFilter = ({
  control,
  errors
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="input-controller">
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.BOX_NAME")}
              placeholder={t("FORM.INPUT.BOX_NAME")}
              hasError={errors.name}
              errorMessage={t("FORM.ERROR.BOX_NAME")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.BOX_CODE")}
              placeholder={t("FORM.INPUT.BOX_CODE")}
              hasError={errors.code}
              errorMessage={t("FORM.ERROR.BOX_CODE")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="id"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.ID")}
              placeholder={t("FORM.INPUT.ID")}
              hasError={errors.id}
              errorMessage={t("FORM.ERROR.ID")}
              isClearable
            />
          )}
        />
      </div>
    </>
  );
};

export default CargoBoxFilter;
