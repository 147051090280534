import ProductList from "./ProductList"
import ShipmentDetail from "./ShipmentDetail"

const ReturnShipmentForm = ({
    data,
    control,
    errors,
    step,
    submitRef,
    onSubmit,
    dirtyFields,
    watch,
    setValue,
    setIsLoading
}) => {
    return (
        <form onSubmit={onSubmit}>
            {step === 1 ? (
                <ProductList
                    data={data}
                    control={control}
                    errors={errors}
                />
            ) : (
                <ShipmentDetail
                    control={control}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    watch={watch}
                    productList={data}
                    setValue={setValue}
                    setIsLoading={setIsLoading}
                />
            )}
            <div className="d-none">
              <button type="submit" ref={submitRef} />
            </div>
        </form>
    )
}

export default ReturnShipmentForm