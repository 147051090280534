import {Modal, toast, useTranslation} from "krl-alfred";
import { useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import SimpleBar from "simplebar-react";
import UpdateProductInfoForm from "../../Forms/UpdateProduct/UpdateProductInfoForm";

const UpdateProductInfoModal = (props) => {
    const {
        show,
        setShow,
        productDetail
    } = props

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);

    const submitRef = useRef(null)

    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm()

    const submit = async (values) => {
        setIsLoading(true)

        toast({
            timer: 5000,
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Çalışır gibi oldu ama burası da çalışmaz çünkü buralar hiç yapılmadı, yapısı da hazır değil. hazır olunca yapılcak :)",
        });

        setIsLoading(false)
        setShow(false)
    }

    useEffect(() => {
        if(show){
            reset({
                productId: productDetail?.productId,
                name: productDetail?.name,
                erpCode: productDetail?.erpCode,
                slug: productDetail?.slug,
                modelNumber: productDetail?.modelNumber,
                metaTitle: productDetail?.metaTitle,
                metaDescription: productDetail?.metaDescription,
                title: productDetail?.title,
            });
        }
    },[show])

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showSecondaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={t("INFO.UPDATE_PRODUCT_INFO")}
                primaryButtonText={t('BUTTON.SAVE')}
                secondaryButtonText={t('BUTTON.GIVE_UP')}
                id="updateProductInfoModal"
                onClose={() => setShow(false)}
                onSecondaryAction={() => setShow(false)}
                show={show}
                onPrimaryAction={() => submitRef?.current?.click()}
                isPrimaryButtonLoading={isLoading}
                isPrimaryButtonDisabled={Object.keys(dirtyFields).length <= 0}
                customContent
                primaryButtonWidth="50%"
                secondaryButtonWidth="50%"
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <UpdateProductInfoForm
                            onSubmit={handleSubmit(submit)}
                            control={control}
                            errors={errors}
                            isLoading={isLoading}
                            submitRef={submitRef}
                        />
                    </div>
                </SimpleBar>
            </Modal>
        </>
    )
}

export default UpdateProductInfoModal