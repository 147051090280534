import {Controller} from "react-hook-form";
import {Input, useTranslation, AutocompleteInput, SelectBox} from "krl-alfred";
import {useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getProductListWithFilter} from "../api/routes/category";

const AddProductToBoxForm = ({control, errors, onSubmit, submitRef}) => {
    const {t} = useTranslation();

    const productIdsRef = useRef(null);

    const loadOptions = async (inputValue, callback) => {
        const data = await getProductListWithFilter({
            query: inputValue,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: [],
        }).then(async resp => {
            return resp.data.map(item => ({
                value: item.productId,
                label: item.name,
            }))
        })
        callback(data)
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="input-controller">
                <Controller
                    name="boxId"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.BOX_ID")}
                            placeholder={t("FORM.INPUT.BOX_ID")}
                            hasError={errors.boxId}
                            errorMessage={t("FORM.ERROR.BOX_ID")}
                            readonly
                            disabled
                        />
                    )}
                    rules={{required: true}}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="productIds"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PRODUCT_IDS")}
                            instanceId="productIdsSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.PRODUCT_IDS")}
                            lightBg={false}
                            onChange={e => {
                                productIdsRef.current.blur()
                                onChange(e)
                            }}
                            hasError={errors?.productIds}
                            errorMessage={t("FORM.ERROR.PRODUCT_IDS")}
                            isAsync
                            isClearable={true}
                            loadOptions={loadOptions}
                            maxOptionListHeight="153px"
                            hasShowDropdownIcon
                            cacheOptions
                            ref={productIdsRef}
                            noOptionsMessage={(e) => {
                                return (['',null].includes(e.inputValue) || e.inputValue.length <= 2) ? t("FORM.INFO.PRODUCT_IDS.ENTER_CHAR") : t("FORM.INFO.PRODUCT_IDS.NO_OPTIONS")
                            }}
                        />
                    )}
                />
            </div>
            <div className="d-none">
                <button type="submit" ref={submitRef}/>
            </div>
        </form>
    );
};

export default AddProductToBoxForm;
