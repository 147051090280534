import React from 'react'
import CircleSummaryBox from '../../Components/Summary/CircleSummaryBox'

function Home() {
  return (
    <div id="home">
        Insights en kısa zamanda burada olacak!
        {/*
        <div className="summaryLayout">
        <CircleSummaryBox/>
        <CircleSummaryBox/>
        <CircleSummaryBox/>
      </div>
        */}
    </div>
  )
}

export default Home
