import {DescriptionCard, toast, useTranslation} from "krl-alfred";
import React from "react";

const Variants = ({productDetail}) => {
    const {t} = useTranslation();

    const setList = (values) => {
        return [
            {
                key: "VARIANT_ID",
                title: t("PRODUCT.VARIANT_ID"),
                display: values?.variantId,
            },
            {
                key: "PRODUCT_ID",
                title: t("PRODUCT.PRODUCT_ID"),
                display: values?.productId,
            },
            {
                key: "BARCODE",
                title: t("PRODUCT.BARCODE"),
                display: values?.barcode,
            },
            {
                key: "SKU",
                title: t("PRODUCT.SKU"),
                display: values?.sku,
            },
            {
                key: "STOCK_QUANTITY",
                title: t("PRODUCT.STOCK_QUANTITY"),
                display: values?.salableQuantity,
            },
            {
                key: "DISPLAY_ORDER",
                title: t("PRODUCT.DISPLAY_ORDER"),
                display: values?.displayOrder,
            },
            {
                key: "BUFFER_DAY",
                title: t("PRODUCT.BUFFER_DAY"),
                display: values?.bufferDay,
            }
        ]
    }

    const handleUpdate = async () => {
        toast({
            timer: 4000,
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Çalışmaz çünkü buralar hiç yapılmadı, yapısı da hazır değil. hazır olunca yapılcak :)",
        });
    }

    return (
        <div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.VARIANTS")}</h1>
                {productDetail.variants?.map(variant => {
                    return (
                        <DescriptionCard
                            list={setList(variant)}
                            title={productDetail?.name}
                            hasHeaderButton
                            headerButtonText={t("BUTTON.EDIT")}
                            headerButtonWidth="82px"
                            headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                            boxSize={4}
                            headerButtonAction={handleUpdate}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Variants