import { useState } from "react";
import { DescriptionCard, useTranslation } from "krl-alfred";
import BoxDetailModal from "../../../Modals/BoxDetailModal";
import AddProductToBoxModal from "../../../Modals/AddProductToBoxModal";

const BoxSummary = ({ data }) => {
    
  const { t } = useTranslation();
  const [isShowEditBoxModal,setIsShowEditBoxModal] = useState(false)
  const [isShowAddProductToBoxModal,setIsShowAddProductToBoxModal] = useState(false)
  
  const list = [
    {
      source: "id",
      title: t("CARGO_BOXES.ID"),
      display: data?.id,
    },
    {
      source: "firstName",
      title: t("CARGO_BOXES.BOX_NAME"),
      display: data?.name,
    },
    {
      source: "code",
      title: t("CARGO_BOXES.BOX_CODE"),
      display: data?.code,
    },
    {
      source: "width",
      title: t("CARGO_BOXES.WIDTH"),
      display: `${data?.width} cm`,
    },
    {
      source: "size",
      title: t("CARGO_BOXES.SIZE"),
      display: `${data?.size} cm`,
    },
    {
      source: "height",
      title: t("CARGO_BOXES.HEIGHT"),
      display: `${data?.height} cm`,
    },
    {
      source: "desi",
      title: t("CARGO_BOXES.DESI"),
      display: data?.desi,
    },
  ];

  const dropdownList = [
      {
          name: t("BUTTON.UPDATE_BOX"),
          component: ({children}) => <a href="javascript:void(0);" onClick={handleUpdateBox}>{children}</a>,
      },
      {
          name: t("BUTTON.ADD_PRODUCT_TO_BOX"),
          component: ({children}) => <a href="javascript:void(0);" onClick={handleAddProductToBox}>{children}</a>,
      }
  ];

  const handleUpdateBox = () => {
    setIsShowEditBoxModal(true)
  }

  const handleAddProductToBox = () => {
    setIsShowAddProductToBoxModal(true)
  }

  return (
    <div className="boxSummary">
      <DescriptionCard 
        list={list} 
        title={t("CARGO_BOXES.BOX_DETAIL")} 
        hasHeaderButton
        hasHeaderDropdown
        dropdownWidth="170px"
        headerButtonWidth="104px"
        dropdownList={dropdownList}
        headerButtonText={t("BUTTON.EDIT")}
      />
      <BoxDetailModal
        show={isShowEditBoxModal}
        setShow={setIsShowEditBoxModal}
        data={data}
      />
      <AddProductToBoxModal
        show={isShowAddProductToBoxModal}
        setShow={setIsShowAddProductToBoxModal}
        box={data}
      />
    </div>
  );
};

export default BoxSummary;
