import FilterDropdown from "../../Listing/FilterDropdown";
import OrderFilter from "../../../Forms/Filters/OrderFilter";
import TodayShipmentFilter from "../../../Forms/Filters/TodayShipmentFilter";
import TodayReturnedFilter from "../../../Forms/Filters/TodayReturnedFilter";
import {useEffect} from "react";
import {getSearchParams} from "../../../utils/genericUtils";
import {useSearchParams} from "react-router-dom";

const Filters = ({
                     handleClear,
                     onFilter,
                     control,
                     errors,
                     watch,
                     setError,
                     clearErrors,
                     setValue,
                     isLoading,
                     onExport,
                     type
                 }) => {

    const [searchParams] = useSearchParams();
    const params = getSearchParams(searchParams);

    useEffect(() => {
        if (params.productIds)
            setValue("productIds", JSON.parse(params.productIds))
    }, []);

    return (
        <div>
            <FilterDropdown
                isLoading={isLoading}
                handleClear={handleClear}
                onFilter={onFilter}
                onExport={onExport}
                hasExport
            >
                {type === "todayShipmentOrders" ? (
                    <TodayShipmentFilter
                        control={control}
                        errors={errors}
                        watch={watch}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        type={type}
                    />
                ) : type === "todayReturnedOrders" ? (
                    <TodayReturnedFilter
                        control={control}
                        errors={errors}
                        watch={watch}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        type={type}
                    />
                ) : (
                    <OrderFilter
                        control={control}
                        errors={errors}
                        watch={watch}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        type={type}
                    />
                )}
            </FilterDropdown>
        </div>
    );
};

export default Filters;
