import FilterDropdown from "../../Listing/FilterDropdown";
import ProductFilter from "../../../Forms/Filters/ProductFilter";

const Filters = ({
  handleClear,
  onFilter,
  control,
  errors,
  watch,
  setError,
  clearErrors,
  setValue,
  isLoading,
  onExport,
  type
}) => {
  return (
    <div>
      <FilterDropdown
        isLoading={isLoading}
        handleClear={handleClear}
        onFilter={onFilter}
        onExport={onExport}
        hasExport
      >
        <ProductFilter
          control={control}
          errors={errors}
          watch={watch}
          setError={setError}
          clearErrors={clearErrors}
          setValue={setValue}
          type={type}
        />
      </FilterDropdown>
    </div>
  );
};

export default Filters;
