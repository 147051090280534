import { Form, Input, DatePicker, Button, Switch } from 'antd'
import React from 'react'
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinCreatedDate: value.MinCreatedDate ? formatDate(new Date(value.MinCreatedDate),false,"") : undefined,
      MaxCreatedDate: value.MaxCreatedDate ? formatDate(new Date(value.MaxCreatedDate),false,"") : undefined,
    })
  }
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item label="Min. Başlangıç Tarihi" name="MinCreatedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Max. Başlangıç Tarihi" name="MaxCreatedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
