import { createContext, useState } from "react";
import { setAutHeader } from "../Service";
import { login as authLogin } from "../api/routes/auth";
import { redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { request } from "../api/request";
import {apiService} from "./../Service/index"

export const AuthContext = createContext({
  login: async (email, password, rememberme = false) => Promise,
  logout: () => {},
  user: {},
  isLoggedIn: false,
});

export const AuthContextProvider = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoggedIn, setIsLoggedIn] = useState(!!user?.id);
  var cookies = new Cookies();

  const login = async (email, password, rememberme = false) => {
    return await authLogin({ email, password })
      .then(async (res) => {
        window.localStorage.setItem("user", JSON.stringify(res));
        cookies.set("accessToken", res?.token?.accessToken, {
          path: "/",
          maxAge: res?.token?.expiresIn,
        });
        request.interceptors.request.use((config) => {
          config.headers.Authorization = res?.token?.accessToken;
          return config;
        });
        apiService.interceptors.request.use((config) => {
          config.headers.Authorization = res?.token?.accessToken;
          return config;
        });
        setIsLoggedIn(true);
        return Promise.resolve(res);
      })
      .catch((err) => Promise.reject(err));
  };
  const logout = () => {
    setAutHeader(false);
    window.localStorage.removeItem("user");
    cookies.remove("accessToken");
    setIsLoggedIn(false);
    redirect("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
