import { Button, useTranslation, toast } from "krl-alfred";
import React, { useState } from "react";
import Listing from "../../Listing";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { addAndRemoveProductToBox } from "../../../api/routes/box";

const ProductList = (props) => {
  const { t } = useTranslation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (record) => {
    
    if(record){
      setSelectedProduct(record)
    }

    if(!showConfirmationModal){
      setShowConfirmationModal(true)
      return
    }
    setIsLoading(true);
    await addAndRemoveProductToBox({boxId:0,productId:selectedProduct?.productId}).then((resp) => {
      toast({
          hasClosing: true,
          title: t("INFO.DELETED_SUCCESSFULL")
      })
    }).catch(err => {
      toast({
          hasClosing: true,
          icon: 'Danger',
          variant: 'warning',
          title: err?.response?.data?.message
      })
    })
    .finally((final) => {
      setIsLoading(false);
      setShowConfirmationModal(false)
      props?.refetch()
    });
  };

  const columns = [
    {
      key: "medias",
      title: t("PRODUCT.PRODUCT_IMAGE"),
      dataIndex: "medias",
      render: (medias) => <img className="imgFluid" width={50} src={medias?.length > 0 ? medias[0]?.url : "/logo.svg"} />,
    },
    {
      key: "productId",
      title: t("PRODUCT.PRODUCT_ID"),
      dataIndex: "productId",
    },
    {
      key: "name",
      title: t("PRODUCT.PRODUCT_NAME"),
      dataIndex: "name",
    },
    {
      key: "actions",
      title: null,
      fixed: "right",
      width: 100,
      render: (record) =>
      (
        <div className="actions">
          <Button
            onClick={() => handleDelete(record)}
            width="32px"
            variant="primary"
            tooltip={t("BUTTON.DELETE")}
            size="xsmall"
            icon="DeleteSmall"
            isLoading={isLoading}
          />
        </div>
      )
    },
  ];

  return (
    <div className="cargoBoxProductList">
      <Listing
        hasLoading={props.hasLoading}
        columns={columns}
        data={{data:props.data}}
        title={t("INFO.PRODUCTLIST_ON_BOX")}
        hasPagination={false}
      />
      <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          contentTitle={t("INFO.ARE_YOU_SURE_DELETE_PRODUCT_ON_BOX",{productName: props?.data[0]?.name, boxName: props?.boxDetail?.name})}
          onPrimaryAction={() => handleDelete()}
          isPrimaryButtonLoading={isLoading}
          primaryButtonText={t('BUTTON.I_APPROVE')}
      />
    </div>
  );
};

export default ProductList;
