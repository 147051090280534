import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {Loader} from "krl-alfred";
import BoxSummary from "./BoxSummary";
import { getBoxList, getBoxProductListToBox } from "../../../api/routes/box";
import ProductList from "./ProductList";

function CargoBoxDetail() {

    const { id } = useParams();
    
    const {data,isFetching} = useQuery({ queryKey: ['boxDetail',id], retry:0, queryFn: () => getBoxList({Index: 1, Size: 10,id}) });
    const {data:productListData,isFetching:productListIsFetching,refetch:productListRefetch} = useQuery({ queryKey: ['productListToBox',id], retry:0, queryFn: () => getBoxProductListToBox({boxId:id}) });
    
    return (
        <div id="cargoBoxDetailPage">
            {(isFetching || productListIsFetching) ? (
                <div className="loadingPage">
                    <Loader width="32px" height="32px" variant="primary"/>
                </div>
            ) : (
                <>
                    <BoxSummary data={data?.data[0]}/>
                    <ProductList data={productListData} boxDetail={data?.data[0]} refetch={productListRefetch}/>
                </>
            )}
        </div>
    )
}

export default CargoBoxDetail;