/*import OrdersPage from "./../../Components/Pages/Orders"

const TodayReturned = () => <OrdersPage type="todayReturnedOrders"/>

export default TodayReturned;*/

import ReturnedsTable from "../../Tables/Returneds";


const TodayReturned = () => {

    return (
        <div>
            <ReturnedsTable />
        </div>
    )
}

export default TodayReturned

