import { request } from "../request";

export const updateItemsSerialNumber = async (data) => await request({
    url: '/console/OrderItem/update-items-serial-number',
    method: 'post',
    data
})

export const updateOrderItemStatus = async (data) => await request({
    url: '/console/OrderItem/update-order-item-status',
    method: 'post',
    data
})

export const updateStartOrEndDate = async (data) => await request({
    url: '/console/OrderItem/update-start-or-end-date',
    method: 'post',
    data
})