import {useTranslation, Textarea} from "krl-alfred";
import { Controller } from "react-hook-form";

function UpdateProductDescriptionForm({ onSubmit, control, errors, isLoading, submitRef }) {
    const { t } = useTranslation();

    return (
        <form onSubmit={onSubmit}>
            <div className="input-controller">
                <Controller
                    name="shortDescription"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Textarea
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.SHORT_DESCRIPTION")}
                            placeholder={t("FORM.INPUT.SHORT_DESCRIPTION")}
                            hasError={errors.shortDescription}
                            errorMessage={t("FORM.ERROR.SHORT_DESCRIPTION")}
                            width="100%"
                            height="145px"
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="fullDescription"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Textarea
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.FULL_DESCRIPTION")}
                            placeholder={t("FORM.INPUT.FULL_DESCRIPTION")}
                            hasError={errors.fullDescription}
                            errorMessage={t("FORM.ERROR.FULL_DESCRIPTION")}
                            width="100%"
                            height="145px"
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="boxDescription"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Textarea
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.BOX_DESCRIPTION")}
                            placeholder={t("FORM.INPUT.BOX_DESCRIPTION")}
                            hasError={errors.boxDescription}
                            errorMessage={t("FORM.ERROR.BOX_DESCRIPTION")}
                            width="100%"
                            height="145px"
                        />
                    )}
                />
            </div>
            <div className="d-none">
                <button type="submit" ref={submitRef}/>
            </div>
        </form>
    );
}

export default UpdateProductDescriptionForm;