import {Form, Input, DatePicker, Button, Row, Col, Select} from 'antd'
import React, {useContext} from 'react'
import { formatDate } from "../../utils/date";
import moment from "moment/moment";
import {OrderContext} from "../../Context/OrderContex";
const { Option } = Select;

const InsuranceStatuses = {
  data: [
    { id: 0, value: true,label: "Var" },
    { id: 1, value: false,label: "Yok" },
  ]
};

function Filters({ onSubmit,initialValues }) {

  const { ProviderTypes } = useContext(OrderContext);

  const onFinish = (value) => {

    onSubmit({
      ...value,
      StartDate: value.StartDate ? formatDate(new Date(value.StartDate),false,"") : undefined,
      EndDate: value.EndDate ? formatDate(new Date(value.EndDate),false,"") : undefined,
      OrderStartCreatedDate: value.OrderStartCreatedDate ? formatDate(new Date(value.OrderStartCreatedDate),false,"") : undefined,
      OrderEndCreatedDate: value.OrderEndCreatedDate ? formatDate(new Date(value.OrderEndCreatedDate),false,"") : undefined,
    })
  }

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
      id="periodicSalesReportFilterForm"
      className="filters"
    >
      <Row>
        <Col md={12}>
          <Form.Item label="Ürün Adı" name="ProductName">
            <Input />
          </Form.Item>
          <Form.Item label="Kiralama Başlangıç tarihi" name="StartDate">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Kiralama Bitiş tarihi" name="EndDate">
            <DatePicker />
          </Form.Item>

          <Form.Item label="Tamamlarsın var mı?" name="IsInsuranceOrder">
            <Select allowClear style={{ width: "100%" }} placeholder="Please select">
              {InsuranceStatuses.data.map((status) => (
                  <Option key={status.id} value={status.value}>{status.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Seri Numarası" name="ProductSerialNumber">
            <Input />
          </Form.Item>
          <Form.Item label="Sipariş Başlangıç tarihi" name="OrderStartCreatedDate">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Sipariş Bitiş tarihi" name="OrderEndCreatedDate">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Ürün Kaynağı" name="providerType">
            <Select allowClear style={{ width: "100%" }} placeholder="Please select">
              {ProviderTypes.data.map((status) => (
                  <Option key={status.id} value={status.value}>{status.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  )
}


export default Filters
