import {formatDate} from "../../../utils/date";
import React, {useContext, useState} from "react";
import {DescriptionCard, toast, useTranslation} from "krl-alfred";
import {OrderContext} from "../../../Context/OrderContex";
import UpdateOrderModal from "../../../Modals/UpdateOrderModal";
import { startInsuranceSend } from "../../../api/routes/order";
import SendShipmentModal from "../../../Modals/SendShipmentModal";

const OrderSummary = ({data,refetch,type}) => {

    const {t} = useTranslation();
    const {orderStatuses, paymentStatuses} = useContext(OrderContext);
    const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);
    const [showSendShipmentModal, setShowSendShipmentModal] = useState(false);

    let list = [
        {
            key:"ORDER_ID",
            title: t("ORDER.ORDER_ID"),
            display: data?.id,
        },
        {
            key:"CREATED_AT",
            title: t("LIST.CREATED_AT"),
            display: formatDate(data?.createdAt,true,"DD.MM.YYYY HH:mm:ss",false),
        },
        {
            key:"TOTAL_PRICE",
            title: t("ORDER.TOTAL_PRICE"),
            display: `${data?.totalText} ${data?.currencyCode}`,
        },
        {
            key:"ORDER_NUMBER",
            title: t("ORDER.ORDER_NUMBER"),
            display: data?.orderNumber,
        },
        {
            key:"ORDER_STATUS",
            title: t("ORDER.ORDER_STATUS"),
            value: data?.orderStatus,
            display: orderStatuses?.find((status) => status.id === data?.orderStatus)?.value,
        },
        {
            key:"TOTAL_ORDER_COUNT",
            title: t("ORDER.TOTAL_ORDER_COUNT"),
            display: data?.totalOrderCount,
        },
        {
            key:"PAYMENT_STATUS",
            title: t("ORDER.PAYMENT_STATUS"),
            value: data?.paymentStatus,
            display: paymentStatuses?.find((status) => status.id === data?.paymentStatus)?.value,
        },
        {
            key:"DISCOUNT",
            title: t("ORDER.DISCOUNT"),
            display: data?.couponCode ? t("INFO.APPLIED") : t("INFO.NOT_IMPLEMENTED"),
        },
        {
            key:"DISCOUNT_CODE",
            title: t("ORDER.DISCOUNT_CODE"),
            display: data?.couponCode ?? "-",
        },
        {
            key:"DISCOUNT_PRICE",
            title: t("ORDER.DISCOUNT_PRICE"),
            display: data?.totalCodeDiscount > 0 ? `${data?.totalCodeDiscountText} ${data?.currencyCode}` : "-",
        },
        {
            key:"TOTAL_DISCOUNT_WITHOUT_CODE",
            title: t("ORDER.TOTAL_DISCOUNT_WITHOUT_CODE"),
            display: data?.totalDiscountWithoutCode > 0 ? data?.totalDiscountWithoutCode : "-",
        },
        {
            key:"INSTALMENT_COUNT",
            title: t("ORDER.INSTALMENT_COUNT"),
            display: data?.transactions && data?.transactions.length > 0 && data?.transactions[0] && data?.transactions[0]?.installmentCount > 1 ? t('ORDER.INSTALLMENT', { count: data?.transactions[0]?.installmentCount }) : t("ORDER.SINGLE_PAYMENT"),
        },
        {
            key:"TRANSACTION",
            title: t("ORDER.TRANSACTION"),
            display: data?.transactions && data?.transactions.length > 0 && data?.transactions[0] && data?.transactions[0]?.gatewayName,
        },
    ];

    if(["operationOrders","todayShipmentOrders","todayReturnedOrders"].includes(type)){
      list = list.filter(column => !["TOTAL_PRICE","TOTAL_ORDER_COUNT","DISCOUNT","DISCOUNT_CODE","DISCOUNT_PRICE","TOTAL_DISCOUNT_WITHOUT_CODE","INSTALMENT_COUNT","TRANSACTION"].includes(column.key))
    }

    const isPurchasedOrder = data?.items?.filter(item => item?.isOrderItemPurchased)?.length > 0
    const isSubscriptionOrder = data?.items?.filter(item => item?.isSubscriptionOrder)?.length > 0

    const dropdownList = [
        {
            name: t("BUTTON.UPDATE_ORDER_INFO"),
            component: ({children}) => <a href="javascript:void(0);" onClick={() => setShowUpdateOrderModal(true)}>{children}</a>,
        },
        {
            name: t("BUTTON.START_INSURANCE"),
            component: ({children}) => <a href="javascript:void(0);" onClick={handleStartInsurance}>{children}</a>,
        }
    ];

    const handleStartInsurance = async () => {
        //data?.orderNumber
        toast({
            hasClosing: false,
            hasLoading: true,
        })
        await startInsuranceSend({orderNumber:data?.orderNumber}).then(() => {
            toast({
                hasClosing: true,
                timer: 2000,
                title: t("INFO.ASSURANCE_SUCCESSFULLY")
            })
        }).catch(err => {
            toast({
                hasClosing: true,
                icon: 'Danger',
                variant: 'warning',
                title: err?.response?.data?.message
            })
        })
    }

    return (
        <div className="orderSummary">

            <SendShipmentModal
                orderDetail={data}
                show={showSendShipmentModal}
                setShow={setShowSendShipmentModal}
                refetch={refetch}
            />
            <DescriptionCard
                list={list}
                title={t("ORDER.ORDER_DETAIL")}
                hasBadge
                hasHeaderButton
                hasHeaderDropdown
                headerButtonText={t("BUTTON.EDIT")}
                dropdownWidth="167px"
                headerButtonWidth="104px"
                dropdownList={dropdownList}
                hasHeaderPrimaryButton={["operationOrders","todayShipmentOrders","todayReturnedOrders"].includes(type)}
                headerPrimaryButtonText={t("BUTTON.SEND")}
                headerPrimaryButtonAction={() => setShowSendShipmentModal(true)}
                badgeText={isPurchasedOrder ? t("ORDER.PURCHASED") : isSubscriptionOrder ? t("ORDER.SUBSCRIPTION") : t("ORDER.RENTAL")}
            />
            <UpdateOrderModal
                show={showUpdateOrderModal}
                setShow={setShowUpdateOrderModal}
                data={data}
                refetch={refetch}
            />
        </div>
    )
}

export default OrderSummary