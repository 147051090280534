import { Form, Input, DatePicker, Button, Row, Col } from "antd";
import React from "react";
import { formatDate } from "../../utils/date"; 

function Filters({ onSubmit,initialValues }) {
  const onFinish = (value) => {
    onSubmit({
      ...value,
      MinStartDate: value.MinStartDate ? formatDate(new Date(value.MinStartDate),false,"YYYY-MM-DD",false) : undefined,
      MaxStartDate: value.MaxStartDate ? formatDate(new Date(value.MaxStartDate),false,"YYYY-MM-DD",false) : undefined,
      MinEndDate: value.MinEndDate ? formatDate(new Date(value.MinEndDate),false,"YYYY-MM-DD",false) : undefined,
      MaxEndDate: value.MaxEndDate ? formatDate(new Date(value.MaxEndDate),false,"YYYY-MM-DD",false) : undefined,
      OrderStartCreatedDate: value.OrderStartCreatedDate ? formatDate(new Date(value.OrderStartCreatedDate),false,"YYYY-MM-DD",false) : undefined,
      OrderEndCreatedDate: value.OrderEndCreatedDate ? formatDate(new Date(value.OrderEndCreatedDate),false,"YYYY-MM-DD",false) : undefined,
    });
  };
  return (
    <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValues={initialValues} className="filters" layout="horizontal" onFinish={onFinish}>
      <Row>
        <Col md={8}>
          <Form.Item label="Min. Kiralama Başlangıç" name="MinStartDate">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item label="Max. Kiralama Başlangıç" name="MaxStartDate">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item label="Sipariş Başlangıç tarihi" name="OrderStartCreatedDate">
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Item label="Min. Kiralama Bitiş" name="MinEndDate">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item label="Max. Kiralama Bitiş" name="MaxEndDate">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item label="Sipariş Bitiş tarihi" name="OrderEndCreatedDate">
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Filters;
