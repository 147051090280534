import { useTranslation, Modal, toast } from "krl-alfred"
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import { OrderContext } from "../Context/OrderContex";
import ConfirmationModal from "./ConfirmationModal";
import UpdateOrderProductForm from "../Forms/UpdateOrderProductForm";
import { formatDate } from "../utils/date";
import moment from "moment";
import { updateItemsSerialNumber, updateOrderItemStatus, updateStartOrEndDate } from "../api/routes/orderItem";

const UpdateOrderProductModal = (props) => {
    const {
        show,
        setShow,
        data,
        refetch,
        order
    } = props

    const { t } = useTranslation()
    const { orderStatuses } = useContext(OrderContext);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submitRef = useRef(null)

    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm()
    
    const submit = async (values) => {
        let isError = false;

        if(!showConfirmationModal){
            setShowConfirmationModal(true)
            setShow(false)
            return
        }

        setIsLoading(true)
        
        if(dirtyFields.status){
            await updateOrderItemStatus({
                "orderItemId": data?.id,
                "status": values?.status?.value
            }).catch(err => {
                toast({
                    hasClosing: true,
                    icon: 'Danger',
                    variant: 'warning',
                    title: err?.response?.data?.message
                })
                isError = true
            })
        }

        if(dirtyFields.serialNumber){
            await updateItemsSerialNumber({
                "orderNumber": order?.orderNumber,
                "itemSerialNumbers": [
                    {
                      "id": data?.id,
                      "sku": data?.sku,
                      "serialNumber": values.serialNumber
                    }
                  ]
            }).catch(err => {
                toast({
                    hasClosing: true,
                    icon: 'Danger',
                    variant: 'warning',
                    title: err?.response?.data?.errors[0]
                })
                isError = true
            })
        }
        
        if(dirtyFields.startDate || dirtyFields.endDate){
            await updateStartOrEndDate({
                "id":data?.id,
                "startDate": values?.startDate,
                "endDate": values?.endDate
            }).catch(err => {
                toast({
                    hasClosing: true,
                    icon: 'Danger',
                    variant: 'warning',
                    title: err?.response?.data?.message
                })
                isError = true
            })
        }

        if(!isError){
            toast({
                hasClosing: true,
                title: t("INFO.UPDATED_ORDER_PRODUCT_INFO")
            })
        }

        setShowConfirmationModal(false)
        setIsLoading(false)
        refetch()
    }

    useEffect(() => {
        
        if(show){
            const formattedStartDate = formatDate(data?.startDate,false,"YYYY-MM-DD");
            const formattedEndDate = formatDate(data?.endDate,false,"YYYY-MM-DD");

            const selectedOrderStatus = orderStatuses?.find(status => status?.id === data?.status)
            if(selectedOrderStatus){
                setValue('status', {
                    value: selectedOrderStatus?.id,
                    label: selectedOrderStatus?.value,
                })
            }
            
            setValue('startDate', moment(data?.startDate).year() > 2000 ? formattedStartDate : null)
            setValue('endDate', moment(data?.startDate).year() > 2000 ? formattedEndDate : null)

            setValue('serialNumber', data?.serialNumber)
        }
    },[show])

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showSecondaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={t("INFO.UPDATE_ORDER_PRODUCT_INFO")}
                primaryButtonText={t('BUTTON.UPDATE')}
                secondaryButtonText={t('BUTTON.GIVE_UP')} 
                id="updateOrderProductModal"
                onClose={() => setShow(false)}
                onSecondaryAction={() => setShow(false)}
                show={show}
                onPrimaryAction={() => submitRef?.current?.click()}
                isPrimaryButtonLoading={isLoading}
                isPrimaryButtonDisabled={Object.keys(dirtyFields).length <= 0}
                customContent
                primaryButtonWidth="50%"
                secondaryButtonWidth="50%"
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <UpdateOrderProductForm
                            onSubmit={handleSubmit(submit)}
                            control={control}
                            errors={errors}
                            isLoading={isLoading}
                            submitRef={submitRef}
                        />
                    </div>
                </SimpleBar>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                contentTitle={t("INFO.ARE_YOU_SURE_UPDATE_ORDER_PRODUCT_INFO")}
                onPrimaryAction={handleSubmit(submit)}
                isPrimaryButtonLoading={isLoading}
                primaryButtonText={t('BUTTON.APPROVE')}
            />
        </>
    )
}

export default UpdateOrderProductModal