import { Controller } from "react-hook-form";
import { Input, useTranslation } from "krl-alfred";

const CustomerFilter = ({
  control,
  errors,
  watch,
  setError,
  clearErrors,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="input-controller">
        <Controller
          name="id"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.ID")}
              placeholder={t("FORM.INPUT.ID")}
              hasError={errors.id}
              errorMessage={t("FORM.ERROR.ID")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.FIRST_NAME")}
              placeholder={t("FORM.INPUT.FIRST_NAME")}
              hasError={errors.firstName}
              errorMessage={t("FORM.ERROR.FIRST_NAME")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.LAST_NAME")}
              placeholder={t("FORM.INPUT.LAST_NAME")}
              hasError={errors.lastName}
              errorMessage={t("FORM.ERROR.LAST_NAME")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              label={t("FORM.LABEL.EMAIL")}
              placeholder={t("FORM.INPUT.EMAIL")}
              hasError={errors.email}
              errorMessage={t("FORM.ERROR.EMAIL")}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              type="date"
              label={t("FORM.LABEL.START_DATE")}
              placeholder={t("FORM.INPUT.START_DATE")}
              hasError={errors.startDate}
              errorMessage={t("FORM.ERROR.START_DATE")}
              format="yyyy-MM-dd"
              mask={null}
              isClearable
            />
          )}
        />
      </div>
      <div className="input-controller">
        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              type="date"
              label={t("FORM.LABEL.END_DATE")}
              placeholder={t("FORM.INPUT.END_DATE")}
              hasError={errors.endDate}
              errorMessage={t("FORM.ERROR.END_DATE")}
              format="yyyy-MM-dd"
              mask={null}
              isClearable
            />
          )}
        />
      </div>
    </>
  );
};

export default CustomerFilter;
