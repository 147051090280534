import {useTranslation, Modal, ProductCardCheckbox, Loader} from "krl-alfred"
import SimpleBar from "simplebar-react";
import { formatDate } from "../utils/date";

const OrderDetailModal = (props) => {
    const {
        show,
        setShow,
        data,
        isLoading,
    } = props

    const { t } = useTranslation()

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={t("ORDER.ORDER_SUMMARY")}
                primaryButtonText={t('BUTTON.GO_TO_ORDER')}
                id="orderDetailModal"
                onClose={() => setShow(false)}
                show={show}
                onPrimaryAction={() => window.open(`/operations-management/orders/${data?.orderNumber}`, '_blank')}
                customContent
                primaryButtonWidth="100%"
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        {isLoading ? (
                            <div className="modalLoading">
                                <Loader width="24px" height="24px" variant="primary"/>
                            </div>
                        ) : (
                            <>
                                <div className="orderSummary">
                                    <strong className="orderNumber">{data?.orderNumber}</strong>
                                </div>
                                <div className="customerDetail">
                                    <ul className="detailList">
                                        <li>
                                            <span className="label">{t("CUSTOMER.FIRST_AND_LAST_NAME")}: </span>
                                            <span
                                                className="value">{`${data?.customer?.firstName} ${data?.customer?.lastName}`}</span>
                                        </li>
                                        <li>
                                            <span
                                                className="label">{t("CUSTOMER.SHIPMENT_FIRST_AND_LAST_NAME")}: </span>
                                            <span
                                                className="value">{`${data?.shippingAddresses[0]?.firstName} ${data?.shippingAddresses[0]?.lastName}`}</span>
                                        </li>
                                        <li>
                                            <span className="label">{t("ORDER.ORDER_DATE")}: </span>
                                            <span
                                                className="value">{formatDate(data?.createdAt, false, "DD.MM.YYYY")}</span>
                                        </li>
                                        <li>
                                            <span className="label">{t("ORDER.ORDER_STATUS")}: </span>
                                            <span className="value">{data?.orderStatusText}</span>
                                        </li>
                                        <li>
                                            <span className="label">{t("CUSTOMER.CUSTOMER_ID")}: </span>
                                            <span className="value">{data?.customerId}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="productList">
                                    {data?.items?.map(item => (
                                        <ProductCardCheckbox
                                            hasCheckbox={false}
                                            disabled
                                            title={item?.productName}
                                            contentAlignment="left"
                                            image={<img src={item?.pictureUrl} width="64px" alt={item?.productName}/>}
                                            size="small"
                                            content={(
                                                <div className="productDetail">
                                                    <ul className="detailList">
                                                        <li>
                                                            <span className="label">{t("PRODUCT.ITEM_ID")}: </span>
                                                            <span className="value">{item?.id}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </SimpleBar>
            </Modal>
        </>
    )
}

export default OrderDetailModal