import Customers from "../Pages/customers";
import Products from "../Pages/products";
import ProductDetail from "../Pages/products/Id";
import InvoiceAndWaybill from "../Pages/invoice-and-waybill";
import Insights from "../Pages/insights";
import OrderDetail from "../Pages/orders/id"
import OrderDetailByOperation from "../Pages/ordersForOperation/id"
import OrderDetailBySaveCardOrder from "../Pages/saveCardOrders/id"
import OrderDetailByAwaitingOrders from "../Pages/awaiting-orders/id"
import OrderDetailByTodayShipmentOrders from "../Pages/today-shipment/id"
import OrderDetailByTodayReturnedOrders from "../Pages/today-returned/id"
import CustomersDetail from "../Pages/customers/id"
import PeriodicSalesReport from "../Pages/periodic-sales-report"
import CustomerOrderReport from "../Pages/customer-order-report"
import OrderStatisticReport from "../Pages/order-statistic-report"
import SuggestedProductReport from "../Pages/suggested-product-report"
import OrderInsuranceReport from "../Pages/order-insurance-report"
import ProductClickReport from "../Pages/product-click-report"
import DemandedProductReport from "../Pages/demanded-product-report"
import ProductSalesReport from "../Pages/product-sales-report"
import CurrentRentalReport from "../Pages/current-rental-report";
import CurrentRentalReportDetail from "../Pages/current-rental-report/Id";
import PurchaseOfferEvaluationReport from "../Pages/get-purchase-offer-evaluation-report";
import PurchasedPriceReport from "../Pages/purchased-price-report";
import VariantSerialNumberDetail from "../Pages/variant-serial-number/Id";
import VariantSerialNumber from "../Pages/variant-serial-number";
import SaveCardOrders from "../Pages/saveCardOrders";
import OrderCalendar from "../Pages/OrderCalendar";
import Orders from "../Pages/orders";
import TodayShipment from "../Pages/today-shipment";
import TodayReturned from "../Pages/today-returned";
import OrdersForOperation from "../Pages/ordersForOperation";
import AwaitingOrders from "../Pages/awaiting-orders";
import CargoBoxes from "../Pages/cargo-boxes";
import CargoBoxDetail from "../Pages/cargo-boxes/id";
import AddProduct from "../Pages/add-product";

const today = new Date();

const Routes = [
  {
    path: "/",
    element: <Insights />,
    id: "INSIGHTS",
    icon: "Chart",
  },
  {
    id: "ORDER_MANAGEMENT",
    icon: "Cargo2"
  },
  {
    path: "/orders-management/orders",
    element: <Orders />,
    id: "ORDERS",
    parent: "ORDER_MANAGEMENT",
  },
  {
    path: "/orders-management/orders/:orderNumber",
    element: <OrderDetail />,
    id: "ORDER_DETAIL",
    type: "detail",
    parent: "ORDER_MANAGEMENT",
    isActiveMenu: "ORDERS",
  },
  {
    path: "/orders-management/awaiting-orders",
    //targetLink: "/orders-management/awaiting-orders?orderStatuses=2&",
    element: <AwaitingOrders />,
    id: "AWAITING_ORDERS",
    parent: "ORDER_MANAGEMENT",
  },
  {
    path: "/orders-management/awaiting-orders/:orderNumber",
    element: <OrderDetailByAwaitingOrders />,
    id: "AWAITING_ORDER_DETAIL",
    type: "detail",
    parent: "ORDER_MANAGEMENT",
    isActiveMenu: "AWAITING_ORDERS",
  },
  {
    path: "/orders-management/save-card-orders",
    element: <SaveCardOrders />,
    id: "SAVE_CARD_ORDERS",
    parent: "ORDER_MANAGEMENT",
  },
  {
    path: "/orders-management/save-card-orders/:orderNumber",
    element: <OrderDetailBySaveCardOrder />,
    id: "SAVE_CARD_ORDER_DETAIL",
    type: "detail",
    parent: "ORDER_MANAGEMENT",
    isActiveMenu: "SAVE_CARD_ORDERS",
  },
  {
    id: "OPERATIONS_MANAGEMENT",
    icon: "Cargo",
  },
  {
    path: "/operations-management/orders",
    element: <OrdersForOperation/>,
    id: "OPERATION_ORDERS",
    parent: "OPERATIONS_MANAGEMENT"
  },
  {
    path: "/operations-management/orders/:orderNumber",
    element: <OrderDetailByOperation />,
    id: "OPERATION_ORDER_DETAIL",
    type: "detail",
    parent: "OPERATIONS_MANAGEMENT",
    isActiveMenu: "OPERATION_ORDERS",
  },
  {
    path: "/operations-management/order-calendar",
    element: <OrderCalendar />,
    id: "ORDER_CALENDAR",
    parent: "OPERATIONS_MANAGEMENT",
  },
  {
    path: `/operations-management/today-shipment`,
    //targetLink: `/operations-management/today-shipment?startDate=${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`,
    element: <TodayShipment />,
    id: "TODAY_SHIPMENT",
    parent: "OPERATIONS_MANAGEMENT"
  },
  {
    path: "/operations-management/today-shipment/:orderNumber",
    element: <OrderDetailByTodayShipmentOrders />,
    id: "TODAY_SHIPMENT_ORDER_DETAIL",
    type: "detail",
    parent: "OPERATIONS_MANAGEMENT",
    isActiveMenu: "TODAY_SHIPMENT",
  },
  {
    path: "/operations-management/today-returned",
    //targetLink: `/operations-management/today-returned?endDate=${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`,
    element: <TodayReturned />,
    id: "TODAY_RETURNED",
    parent: "OPERATIONS_MANAGEMENT"
  },
  {
    path: "/operations-management/today-returned/:orderNumber",
    element: <OrderDetailByTodayReturnedOrders />,
    id: "TODAY_RETURNED_ORDER_DETAIL",
    type: "detail",
    parent: "OPERATIONS_MANAGEMENT",
    isActiveMenu: "TODAY_RETURNED",
  },
  {
    path: "/operations-management/cargo-boxes",
    element: <CargoBoxes />,
    id: "PRODUCT_BOXES",
    parent: "OPERATIONS_MANAGEMENT",
  },
  {
    path: "/operations-management/cargo-boxes/:id",
    element: <CargoBoxDetail />,
    id: "PRODUCT_BOXES_DETAIL",
    type: "detail",
    parent: "OPERATIONS_MANAGEMENT",
    isActiveMenu: "PRODUCT_BOXES",
  },
  {
    id: "ADD_BOX",
    parent: "OPERATIONS_MANAGEMENT",
    icon:"PlusSmall"
  },
  {
    id: "GENERATE_RETURN_CODE",
    parent: "OPERATIONS_MANAGEMENT",
    icon:"BackCargosmall"
  },
  {
    id: "PRODUCT_MANAGEMENT",
    icon: "Box",
  },
  {
    path: "/product-management/products",
    element: <Products />,
    id: "PRODUCT_MASTER_DATA",
    parent: "PRODUCT_MANAGEMENT",
  },
  {
    path: "/product-management/products/:productId",
    element: <ProductDetail />,
    id: "PRODUCT_DETAIL",
    type: "detail",
    parent: "PRODUCT_MANAGEMENT",
    isActiveMenu: "PRODUCT_MASTER_DATA",
  },
  {
    path: "/product-management/inventory",
    element: <VariantSerialNumber />,
    id: "INVENTORY",
    parent: "PRODUCT_MANAGEMENT",
  },
  {
    path: "/product-management/inventory/:productId",
    element: <VariantSerialNumberDetail />,
    id: "INVENTORY_DETAIL",
    parent: "PRODUCT_MANAGEMENT",
    isActiveMenu: "INVENTORY",
    type: "detail",
  },
  {
    path: "/product-management/category",
    element: <div>Kategori en kısa zamanda burada olacak!</div>,
    id: "CATEGORY",
    parent: "PRODUCT_MANAGEMENT",
  },
  {
    path: "/product-management/brands",
    element: <div>Markalar en kısa zamanda burada olacak!</div>,
    id: "BRANDS",
    parent: "PRODUCT_MANAGEMENT",
  },
  {
    path: "/product-management/add-product",
    element: <AddProduct />,
    id: "ADD_PRODUCT",
    parent: "PRODUCT_MANAGEMENT",
    icon:"PlusSmall"
  },
  {
    id: "REPORTS",
    icon: "Graph",
  },
  {
    path: "/reports/ultimate-rental-data",
    element: <PeriodicSalesReport />,
    id: "ULTIMATE_RENTAL_DATA",
    parent : "REPORTS",
  },
  {
    path: "/reports/products-on-rental",
    element: <CurrentRentalReport />,
    id: "PRODUCTS_ON_RENTAL",
    parent : "REPORTS",
  },
  {
    path: "/reports/products-on-rental/:productId",
    element: <CurrentRentalReportDetail />,
    id: "CURRENT_RENTAL_REPORT_DETAL",
    type: "detail",
    parent: "REPORTS",
    isActiveMenu: "PRODUCTS_ON_RENTAL",
  },
  {
    path: "/reports/registered-users-info",
    element: <CustomerOrderReport />,
    id: "REGISTERED_USERS_INFO",
    parent : "REPORTS",
  },
  {
    path: "/reports/total-statistics",
    element: <OrderStatisticReport />,
    id: "TOTAL_STATISTICS",
    parent : "REPORTS",
  },
  {
    path: "/reports/device-recommendations",
    element: <SuggestedProductReport />,
    id: "DEVICE_RECOMMENDATIONS",
    parent : "REPORTS",
  },
  {
    path: "/reports/damage-coverage",
    element: <OrderInsuranceReport />,
    id: "DAMAGE_COVERAGE",
    parent : "REPORTS",
  },
  {
    path: "/reports/product-page-clicks",
    element: <ProductClickReport />,
    id: "PRODUCT_PAGE_CLICKS",
    parent : "REPORTS",
  },
  {
    path: "/reports/visitors-and-basket-details",
    element: <DemandedProductReport />,
    id: "VISITORS_AND_BASKET_DETAILS",
    parent : "REPORTS",
  },
  {
    path: "/reports/serial-no-based-rentals",
    element: <ProductSalesReport />,
    id: "SERIAL_NO_BASED_RENTALS",
    parent : "REPORTS",
  },
  {
    path: "/reports/accounting",
    element: <InvoiceAndWaybill />, 
    id: "ACCOUNTING",
    parent : "REPORTS",
  },
  {
    path: "/reports/sales-survey",
    element: <PurchaseOfferEvaluationReport />,
    id: "SALES_SURVEY",
    parent : "REPORTS",
  },
  {
    path: "/reports/sold-product-details",
    element: <PurchasedPriceReport />,
    id: "SOLD_PRODUCT_DETAILS",
    parent : "REPORTS",
  },
  {
    id: "CUSTOMERS",
    icon: "User3",
  },
  {
    path: "/customer/all-customers",
    element: <Customers />,
    id: "CUSTOMER_INFO",
    parent : "CUSTOMERS",
  },
  {
    path: "/customer/:customerId",
    element: <CustomersDetail />,
    id: "CUSTOMER_DETAIL",
    type: "detail",
    parent: "CUSTOMERS",
    isActiveMenu: "CUSTOMER_INFO",
  },
  {
    path: "/customer/segment",
    element: <div>Segment en kısa zamanda burada olacak!</div>,
    id: "SEGMENT",
    parent : "CUSTOMERS",
  },
  {
    path: "/customer/roles",
    element: <div>Rol en kısa zamanda burada olacak!</div>,
    id: "ROLE",
    parent : "CUSTOMERS",
  },
  {
    path: "/campaign-management",
    element: <div>Kampanya yönetimi en kısa zamanda burada olacak!</div>,
    id: "CAMPAING_MANAGEMENT",
    icon: "Campaign"
  },
  {
    id: "SETTINGS",
    icon: "Setting",
  },
  {
    path: "/settings/mail-templates",
    element: <div>Email şablonları en kısa zamanda burada olacak!</div>,
    id: "EMAIL_TEMPLATES",
    parent : "SETTINGS",
  },
  {
    path: "/settings/sms-templates",
    element: <div>SMS şablonları en kısa zamanda burada olacak!</div>,
    id: "SMS_TEMPLATES",
    parent : "SETTINGS",
  },
  {
    path: "/settings/page-management",
    element: <div>Sayfa yönetimi en kısa zamanda burada olacak!</div>,
    id: "PAGE_MANAGEMENT",
    parent : "SETTINGS",
  },
  {
    path: "/settings/site-settings",
    element: <div>Site ayar yönetimi en kısa zamanda burada olacak!</div>,
    id: "SITE_SETTINGS_MANAGEMENT",
    parent : "SETTINGS",
  },
  {
    path: "/settings/system-management",
    element: <div>Sistem yönetimi en kısa zamanda burada olacak!</div>,
    id: "SYSTEM_MANAGEMENT",
    parent : "SETTINGS",
  },
  {
    path: "/settings/profile-settings",
    element: <div>Profil ayarları en kısa zamanda burada olacak!</div>,
    id: "PROFILE_SETTINGS",
    type: "detail",
  }
]

export default Routes;