import Filters from "./Filters";
import List from "./List";
import { useForm } from "react-hook-form";
import { useQuery} from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {useEffect, useState} from "react";
import { toast } from "krl-alfred";
import {getSearchParams} from "../../../utils/genericUtils";
import { getProductList } from "../../../api/routes/product";

const Products = (props) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [params,setParams] = useState(getSearchParams(searchParams));

  useEffect(() => {
    setParams(getSearchParams(searchParams));
  },[searchParams])

  const defaultPageSize = params?.defaultPageSize || 15;

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: params,
  });

  const submit = async (data) => {
    toast({
      hasClosing: true,
      icon: "Danger",
      variant: "warning",
      title: "Buranın filtresi yok. o yüzden burası da yapılmadı şimdilik :)",
    });
    //data.index = 1;
    //let str = setUrlWithString(data);
    //navigate(`${str}`);
  };

  const handleExport = async () => {
    toast({
      hasClosing: true,
      icon: "Danger",
      variant: "warning",
      title: "Burası yapılmadı şimdilik :)",
    });
  };

  const handleClear = async () => {
    //navigate(`?`);
    reset({
      billingAddressFirstName: "",
      billingAddressLastName: "",
      billingAddressEmail: "",
      orderNumber: "",
      startDate: "",
      endDate: "",
      minStartDate: "",
      maxStartDate: "",
      minEndDate: "",
      maxEndDate: "",
      serialNumber: "",
      orderStatuses: "",
      paymentStatuses: "",
      orderTypes: "",
      productIds: "",
    });
  };

  const { data, isFetching,refetch } = useQuery({
    queryKey: ["productPageList", { ...params }],
    retry: 0,
    refetchOnReconnect: false,
    queryFn: async () => {
      let requestData = params;
      let filteredData = {};
      
      for (const [key, value] of Object.entries(requestData)) {
        if (typeof value === "object") {
          filteredData[key] = [value.value];
        } else {
          if (![undefined, null, ""].includes(value)) {
            if (key === "orderTypes") {
              filteredData[value] = true;
            } else {
              filteredData[key] = value;
            }
          }
        }
      }

      return await getProductList({
        size: defaultPageSize,
        index: params?.index || 1,
        ...filteredData
      });
    }
  });
  
  return (
    <div id="productsPage">
      <Filters
        handleClear={handleClear}
        onFilter={handleSubmit(submit)}
        onExport={handleExport}
        control={control}
        errors={errors}
        watch={watch}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        isLoading={isFetching}
        type={props?.type}
      />
      <List
        hasLoading={isFetching}
        data={data}
        type={props?.type}
        refetch={refetch}
        defaultPageSize={defaultPageSize}
      />
    </div>
  );
};

export default Products;
