import SimpleBar from "simplebar-react"
import { useTranslation, Modal, toast } from "krl-alfred"
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import BoxDetailForm from "../Forms/BoxDetailForm";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { addBox, updateBox } from "../api/routes/box";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

const BoxDetailModal = ({show, setShow, refetch, data}) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))

    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const submitRef = useRef(null)

    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm()
    
    const handleUpdateSubmit = async (values) => {
        setIsLoading(true)
        await updateBox({...values,userId:user.id,desi:0})
        .then(() => {
            toast({
                hasClosing: true,
                title: t("INFO.SUCCESS_UPDATED_TO_BOX")
            })
            setShow(false)
        })
        .catch((err) => {
            toast({
                hasClosing: true,
                icon: 'Danger',
                variant: 'warning',
                title: err?.response?.data?.message
            })
            setShow(false)
        })
        setIsLoading(true)
        queryClient.invalidateQueries("boxList")
    }
    
    const handleAddSubmit = async (values) => {
        setIsLoading(true)
        await addBox({...values,userId:user.id,desi:0})
        .then(() => {
            setShowConfirmationModal(true)
            setShow(false)
        })
        .catch((err) => {
            toast({
                hasClosing: true,
                icon: 'Danger',
                variant: 'warning',
                title: err?.response?.data?.message
            })
            setShow(false)
        })
        setIsLoading(true)
        queryClient.invalidateQueries("boxList")
    }

    useEffect(() => {
        if(show){
            if(data?.id){
                reset({
                  id: data?.id,
                  name: data?.name,
                  code: data?.code,
                  width: data?.width,
                  size: data?.size,
                  height: data?.height
                });
            }else{
                reset({
                  name: null,
                  code: null,
                  width: null,
                  size: null,
                  height: null
                });
            }
        }
    },[show])

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showSecondaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={data?.id ? t("INFO.UPDATE_BOX_INFO") : t("INFO.ADD_BOX")}
                primaryButtonText={data?.id ? t('BUTTON.UPDATE') : t('BUTTON.ADD')}
                secondaryButtonText={t('BUTTON.GIVE_UP')}
                id="boxDetailModal"
                onClose={() => setShow(false)}
                show={show}
                onPrimaryAction={() => submitRef?.current?.click()}
                onSecondaryAction={() => setShow(false)}
                customContent
                primaryButtonWidth="50%"
                secondaryButtonWidth="50%"
                isPrimaryButtonDisabled={data?.id ? Object.keys(dirtyFields).length <= 0 : !isValid}
                isPrimaryButtonLoading={isLoading}
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <BoxDetailForm
                            onSubmit={data?.id ? handleSubmit(handleUpdateSubmit) : handleSubmit(handleAddSubmit)}
                            control={control}
                            errors={errors}
                            isLoading={isLoading}
                            submitRef={submitRef}
                            data={data}
                        />
                    </div>
                </SimpleBar>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                contentTitle={t("INFO.SUCCESS_ADD_TO_BOX")}
                onPrimaryAction={() => {
                    navigate(`/operations-management/cargo-boxes`);
                    setShowConfirmationModal(false)
                }}
                primaryButtonText={t('BUTTON.GO_TO_BOXES')}
                contentDescription={t("INFO.SUCCESS_ADD_TO_BOX_DESC")}
            />
        </>
    )
}

export default BoxDetailModal