import { Button, useTranslation, toast } from "krl-alfred";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Listing from "../../Listing";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { deleteBox } from "../../../api/routes/box";

const List = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'))

  const routeLink = (record) => navigate(`/operations-management/cargo-boxes/${record?.id}`);

  const handleDelete = async (record) => {
    setSelectedBox(record)
    if(!showConfirmationModal){
      setShowConfirmationModal(true)
      return
    }
    setIsLoading(true);
    await deleteBox({id:selectedBox.id,userId:user.id}).then((resp) => {
      toast({
          hasClosing: true,
          title: t("INFO.DELETED_SUCCESSFULL")
      })
    }).catch(err => {
      toast({
          hasClosing: true,
          icon: 'Danger',
          variant: 'warning',
          title: err?.response?.data?.message
      })
    })
    .finally((final) => {
      setIsLoading(false);
      setShowConfirmationModal(false)
      props?.refetch()
    });
  };

  const columns = [
    {
      key: "id",
      title: t("LIST.ID"),
      dataIndex: "id",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "name",
      title: t("CARGO_BOXES.BOX_NAME"),
      dataIndex: "name",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "code",
      title: t("CARGO_BOXES.BOX_CODE"),
      dataIndex: "code",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "width",
      title: t("CARGO_BOXES.WIDTH"),
      dataIndex: "width",
      render: (val) => `${val} cm`,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "size",
      title: t("CARGO_BOXES.SIZE"),
      dataIndex: "size",
      render: (val) => `${val} cm`,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "height",
      title: t("CARGO_BOXES.HEIGHT"),
      dataIndex: "height",
      render: (val) => `${val} cm`,
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "desi",
      title: t("CARGO_BOXES.DESI"),
      dataIndex: "desi",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "actions",
      title: null,
      fixed: "right",
      width: 100,
      render: (record) =>
      (
        <div className="actions">
          <Button
            onClick={() => handleDelete(record)}
            width="32px"
            variant="primary"
            tooltip={t("BUTTON.DELETE")}
            size="xsmall"
            icon="DeleteSmall"
            isLoading={isLoading}
          />
        </div>
      )
    },
  ];

  return (
    <div className="cargoBoxList">
      <Listing
        hasLoading={props?.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />
      <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          contentTitle={t("INFO.ARE_YOU_SURE_DELETED_CARGO_BOX")}
          onPrimaryAction={handleDelete}
          isPrimaryButtonLoading={isLoading}
          primaryButtonText={t('BUTTON.DELETE')}
      />
    </div>
  );
};

export default List;
