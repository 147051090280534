import {DescriptionCard, toast, useTranslation} from "krl-alfred";
import React from "react";

const Properties = ({productDetail}) => {
    const {t} = useTranslation();
    let list = [ ];
    productDetail?.properties?.map(property => {
        list.push({
            key: property?.name,
            title: property?.name,
            display: property?.value,
        })
    })

    const handleUpdate = async () => {
        toast({
            timer: 4000,
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Çalışmaz çünkü buralar hiç yapılmadı, yapısı da hazır değil. hazır olunca yapılcak :)",
        });
    }

    return (
        <div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.PROPERTIES")}</h1>
                <DescriptionCard
                    list={list}
                    title={productDetail?.name}
                    hasHeaderButton
                    headerButtonText={t("BUTTON.EDIT")}
                    headerButtonWidth="82px"
                    headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                    headerButtonAction={handleUpdate}
                    boxSize={productDetail?.properties?.length <= 5 ? productDetail?.properties?.length : 5}
                />
            </div>
        </div>
    )
}

export default Properties