import { useTranslation, SelectBox, Textarea } from "krl-alfred";
import { Controller } from "react-hook-form";
import { focusSelect } from "./../../utils/genericUtils";

function ScoreList({ control, errors, product }) {
  const { t } = useTranslation();

  return (
    <div className="scoreList">
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][sendingOnTime]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.SENDING_ON_TIME")}
              instanceId="sendingOnTime"
              id="sendingOnTime"
              width="100%"
              placeholder={t("FORM.INPUT.SENDING_ON_TIME")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={(e) => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={
                errors?.scores
                  ? errors?.scores[product?.id]?.sendingOnTime
                  : null
              }
              errorMessage={t("FORM.ERROR.SENDING_ON_TIME")}
              maxOptionListHeight="153px"
              options={[...Array(10)].map((_, i) => ({
                value: i + 1,
                label: i + 1,
              }))}
              hasShowDropdownIcon
              tabSelectsValue={false}
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][packagingAndBoxCondition]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.PACKAGING_AND_BOX_CONDITION")}
              instanceId="packagingAndBoxCondition"
              width="100%"
              placeholder={t("FORM.INPUT.PACKAGING_AND_BOX_CONDITION")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={(e) => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={
                errors?.scores
                  ? errors?.scores[product?.id]?.packagingAndBoxCondition
                  : null
              }
              errorMessage={t("FORM.ERROR.PACKAGING_AND_BOX_CONDITION")}
              maxOptionListHeight="153px"
              options={[...Array(10)].map((_, i) => ({
                value: i + 1,
                label: i + 1,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][completenessOfParts]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.COMPLETENESS_OF_PARTS")}
              instanceId="completenessOfParts"
              width="100%"
              placeholder={t("FORM.INPUT.COMPLETENESS_OF_PARTS")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={(e) => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={
                errors?.scores
                  ? errors?.scores[product?.id]?.completenessOfParts
                  : null
              }
              errorMessage={t("FORM.ERROR.COMPLETENESS_OF_PARTS")}
              maxOptionListHeight="153px"
              options={[...Array(10)].map((_, i) => ({
                value: i + 1,
                label: i + 1,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][productCleaningAndCosmetics]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <SelectBox
              value={value}
              name={name}
              label={t("FORM.LABEL.PRODUCT_CLEANING_AND_COSMETICS")}
              instanceId="productCleaningAndCosmetics"
              width="100%"
              placeholder={t("FORM.INPUT.PRODUCT_CLEANING_AND_COSMETICS")}
              isSearchable={false}
              lightBg={false}
              //filterOption={customSelectFilter}
              onChange={onChange}
              onFocus={(e) => focusSelect(e.target)}
              onMenuClose={() => document.activeElement.blur()}
              noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
              hasError={
                errors?.scores
                  ? errors?.scores[product?.id]?.productCleaningAndCosmetics
                  : null
              }
              errorMessage={t("FORM.ERROR.PRODUCT_CLEANING_AND_COSMETICS")}
              maxOptionListHeight="153px"
              options={[...Array(10)].map((_, i) => ({
                value: i + 1,
                label: i + 1,
              }))}
              hasShowDropdownIcon
            />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][note]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Textarea
              onChange={onChange}
              value={value}
              name={name}
              width="100%"
              //label={t('FORM.LABEL.NOTE')}
              label={null}
              placeholder={t("FORM.INPUT.NOTE")}
              hasError={
                errors?.scores ? errors?.scores[product?.id]?.note : null
              }
              errorMessage={t("FORM.ERROR.NOTE")}
              resize="none"
              height="145px"
            />
          )}
        />
      </div>
    </div>
  );
}

export default ScoreList;
