import {DescriptionCard, toast, useTranslation} from "krl-alfred";
import React from "react";

const Medias = ({productDetail}) => {
    const {t} = useTranslation();

    const setList = (values) => [
        {
            key: "FILE_NAME",
            title: t("PRODUCT.FILE_NAME"),
            display: values?.fileName,
        },
        {
            key: "RELATIVE_PATH",
            title: t("PRODUCT.RELATIVE_PATH"),
            display: values?.relativePath,
        },
        {
            key: "URL",
            title: t("PRODUCT.URL"),
            display: values?.url,
        }
    ]

    const handleUpdate = async () => {
        toast({
            timer: 4000,
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Çalışmaz çünkü buralar hiç yapılmadı, yapısı da hazır değil. hazır olunca yapılcak :)",
        });
    }

    return (
        <div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.MEDIAS")}</h1>
                {productDetail.medias?.map(media => (
                    <div className="descriptionCardWrapper">
                        <DescriptionCard
                            list={setList(media)}
                            title={productDetail?.name}
                            hasHeaderButton
                            headerButtonText={t("BUTTON.EDIT")}
                            headerButtonWidth="82px"
                            headerImage={<img alt={productDetail?.name} src={media?.url}/>}
                            boxSize={3}
                            headerButtonAction={handleUpdate}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Medias