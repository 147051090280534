
const ProductFilter = ({
  control,
  errors,
  watch,
  setError,
  clearErrors,
  setValue,
  type
}) => {
  return (
    <>
      ürünlerin filtresi yok henüz, yapısı da yok. hazırladığımızda burada olacak :)
    </>
  );
};

export default ProductFilter;
