import {DescriptionCard, toast, useTranslation} from "krl-alfred";
import React from "react";

const Prices = ({productDetail}) => {
    const {t} = useTranslation();
    let rentalList = [ ];
    let subscriptionList = [ ];

    const priceList = productDetail?.variants[0]?.prices;
    const rentalPriceList = priceList?.filter(price => !price?.isSubscriptionPrice);
    const subscriptionPriceList = priceList?.filter(price => price?.isSubscriptionPrice);

    rentalPriceList.map(price => {
        rentalList.push(
            {
                key: "RENTAL_PERIOD",
                title: t("PRODUCT.RENTAL_PERIOD"),
                display: price?.priceTypeText,
            },
            {
                key: "OLD_PRICE",
                title: t("PRODUCT.OLD_PRICE"),
                display: `${price?.oldPriceText} ${price?.currencyCode}`,
            },
            {
                key: "PRICE",
                title: t("PRODUCT.PRICE"),
                display: `${price?.newPriceText} ${price?.currencyCode}`,
            },
            {
                key: "INSURANCE_RATE",
                title: t("PRODUCT.INSURANCE_RATE"),
                display: price?.insuranceRate,
            }
        )
    })
    subscriptionPriceList.map(price => {
        subscriptionList.push(
            {
                key: "OLD_PRICE",
                title: t("PRODUCT.OLD_PRICE"),
                display: `${price?.oldPriceText} ${price?.currencyCode}`,
            },
            {
                key: "PRICE",
                title: t("PRODUCT.PRICE"),
                display: `${price?.newPriceText} ${price?.currencyCode}`,
            },
            {
                key: "RENTAL_PERIOD",
                title: t("PRODUCT.RENTAL_PERIOD"),
                display: price?.priceTypeText,
            },
            {
                key: "INSURANCE_RATE",
                title: t("PRODUCT.INSURANCE_RATE"),
                display: price?.insuranceRate,
            }
        )
    })

    const handleUpdate = async () => {
        toast({
            timer: 4000,
            hasClosing: true,
            icon: "Danger",
            variant: "warning",
            title: "Çalışmaz çünkü buralar hiç yapılmadı, yapısı da hazır değil. hazır olunca yapılcak :)",
        });
    }

    return (
        <div>
            <div className="productInfoWrapper">
                <h1 className="pageTitle">{t("PRODUCT.RENTAL_PERIOD")}</h1>
                <DescriptionCard
                    list={rentalList}
                    title={productDetail?.name}
                    hasHeaderButton
                    headerButtonText={t("BUTTON.EDIT")}
                    headerButtonWidth="82px"
                    headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                    boxSize={4}
                    headerButtonAction={handleUpdate}
                />
            </div>
            {subscriptionPriceList?.length > 0 && (
                <div className="productInfoWrapper">
                    <h1 className="pageTitle">{t("PRODUCT.SUBSCRIPTION_PERIOD")}</h1>
                    <DescriptionCard
                        list={subscriptionList}
                        title={productDetail?.name}
                        hasHeaderButton
                        headerButtonText={t("BUTTON.EDIT")}
                        headerButtonWidth="82px"
                        headerImage={<img alt={productDetail?.name} src={productDetail?.medias[0]?.url}/>}
                        boxSize={4}
                        headerButtonAction={handleUpdate}
                    />
                </div>
            )}
        </div>
    )
}

export default Prices