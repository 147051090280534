import { useTranslation, Icon } from "krl-alfred";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/date";
import Listing from "../../Listing";

const List = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const routeLink = (record) =>
    navigate(`/customer/${record?.id}`);

  const columns = [
    {
      key: "id",
      title: t("LIST.ID"),
      dataIndex: "id",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "firstName",
      title: t("CUSTOMER.FIRST_NAME"),
      dataIndex: "firstName",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "lastName",
      title: t("CUSTOMER.LAST_NAME"),
      dataIndex: "lastName",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "identificationNumber",
      title: t("CUSTOMER.IDENTIFICATION_NUMBER"),
      dataIndex: "identificationNumber",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "dateOfBirth",
      title: t("CUSTOMER.DATE_OF_BIRTH"),
      dataIndex: "dateOfBirth",
      render: (date) => formatDate(date),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "isDeleted",
      title: t("CUSTOMER.DELETED"),
      dataIndex: "isDeleted",
      render: (isDeleted) => (
        isDeleted ? t("CUSTOMER.DELETED") : t("CUSTOMER.NOT_DELETED")
      ),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "isVerified",
      title: t("CUSTOMER.IS_VERIFIED"),
      dataIndex: "isVerified",
      render: (isVerified) => (
        isVerified ? <div className="status"><Icon color="var(--green)" name="CircleFilled"/>{t("CUSTOMER.VERIFIED")}</div> : <div className="status"><Icon color="var(--red)" name="CircleFilled"/>{t("CUSTOMER.NOT_VERIFIED")}</div>
      ),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "email",
      title: t("CUSTOMER.E_MAIL"),
      dataIndex: "email",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "phone",
      title: t("CUSTOMER.PHONE_NUMBER"),
      dataIndex: "phone",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "roles",
      title: t("CUSTOMER.ROLE"),
      dataIndex: "roles",
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "isActive",
      title: t("CUSTOMER.IS_ACTIVE"),
      dataIndex: "isActive",
      render: (isActive) => (
        isActive ? <div className="status"><Icon color="var(--primary)" name="CircleFilled"/>{t("CUSTOMER.ACTIVE")}</div> : <div className="status"><Icon color="var(--dark-opacity-25)" name="CircleFilled"/>{t("CUSTOMER.PASSIVE")}</div>
      ),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
    {
      key: "lastLoginDateAt",
      title: t("CUSTOMER.LAST_LOGIN_DATE_AT"),
      dataIndex: "lastLoginDateAt",
      render: (date) => formatDate(date,true,"DD.MM.YYYY HH:mm:ss",false),
      onCell: (record, index) => ({
        onClick(e) {
          routeLink(record);
        },
      }),
    },
  ];

  return (
    <div className="customerList">
      <Listing
        hasLoading={props?.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />
    </div>
  );
};

export default List;
