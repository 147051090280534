import {Dropdown, useTranslation} from "krl-alfred"
import { AuthContext } from "../../../Context/AuthContext";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

const UserDropdown = () => {
    
    const [openDropdown, setOpenDropdown] = useState(false)
    const { logout, user } = useContext(AuthContext);
    const { t } = useTranslation();
  
    const AvatarToggle = () => (
      <div className="userAvatar" onClick={() => setOpenDropdown(!openDropdown)}>
        {user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase()}
      </div>
    )
  
    const list = [
      {
        component: () => (
            <Link to="/settings/profile-settings" onClick={() => { setOpenDropdown(false) }}>{t("AUTH.PROFILE_SETTINGS")}</Link>
        ),
      },
      {
        component: () => (
            <Link to="/login" onClick={() => { logout() }}>{t("AUTH.LOGOUT")}</Link>
        ),
      },
    ]

    return (
    <div id="userDropdown">
        <Dropdown Toggle={() => <AvatarToggle />} list={list} show={openDropdown} />
    </div>
    )
}

export default UserDropdown