/*import OrdersPage from "./../../Components/Pages/Orders"
const TodayShipment = () => <OrdersPage type="todayShipmentOrders"/>

export default TodayShipment;*/



import ShipmentsTable from "../../Tables/Shipments";


const TodayShipment = () => {

    return (
        <div>
            <ShipmentsTable />
        </div>
    )
}

export default TodayShipment
