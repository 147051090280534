import React from 'react'
import { Button, Input, useTranslation } from 'krl-alfred'
import { Controller } from 'react-hook-form'
import { emailIsValid } from '../utils/genericUtils'

function LoginForm({ onSubmit, control, errors, isLoading }) {
  const {t} = useTranslation();

  return (
    <form onSubmit={onSubmit} >
      <div className="input-container">
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              width="100%"
              type="email"
              label={t('FORM.LABEL.EMAIL')}
              placeholder={t('FORM.INPUT.EMAIL')}
              hasError={errors.email}
              errorMessage={errors?.email?.message || t('FORM.ERROR.EMAIL')}
            />
          )}
          rules={{ required: true, validate: val => emailIsValid(val) }}
        />
      </div>
      <div className="input-container">
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Input
              onChange={onChange}
              value={value}
              name={name}
              width="100%"
              type="password"
              label={t('FORM.LABEL.PASSWORD')}
              placeholder={t('FORM.INPUT.PASSWORD')}
              hasError={errors.password}
              errorMessage={t('FORM.ERROR.PASSWORD')}
            />
          )}
          rules={{ required: true }}
        />
      </div>
      {/* 
      <div className='rememberMe'>
        <Controller
          name="rememberme"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Checkbox
              onChange={onChange}
              id="rememberme"
              checked={value}
              type="checkbox"
              label={t('FORM.LABEL.REMEMBER_ME')}
              size="small"
            />
          )}
        />
      </div>
      */}
      <Button isLoading={isLoading} type="submit" width="100%" size="big" variant="primary">{t("BUTTON.LOGIN")}</Button>
    </form>
  )
}

export default LoginForm
