import {toast, useTranslation} from "krl-alfred"
import FileActionCard from "../../Components/Cards/FileActionCard"
import {
    getActiveBrands,
    getSampleImportForImportProducts,
    getSampleImportForUpdatePrices, importForUpdatePrices
} from "../../api/routes/product"
import { downloadFile } from "../../api/request"
import React, {useEffect, useRef, useState} from "react";
import ConfirmationModal from "../../Modals/ConfirmationModal";

const AddProduct = () => {

    const {t} = useTranslation()
    const inputFile = useRef(null)
    const [uploadType,setUploadType] = useState(null)
    const [showConfirmationModal,setShowConfirmationModal] = useState(false)
    const [isLoading,setIsLoading] = useState(false)


    const handleDownloadSampleProduct = async () => {
        await getSampleImportForImportProducts().then(resp => {
            downloadFile(resp)
        })
    }

    const handleDownloadSampleProductPrice = async () => {
        await getSampleImportForUpdatePrices().then(resp => {
            downloadFile(resp)
        })
    }

    const handleStartUploadProduct = () => {
        setUploadType("uploadProduct")
        inputFile.current.value = null;
        inputFile.current.click();
    }

    const handleStartUploadProductPrice = () => {
        setUploadType("uploadProductPrice")
        inputFile.current.value = null;
        inputFile.current.click();
    }

    const handleDownloadBrands = async () => {
        await getActiveBrands().then(resp => {
            downloadFile(resp)
        })
    }

    const handleFileChange = (e) => {
        if(e.target.value){
            setShowConfirmationModal(true)
        }
    }

    const handleUpload = async () => {
        const fmData = new FormData();
        fmData.append("file", inputFile?.current?.files[0]);

        setIsLoading(true)
        if(uploadType === "uploadProduct"){
            toast({
                timer:5000,
                hasClosing: true,
                icon: "Danger",
                variant: "warning",
                title: "Ürün güncellemenin entpointi yok o yüzden burası da yapılmadı şimdilik :)",
            });
        }else if(uploadType === "uploadProductPrice"){
            await importForUpdatePrices(fmData).then(resp => {
                toast({
                    hasClosing: true,
                    title: t("INFO.UPLOAD_SUCCESS")
                })
            }).catch(err => {
                toast({
                    hasClosing: true,
                    icon: "Danger",
                    variant: "warning",
                    title: err?.response?.data?.message,
                });
            })
        }
        setIsLoading(false)
        setShowConfirmationModal(false)
    }

    return (
        <div id="addProductPage">
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFileChange}/>
            <h1 className="pageTitle">{t("INFO.ADD_PRODUCT")}</h1>
            <p className="pageDesc">{t("INFO.ADD_PRODUCT_DESC")}</p>
            <div className="uploadContentWrapper">
                <FileActionCard
                    title={t("INFO.UPDATE_PRODUCT")}
                    isShowSecondaryButton
                    isShowPrimaryButton
                    primaryText={t("BUTTON.UPLOAD")}
                    secondaryText={t("BUTTON.DOWNLOAD_SAMPLE_FILE")}
                    onPrimaryAction={handleStartUploadProduct}
                    onSecondaryAction={handleDownloadSampleProduct}
                />
                <FileActionCard
                    title={t("INFO.UPDATE_PRICE")}
                    isShowSecondaryButton
                    isShowPrimaryButton
                    primaryText={t("BUTTON.UPLOAD")}
                    secondaryText={t("BUTTON.DOWNLOAD_SAMPLE_FILE")}
                    onPrimaryAction={handleStartUploadProductPrice}
                    onSecondaryAction={handleDownloadSampleProductPrice}
                />
                <FileActionCard
                    title={t("INFO.UPDATE_BRAND")}
                    isShowSecondaryButton
                    secondaryText={t("BUTTON.DOWNLOAD")}
                    onSecondaryAction={handleDownloadBrands}
                />
            </div>

            <ConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                contentTitle={t("INFO.ARE_YOU_SURE_UPLOAD")}
                onPrimaryAction={handleUpload}
                isPrimaryButtonLoading={isLoading}
                primaryButtonText={t('BUTTON.APPROVE')}
            />
        </div>
    )
}

export default AddProduct