import { useTranslation } from "krl-alfred";
import { useForm } from "react-hook-form";
import LoginForm from "../../Forms/LoginForm";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { Navigate } from 'react-router-dom'

const Login = () => {
    const {t} = useTranslation();
    const { isLoggedIn,login } = useContext(AuthContext)
    const [isLoading,setIsLoading] = useState(false)
    const navigate = useNavigate();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError
    } = useForm()

    const handleLogin = async (values) => {
        setIsLoading(true)
        await login(values.email, values.password).then(() => {
            navigate("/");
        }).catch(err => {
            setError("email",{message:err?.response?.data?.message})
            //seterrorMessage(err)
        })
        setIsLoading(false)
    }

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <div id="loginPage">
            <div className="leftContent">
                <div className="logo">
                    <img src="/favicon.svg" alt="favicon"/>
                    <img src="/logo-light.svg" alt="logo light"/>
                </div>
                <div className="textContent">
                    <h1 className="title">{t("AUTH.WELCOME")}</h1>
                    <p className="description">{t("AUTH.DESCRIPTION")}</p>
                </div>
            </div>
            <div className="rightContent">
                <div className="formContent">
                    <strong className="title">{t("AUTH.LOGIN")}</strong>
                    <div className="formWrapper">
                        <LoginForm
                            control={control}
                            errors={errors}
                            onSubmit={handleSubmit(handleLogin)}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
