import {Controller} from "react-hook-form";
import {
    Input,
    useTranslation,
    SelectBox
} from "krl-alfred";
import {useContext, useEffect, useRef} from "react";
import orderTypesData from "./../../jsons/orderTypes.json";
import {OrderContext} from "../../Context/OrderContex";
import {getProductListWithFilter} from "../../api/routes/category";

const OrderFilter = ({
                         control,
                         errors,
                         watch,
                         setError,
                         clearErrors,
                         setValue,
                         type
                     }) => {
    const {t} = useTranslation();
    const {orderStatuses, paymentStatuses} = useContext(OrderContext);

    useEffect(() => {
        if (watch("paymentStatuses")) {
            const selectedPaymentStatus = paymentStatuses.find(
                (status) => status.id === parseInt(watch("paymentStatuses"))
            );
            if (selectedPaymentStatus) {
                setValue("paymentStatuses", {
                    value: selectedPaymentStatus?.id,
                    label: selectedPaymentStatus?.value,
                });
            }
        }
        if (watch("orderStatuses")) {
            const selectedOrderStatus = orderStatuses.find(
                (status) => status.id === parseInt(watch("orderStatuses"))
            );
            if (selectedOrderStatus) {
                setValue("orderStatuses", {
                    value: selectedOrderStatus?.id,
                    label: selectedOrderStatus?.value,
                });
            }
        }
        if (watch("orderTypes")) {
            const selectedOrderType = orderTypesData.find(
                (type) => type.value === watch("orderTypes")
            );
            if (selectedOrderType) {
                setValue("orderTypes", {
                    value: selectedOrderType?.value,
                    label: selectedOrderType?.label,
                });
            }
        }
    }, [orderStatuses, paymentStatuses]);

    const loadOptions = async (inputValue, callback) => {
        const data = await getProductListWithFilter({
            query: inputValue,
            filterOrderByType: 0,
            pageIndex: 1,
            pageSize: 1000,
            brandIds: [],
        }).then(async resp => {
            return resp.data.map(item => ({
                value: item.productId,
                label: item.name,
            }))
        })
        callback(data)
    }

    const productIdsRef = useRef(null);

    return (
        <>
            <div className="input-controller">
                <Controller
                    name="billingAddressFirstName"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.FIRST_NAME")}
                            placeholder={t("FORM.INPUT.FIRST_NAME")}
                            hasError={errors.billingAddressFirstName}
                            errorMessage={t("FORM.ERROR.FIRST_NAME")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="billingAddressLastName"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.LAST_NAME")}
                            placeholder={t("FORM.INPUT.LAST_NAME")}
                            hasError={errors.billingAddressLastName}
                            errorMessage={t("FORM.ERROR.LAST_NAME")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="billingAddressEmail"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.EMAIL")}
                            placeholder={t("FORM.INPUT.EMAIL")}
                            hasError={errors.billingAddressEmail}
                            errorMessage={t("FORM.ERROR.EMAIL")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="orderNumber"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ORDER_NUMBER")}
                            placeholder={t("FORM.INPUT.ORDER_NUMBER")}
                            hasError={errors.orderNumber}
                            errorMessage={t("FORM.ERROR.ORDER_NUMBER")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="startDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.START_DATE")}
                            placeholder={t("FORM.INPUT.START_DATE")}
                            hasError={errors.startDate}
                            errorMessage={t("FORM.ERROR.START_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="endDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.END_DATE")}
                            placeholder={t("FORM.INPUT.END_DATE")}
                            hasError={errors.endDate}
                            errorMessage={t("FORM.ERROR.END_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="minStartDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.MIN_START_DATE")}
                            placeholder={t("FORM.INPUT.MIN_START_DATE")}
                            hasError={errors.minStartDate}
                            errorMessage={t("FORM.ERROR.MIN_START_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="maxStartDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.MAX_START_DATE")}
                            placeholder={t("FORM.INPUT.MAX_START_DATE")}
                            hasError={errors.maxStartDate}
                            errorMessage={t("FORM.ERROR.MAX_START_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="minEndDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.MIN_END_DATE")}
                            placeholder={t("FORM.INPUT.MIN_END_DATE")}
                            hasError={errors.minEndDate}
                            errorMessage={t("FORM.ERROR.MIN_END_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="maxEndDate"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type="date"
                            label={t("FORM.LABEL.MAX_END_DATE")}
                            placeholder={t("FORM.INPUT.MAX_END_DATE")}
                            hasError={errors.maxEndDate}
                            errorMessage={t("FORM.ERROR.MAX_END_DATE")}
                            format="yyyy-MM-dd"
                            mask={null}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="serialNumber"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.SERIAL_NUMBER")}
                            placeholder={t("FORM.INPUT.SERIAL_NUMBER")}
                            hasError={errors.serialNumber}
                            errorMessage={t("FORM.ERROR.SERIAL_NUMBER")}
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="orderStatuses"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ORDER_STATUSES")}
                            instanceId="orderStatusesSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.ORDER_STATUSES")}
                            isSearchable={false}
                            lightBg={false}
                            //filterOption={customSelectFilter}
                            onChange={onChange}
                            //onFocus={e => focusSelect(e.target)}
                            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                            hasError={errors.orderStatuses}
                            errorMessage={t("FORM.ERROR.ORDER_STATUSES")}
                            maxOptionListHeight="153px"
                            options={orderStatuses?.map((orderStatus) => ({
                                value: orderStatus.id,
                                label: orderStatus.value,
                            }))}
                            hasShowDropdownIcon
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="paymentStatuses"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PAYMENT_STATUSES")}
                            instanceId="paymentStatusesSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.PAYMENT_STATUSES")}
                            isSearchable={false}
                            lightBg={false}
                            //filterOption={customSelectFilter}
                            onChange={onChange}
                            //onFocus={e => focusSelect(e.target)}
                            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                            hasError={errors.paymentStatuses}
                            errorMessage={t("FORM.ERROR.PAYMENT_STATUSES")}
                            maxOptionListHeight="153px"
                            options={paymentStatuses?.map((status) => ({
                                value: status.id,
                                label: status.value,
                            }))}
                            hasShowDropdownIcon
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="orderTypes"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.ORDER_TYPES")}
                            instanceId="orderTypesSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.ORDER_TYPES")}
                            isSearchable={false}
                            lightBg={false}
                            //filterOption={customSelectFilter}
                            onChange={onChange}
                            //onFocus={e => focusSelect(e.target)}
                            noOptionsMessage={() => t("INFO.NO_OPTIONS_FOUND")}
                            hasError={errors.orderTypes}
                            errorMessage={t("FORM.ERROR.ORDER_TYPES")}
                            maxOptionListHeight="153px"
                            options={orderTypesData?.map((orderStatus) => ({
                                value: orderStatus.value,
                                label: orderStatus.label,
                            }))}
                            hasShowDropdownIcon
                            isClearable
                        />
                    )}
                />
            </div>
            <div className="input-controller">
                <Controller
                    name="productIds"
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <SelectBox
                            value={value}
                            name={name}
                            label={t("FORM.LABEL.PRODUCT_IDS")}
                            instanceId="productIdsSelect"
                            width="100%"
                            placeholder={t("FORM.INPUT.PRODUCT_IDS")}
                            lightBg={false}
                            onChange={e => {
                                productIdsRef.current.blur()
                                onChange(e)
                            }}
                            onMenuClose={() => document.activeElement.blur()}
                            hasError={errors?.productIds}
                            errorMessage={t("FORM.ERROR.PRODUCT_IDS")}
                            isAsync
                            isClearable={true}
                            loadOptions={loadOptions}
                            maxOptionListHeight="153px"
                            isMulti
                            hasShowDropdownIcon
                            cacheOptions
                            ref={productIdsRef}
                            noOptionsMessage={(e) => {
                                return (['',null].includes(e.inputValue) || e.inputValue.length <= 2) ? t("FORM.INFO.PRODUCT_IDS.ENTER_CHAR") : t("FORM.INFO.PRODUCT_IDS.NO_OPTIONS")
                            }}
                        />
                    )}
                />
            </div>
        </>
    );
};

export default OrderFilter;
